import { Tag } from "antd";
import {
  JobDetailType,
  RoleType,
} from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  jobDetailData: JobDetailType;
};

export default function TableView(props: PropsType) {
  const { jobDetailData } = props;

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className="job_roles_table">
      <div className="table_inner">
        <div className="table_header">
          <div className="top_role">{jobDetailData?.name}</div>
          {jobDetailData?.roles?.length > 0 &&
            jobDetailData?.roles?.map((role: RoleType, index) => (
              <div className="header_cols" key={role?.id}>
                <div className="header_label">
                  {role?.name}
                  <h6 className={`tagNumber_${index + 1}`}>{index + 1}</h6>
                </div>
              </div>
            ))}
        </div>
        <div className="table_body">
          <div className="body_inner">
            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Purpose statement</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        role?.purpose_statement as string
                      )}
                      className="rows_para"
                    />
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Objectives</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        role?.objectives as string
                      )}
                      className="rows_para"
                    />
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Key results</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      {role?.key_results?.length > 0 ? (
                        <ul>
                          {role?.key_results?.map((key: string, i: number) => (
                            <li key={i}>{key}</li>
                          ))}
                        </ul>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Workflows</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      {role?.workflows?.length > 0
                        ? role?.workflows?.map(
                            (workflow: string, i: number) => (
                              <Tag
                                className="ant-tag defaultTag white_tag"
                                key={i}
                              >
                                {workflow}
                              </Tag>
                            )
                          )
                        : "N/A"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Procedures/WIs/Policies</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        role?.procedures as string
                      )}
                      className="rows_para"
                    />
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Entities</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        role?.entities as string
                      )}
                      className="rows_para"
                    />
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Skills</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      {role?.skills?.length > 0
                        ? role?.skills?.map((skill: string, i: number) => (
                            <Tag
                              className="ant-tag defaultTag white_tag"
                              key={i}
                            >
                              {skill}
                            </Tag>
                          ))
                        : "N/A"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Technologies</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      {role?.technologies?.length > 0
                        ? role?.technologies?.map(
                            (technology: string, i: number) => (
                              <Tag
                                className="ant-tag defaultTag white_tag"
                                key={i}
                              >
                                {technology}
                              </Tag>
                            )
                          )
                        : "N/A"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Teams</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      {role?.teams?.length > 0
                        ? role?.teams?.map((team: string, i: number) => (
                            <Tag
                              className="ant-tag defaultTag white_tag"
                              key={i}
                            >
                              {team}
                            </Tag>
                          ))
                        : "N/A"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Meetings</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      {role?.meetings?.length > 0
                        ? role?.meetings?.map((meeting: string, i: number) => (
                            <Tag
                              className="ant-tag defaultTag white_tag"
                              key={i}
                            >
                              {meeting}
                            </Tag>
                          ))
                        : "N/A"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Department</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">{role?.department}</div>
                  </div>
                ))}
            </div>

            {/* <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Coached By</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div className="rows_cols" key={role?.id}>
                    <div className="rows_para">
                      <div className="coach_avatar">
                        <div className="coach_text">
                          Executive coach
                          <img src="/images/visit_icon.svg" alt="" />
                        </div>
                        <div className="coach_avatar">
                          <Avatar.Group
                            className="avatarGroup"
                            size="small"
                            max={{
                              count: 3,
                              style: {
                                color: "#161616",
                                backgroundColor: "#fff",
                                cursor: "pointer",
                                fontSize: "10px",
                                fontWeight: "500",
                              },
                            }}
                          >
                            <Avatar>AR</Avatar>
                            <Avatar src="/images/avatar_1.jpg" />
                            <Avatar>SN</Avatar>
                          </Avatar.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div> */}

            {/* <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Objectives</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <p> Same as job </p>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Key results</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>NPS</li>
                    <li>Tenured Staff</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Workflows</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Initiation and Scope Definition
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Monitoring and Controlling Wor...
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Evaluation</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Monitoring and Controlling Wor...
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Initiation and Scope Definition
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Procedures/WIs/Policies</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                    <li>Training</li>
                    <li>Tracking</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Tracking</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Entities</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Skills</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Technologies</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Teams</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Meetings</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Department</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Coached By</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
