import { Row, Col, Button, Card, Form, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import TextEditor from "../../../../molecules/FormItems/TextEditor";
import { useCallback, useEffect, useState } from "react";
import { getUserDetails } from "../../../MyAccount/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import SelectField from "../../../../molecules/FormItems/SelectField";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
} from "../../ducks/actions";
import InputField from "../../../../molecules/FormItems/InputField";
import { createRole, generateAIDescription } from "../../ducks/services";
import { CreateRole } from "../../ducks/types";
import SuccessMessage from "../../../..//atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../../..//atoms/Toasts/ErrorMessage";

export default function AddRoles() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateRole>();
  const [roleName, setRoleName] = useState("");
  const [loading, setLoading] = useState(false);
  const [roleSelect, setRoleSelect] = useState(false);
  const url = useLocation();
  const companyId = url?.pathname.split("/")[2];
  const [checkNA, setCheckNA] = useState({
    objectives: false,
    keyResults: false,
    procedures: false,
    workflows: false,
    skills: false,
    technologies: false,
    teams: false,
    entities: false,
    meetings: false,
    coachedBy: false,
  });
  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );

  const onSubmit = (val: CreateRole) => {
    setLoading(true);
    const payload = {
      name: roleName,
      jobs: val?.jobs,
      rolePurposeStatement: val?.rolePurposeStatement,
      companyId: companyId,
      objectives: checkNA?.objectives ? "N/A" : val?.objectives,
      keyResults: checkNA?.keyResults ? [] : val?.keyResults,
      procedures: checkNA?.procedures ? "N/A" : val?.procedures,
      workflows: checkNA?.workflows ? [] : val?.workflows,
      skills: checkNA?.skills ? [] : val?.skills,
      technologies: checkNA?.technologies ? [] : val?.technologies,
      teams: checkNA?.teams ? [] : val?.teams,
      entities: checkNA?.entities ? "N/A" : val?.entities,
      meetings: checkNA?.meetings ? [] : val?.meetings,
      coachedBy: checkNA?.coachedBy
        ? "coached_by_not_available"
        : val?.coachedBy,
      department: val?.department,
    };

    createRole(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage("Role created.");
        navigate(`/company-detail/roles/${companyId}`);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  const setPurposeStatementText = () => {
    const payload = {
      name: roleName,
      type: "role",
    };
    generateAIDescription(payload).then((res) => {
      setValue("rolePurposeStatement", res?.data?.description);
    });
  };
  interface OnChangeEvent {
    target: {
      checked: boolean;
    };
  }
  const onChange = (e: OnChangeEvent, field: string): void => {
    setCheckNA({ ...checkNA, [field]: e.target.checked });
  };

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>Create Role</h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "100%", maxWidth: "992px", margin: "0 auto" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "992px",
            paddingLeft: "68px",
            paddingRight: "68px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                {!roleSelect && (
                  <div className="role_name">
                    <InputField
                      fieldName="name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify role name",
                      }}
                      isRequired
                      rules={{
                        required: "Role name is required.",
                      }}
                      validate={errors.name && "error"}
                      validMessage={errors.name && errors.name.message}
                      onInputChange={(e: any) => setRoleName(e.target.value)}
                    />
                    {roleName && (
                      <Button
                        type="text"
                        htmlType="button"
                        onClick={() => setRoleSelect(true)}
                        className="role_btn"
                      >
                        <Image
                          src="/images/check-icon.svg"
                          preview={false}
                          width={16}
                        />
                      </Button>
                    )}
                  </div>
                )}
                {roleName && roleSelect && (
                  <div className="role_title">{roleName}</div>
                )}
              </Col>
              <Col span={24}>
                <SelectField
                  fieldName="jobs"
                  label="Jobs"
                  control={control}
                  selectOption={dropdownData?.jobs}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select one or multiple jobs",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>

              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.objectives}
                    onChange={(e) => onChange(e, "objectives")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="objectives"
                  control={control}
                  initValue=""
                  label={<>Objectives</>}
                  iProps={{
                    disabled: checkNA.objectives,
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired={!checkNA.objectives ? true : false}
                  rules={{
                    required: !checkNA.objectives
                      ? "Objectives is required."
                      : false,
                  }}
                  validate={!checkNA.objectives && errors.objectives && "error"}
                  validMessage={
                    !checkNA.objectives &&
                    errors.objectives &&
                    errors.objectives.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.keyResults}
                    onChange={(e) => onChange(e, "keyResults")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="keyResults"
                  label={<>Key Results</>}
                  control={control}
                  selectOption={arrayDropdown("KEY_RESULT")}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select key results",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.keyResults,
                  }}
                  isRequired={!checkNA.keyResults ? true : false}
                  rules={{
                    required: !checkNA.keyResults
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.keyResults && errors.keyResults && "error"}
                  validMessage={
                    !checkNA.keyResults &&
                    errors.keyResults &&
                    errors.keyResults.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.procedures}
                    onChange={(e) => onChange(e, "procedures")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="procedures"
                  control={control}
                  initValue=""
                  label={<>Procedures</>}
                  isRequired={!checkNA.procedures ? true : false}
                  iProps={{
                    disabled: checkNA.procedures,
                  }}
                  rules={{
                    required: !checkNA.procedures
                      ? "Procedures is required."
                      : false,
                  }}
                  validate={!checkNA.procedures && errors.procedures && "error"}
                  validMessage={
                    !checkNA.procedures &&
                    errors.procedures &&
                    errors.procedures.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.workflows}
                    onChange={(e) => onChange(e, "workflows")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="workflows"
                  label={<>Workflows</>}
                  control={control}
                  selectOption={arrayDropdown("WORKFLOWS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select workflows",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.workflows,
                  }}
                  isRequired={!checkNA.workflows ? true : false}
                  rules={{
                    required: !checkNA.workflows
                      ? "Workflows is required."
                      : false,
                  }}
                  validate={!checkNA.workflows && errors.workflows && "error"}
                  validMessage={
                    !checkNA.workflows &&
                    errors.workflows &&
                    errors.workflows.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.skills}
                    onChange={(e) => onChange(e, "skills")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="skills"
                  label={<>Skills</>}
                  control={control}
                  selectOption={arrayDropdown("SKILL")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select skills",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.skills,
                  }}
                  isRequired={!checkNA.skills ? true : false}
                  rules={{
                    required: !checkNA.skills ? "Skills is required." : false,
                  }}
                  validate={!checkNA.skills && errors.skills && "error"}
                  validMessage={
                    !checkNA.skills && errors.skills && errors.skills.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.technologies}
                    onChange={(e) => onChange(e, "technologies")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="technologies"
                  label={<>Technologies</>}
                  control={control}
                  selectOption={arrayDropdown("TECHNOLOGIES")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select Technologies",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.technologies,
                  }}
                  isRequired={!checkNA.technologies ? true : false}
                  rules={{
                    required: !checkNA.technologies
                      ? "Technologies is required."
                      : false,
                  }}
                  validate={
                    !checkNA.technologies && errors.technologies && "error"
                  }
                  validMessage={
                    !checkNA.technologies &&
                    errors.technologies &&
                    errors.technologies.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.teams}
                    onChange={(e) => onChange(e, "teams")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="teams"
                  label={<>Teams</>}
                  control={control}
                  selectOption={arrayDropdown("TEAMS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select teams",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.teams,
                  }}
                  isRequired={!checkNA.teams ? true : false}
                  rules={{
                    required: !checkNA.teams ? "Teams is required." : false,
                  }}
                  validate={!checkNA.teams && errors.teams && "error"}
                  validMessage={
                    !checkNA.teams && errors.teams && errors.teams.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.entities}
                    onChange={(e) => onChange(e, "entities")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="entities"
                  label={<>Entities</>}
                  control={control}
                  initValue=""
                  isRequired={!checkNA.entities ? true : false}
                  iProps={{
                    disabled: checkNA.entities,
                  }}
                  rules={{
                    required: !checkNA.entities
                      ? "Entities is required."
                      : false,
                  }}
                  validate={!checkNA.entities && errors.entities && "error"}
                  validMessage={
                    !checkNA.entities &&
                    errors.entities &&
                    errors.entities.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.meetings}
                    onChange={(e) => onChange(e, "meetings")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="meetings"
                  label={<>Meetings</>}
                  control={control}
                  selectOption={arrayDropdown("MEETINGS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select meetings",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.meetings,
                  }}
                  isRequired={!checkNA.meetings ? true : false}
                  rules={{
                    required: !checkNA.meetings
                      ? "Meetings is required."
                      : false,
                  }}
                  validate={!checkNA.meetings && errors.meetings && "error"}
                  validMessage={
                    !checkNA.meetings &&
                    errors.meetings &&
                    errors.meetings.message
                  }
                />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col flex="0 0 395px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.coachedBy}
                    onChange={(e) => onChange(e, "coachedBy")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="coachedBy"
                  label={<>Select role</>}
                  control={control}
                  selectOption={dropdownData?.roles}
                  iProps={{
                    placeholder: "Select role",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.coachedBy,
                  }}
                  isRequired={!checkNA.coachedBy ? true : false}
                  rules={{
                    required: !checkNA.coachedBy ? "Role is required." : false,
                  }}
                  validate={!checkNA.coachedBy && errors.coachedBy && "error"}
                  validMessage={
                    !checkNA.coachedBy &&
                    errors.coachedBy &&
                    errors.coachedBy.message
                  }
                />
              </Col>
              <Col flex="0 0 395px">
                <SelectField
                  fieldName="department"
                  label={<>Deparment</>}
                  control={control}
                  selectOption={arrayDropdown("DEPARTMENT")}
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                  isRequired
                  rules={{
                    required: "Department is required.",
                  }}
                  validate={errors.department && "error"}
                  validMessage={errors.department && errors.department.message}
                />
              </Col>
              <Col span={24}>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontFamily: '"DM Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: " 20px",
                    color: "#161616",
                  }}
                >
                  <div>
                    Role purpose statement
                    <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Button
                        className="generate_btn"
                        onClick={setPurposeStatementText}
                      >
                        <Image
                          src="/images/MagicWand.svg"
                          alt="Generate with AI"
                          width={16}
                          height={16}
                          preview={false}
                        />
                        Generate with AI
                      </Button>
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="rolePurposeStatement"
                  control={control}
                  initValue=""
                  label=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Role purpose statement is required.",
                  }}
                  validate={errors.rolePurposeStatement && "error"}
                  validMessage={
                    errors.rolePurposeStatement &&
                    errors.rolePurposeStatement.message
                  }
                />
              </Col>
              <Col
                className="fp_footer"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="icon_dark_btn"
                  >
                    Add Role
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
