export default function Objectives() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are objectives
      </h2>
      <p className="wg_caption">
        Objectives represent the big-picture strategic goals. They are
        qualitative and outline what the organization is aiming to achieve at a
        high level. Objectives are part of strategic thinking models such as
        OKRs (Objectives and Key Results).
        <br /> <br />
        Objectives could include hitting a particular sales volume, retaining a
        higher percentage of clients, boosting website visitors by a certain
        number each month.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Identify the main goals your organization or team is striving to
              achieve.
            </li>
            <li>Make sure the objective is clear and concise.</li>
            <li>
              Link the objective to any relevant product info, marketing
              materials, or sales targets.
            </li>
            <li>
              There can be master objectives and sub-objectives supporting them.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6> Examples </h6>
          <ul>
            <li>
              Marketing Director Job: Generate 50,000 new leads through digital
              marketing campaigns and events being launched in the 3rd quarter
              of 2024. Target: 50,000 leads
            </li>
            <li>
              Sales Manager Role: Increase quarterly sales revenue by 15% by
              2025. Target: 15% revenue increase
            </li>
            <li>
              Customer Service Manager Role: Improve customer satisfaction score
              (CSAT) to 90% by the end of the year. Target: 90% CSAT
            </li>
            <li>Gross Margin %: [Insert target or value]</li>
            <li>Safety Incidents: [Insert target or value]</li>
            <li>Average Days to Ship: [Insert target or value]</li>
            <li>
              Average Days Sales in Accounts Receivable: [Insert target or
              value]
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
