import { Button, Card, Tabs, Tag } from "antd";
import { RoleType } from "../../organisms/MyJob/ducks/types";
import { useEffect, useState } from "react";
import {
  getFlowerDataBySessionID,
  getSessionById,
} from "../../organisms/MyJob/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon } from "../../atoms/CustomIcons";
import FullPageLayout from "../../templates/FullPageLayout";
import FpSessionRoleContributor from "./FpSessionRole";

export default function RoleNames() {
  const url = useLocation();
  const navigate = useNavigate();
  const teamName = url?.pathname.split("/")[3];
  const sessionId = url?.pathname.split("/")[4];
  const sessionData = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.data
  );
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
      dispatch(getSessionById(sessionId));
    }
  }, [sessionId]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>
            Flower Power session <Tag className="team_tag">{teamName}</Tag>
          </h3>
        </div>
      </Card>
      <Card>
        <div className="session_flower">
          <Tabs
            activeKey={activeTab}
            onChange={onTabChange}
            destroyInactiveTabPane={true}
            items={flowerData?.sessionPetals?.map((e: RoleType, i: number) => ({
              key: `${i + 1}`,
              label: (
                <div className={`tab-label fp-tabs-${i + 1}`}>
                  <span className="tab-number">{i + 1}</span> {e?.name}
                </div>
              ),
              children: (
                <FpSessionRoleContributor
                  data={flowerData}
                  petalTeams={flowerData?.petalTeams}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  activeData={e}
                  activeNumber={i + 1}
                  sessionId={sessionId}
                  sessionData={sessionData}
                  setLoading={setLoading}
                  teamName={teamName}
                />
              ),
            }))}
          />
        </div>
      </Card>
    </FullPageLayout>
  );
}
