import { Button, Col, Image, Row, Form, Spin } from "antd";
import InputField from "../../molecules/FormItems/InputField";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../CustomIcons";
import SelectField from "../../molecules/FormItems/SelectField";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../config/hooks";
import { getJobFilters } from "../../organisms/CompanyDetails/ducks/actions";
import { createUser } from "../../organisms/CompanyDetails/ducks/services";
import { AxiosError, AxiosResponse } from "axios";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import ErrorMessage from "../Toasts/ErrorMessage";
import SuccessMessage from "../Toasts/SuccessMessage";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const { onClose, title, companyId, updateApi } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  // const employeeFiltersOptions = useAppSelector(
  //   (state) => state?.companyDetails?.employeeFiltersOptions
  // );

  const onCloseHandler = () => {
    onClose();
  };

  type FormValues = {
    first_name: string;
    last_name: string;
    email: string;
    user_type: string;
    job: string;
  };

  const {
    control,
    getValues,
    formState: { errors, isDirty, isValid },
    trigger,
    handleSubmit,
    reset,
  } = useForm<FormValues>();

  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      firstName: val?.first_name,
      lastName: val?.last_name,
      email: val?.email,
      userType: val?.user_type,
      jobId: val?.job ?? "",
      companyId: companyId,
    };
    createUser(payload)
      .then((res: AxiosResponse) => {
        setLoading(false);
        console.log("res?.data?.id", res?.data?.id);
        SuccessMessage("Profile created.");
        dispatch(getJobFilters(companyId));
        updateApi(res?.data?.id);
        onClose();
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const saveAddMore = async () => {
    const allValues = getValues();
    const isValid = await trigger();

    if (isValid) {
      setLoading(true);
      const payload = {
        firstName: allValues?.first_name,
        lastName: allValues?.last_name,
        email: allValues?.email,
        userType: allValues?.user_type,
        jobId: allValues?.job ?? "",
        companyId: companyId,
      };
      createUser(payload)
        .then((res: AxiosResponse) => {
          setLoading(false);
          SuccessMessage("Profile created.");
          dispatch(getJobFilters(companyId));
          updateApi(res?.data?.id);
          reset();
        })
        .catch((e: unknown) => {
          if (e instanceof AxiosError) {
            setLoading(false);
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
          } else {
            setLoading(false);
            ErrorMessage("Something went wrong");
          }
        });
    }
  };

  const userTypeDropDown = [
    {
      value: "INDIVIDUAL_CONTRIBUTOR",
      label: "Individual Contributor",
      desc: (
        <>
          Participates in the Flower Power sessions and has a view access to the
          company's organizational structure.
        </>
      ),
    },
    {
      value: "COMPANY_ADMIN",
      label: "Company Admin",
      desc: "Manages the company and its employees. Can facilitate and participate in the Flower Power sessions",
    },
  ];

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <div className="modal_content">
            <Row gutter={16}>
              <Col span={12}>
                <h4>
                  First name <span className="red_color">*</span>
                </h4>
                <InputField
                  fieldName="first_name"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Type first name",
                  }}
                  isRequired
                  rules={{
                    required: "First name is required.",
                  }}
                  validate={errors.first_name && "error"}
                  validMessage={errors.first_name && errors.first_name.message}
                />
              </Col>
              <Col span={12}>
                <h4>
                  Last name <span className="red_color">*</span>
                </h4>
                <InputField
                  fieldName="last_name"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Type last name",
                  }}
                  isRequired
                  rules={{
                    required: "Last name is required.",
                  }}
                  validate={errors.last_name && "error"}
                  validMessage={errors.last_name && errors.last_name.message}
                />
              </Col>
              <Col span={24}>
                <h4>
                  Email address <span className="red_color">*</span>
                </h4>
                <InputField
                  fieldName="email"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Type email address",
                  }}
                  isRequired
                  rules={{
                    required: "Email is required.",
                    pattern: {
                      value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Email is required.",
                    },
                  }}
                  validate={errors.email && "error"}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>
              {/* <Col span={24}>
                <SelectField
                  fieldName="job"
                  label="Job title"
                  control={control}
                  selectOption={employeeFiltersOptions?.job}
                  className="fp_select"
                  iProps={{
                    placeholder: "Select job",
                    suffixIcon: <DropdownIcon />,
                  }}
                  tooltipTitle="The position at your company that the employee occupies."
                />
              </Col> */}
              <Col span={24}>
                <SelectField
                  fieldName="user_type"
                  label="Access level"
                  control={control}
                  selectOption={userTypeDropDown}
                  className="fp_select"
                  iProps={{
                    placeholder: "Select access level",
                    suffixIcon: <DropdownIcon />,
                    popupClassName: "fp_employee_popup",
                  }}
                  isRequired
                  rules={{
                    required: "Access level is required.",
                  }}
                  validate={errors.user_type && "error"}
                  validMessage={errors.user_type && errors.user_type.message}
                  tooltipTitle="This defines what the employee can do within the system."
                />
              </Col>
            </Row>
          </div>
          <div className="modal_footer">
            <Button
              htmlType="button"
              className="cancelBtn defaultBtnColor"
              type="default"
              size="large"
              onClick={saveAddMore}
              disabled={!isDirty || !isValid}
            >
              Save & create more
            </Button>

            <Button
              htmlType="submit"
              className="actionBtn dangerBtnColor"
              type="primary"
              size="large"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};
