import { Fragment } from "react/jsx-runtime";
import { RoleType } from "../../../organisms/MyJob/ducks/types";
import { flowerMapGen } from "../../../atoms/JobDetails/Components/FlowerSession/SessionSteps/Components/FlowerMapGen";

type PropsType = {
  data: RoleType[];
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  activeData?: RoleType;
  petalTeams: any;
  teamName: string;
};

export default function SessionFlower(props: PropsType) {
  const { data, activeTab, setActiveTab, petalTeams, teamName } = props;
  const currentTeamDetails = petalTeams.find(
    (e: { team_name: string }) => e.team_name === teamName
  );

  console.log("petalTeams", petalTeams);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className={`flower flowerReady ${flowerMapGen[data.length].class}`}>
      {data?.map((e: any, i: number) => (
        <Fragment key={i + 1}>
          <div
            className={`leaf leaf-${i + 1} ${
              activeTab ? parseInt(activeTab) === i + 1 && "active" : ""
            }`}
            onClick={() =>
              setActiveTab ? setActiveTab((i + 1).toString()) : null
            }
          >
            <div className="petal_svg">
              {flowerMapGen[data.length].petalShape}
            </div>
            <div className="leaf_text">
              <div className="leaf_detail">
                <h4>
                  {currentTeamDetails?.id &&
                  currentTeamDetails?.contributions?.length > 0 &&
                  currentTeamDetails?.contributions?.find(
                    (item1: any) => e.id === item1.petal_id
                  )?.serious_name ? (
                    `S: ${
                      currentTeamDetails?.contributions?.find(
                        (item1: any) => e.id === item1.petal_id
                      )?.serious_name
                    }`
                  ) : (
                    <span style={{ opacity: 0.5 }}>Role names...</span>
                  )}
                </h4>
                <h4>
                  {currentTeamDetails?.id &&
                  currentTeamDetails?.contributions?.length > 0 &&
                  currentTeamDetails?.contributions?.find(
                    (item1: any) => e.id === item1.petal_id
                  )?.funny_name
                    ? `F: ${
                        currentTeamDetails?.contributions?.find(
                          (item1: any) => e.id === item1.petal_id
                        )?.funny_name
                      }`
                    : ""}
                </h4>
                {e?.purpose_statement && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      e?.purpose_statement as string
                    )}
                    className="leaf_paragraph"
                  />
                )}
              </div>
              <div className="leaf_number">{i + 1}</div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
