import { useEffect, useState } from "react";
import { Avatar, Button, Card, Col, Image, Row, Select, Spin } from "antd";
import {
  CaretRightOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import { SearchIcon } from "../../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getParticipantsForSession } from "../../../../organisms/MyJob/ducks/actions";
import { createSession } from "../../../../organisms/MyJob/ducks/services";
import { AxiosError, AxiosResponse } from "axios";
import ErrorMessage from "../../../Toasts/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { Participants } from "../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../config/constants";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const { onClose, title, btnText, jobId, companyId, paragraph, participants } =
    props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const onCloseHandler = () => {
    onClose();
  };
  const participantsData = useAppSelector(
    (state) => state?.jobDetail?.participantsData
  );

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      job_id: jobId,
      company_id: companyId,
      employees: selectedItems,
    };

    createSession(payload)
      .then((res: AxiosResponse) => {
        if (res && res?.data?.id) {
          setLoading(false);
          window.location.assign(`/flower-session/${res?.data?.id}`);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          setLoading(false);
        } else {
          ErrorMessage("Something went wrong");
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (jobId && companyId) {
      const payload = {
        job_id: jobId,
        company_id: companyId,
      };

      dispatch(getParticipantsForSession(payload));
    }
  }, [dispatch, jobId, companyId]);

  const filteredOptions = participantsData?.filter(
    (o: any) => !selectedItems.includes(o?.id)
  );

  const completeData = participantsData?.filter((o: any) =>
    selectedItems.includes(o?.id)
  );

  const setSelectedParticipants = (value: string) => {
    const dummyArray = [];
    dummyArray.push(value);
    setSelectedItems([...selectedItems, ...dummyArray]);
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>

        <div className="modal_content">
          <Row gutter={16}>
            {paragraph && (
              <Col span={24} style={{ marginBottom: 16 }}>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  {paragraph}
                </p>
              </Col>
            )}
            <Col flex="0 0 100%">
              <label className="add_part_label">
                {paragraph ? "Add participants" : "Select participants"}
              </label>
              <Select
                className="participant_select"
                placeholder="Search employees"
                value={null}
                onChange={setSelectedParticipants}
                style={{ width: "100%" }}
                suffixIcon={<SearchIcon />}
                options={filteredOptions.map((e: any) => ({
                  label: (
                    <>
                      <div className="detail_option">
                        {e?.image?.url ? (
                          <div className="participant_avatar">
                            <Avatar src={e?.image?.url} />
                          </div>
                        ) : (
                          <div className="participant_avatar">
                            <Avatar>
                              {getInitials(e?.first_name, e?.last_name)}
                            </Avatar>
                          </div>
                        )}

                        <div className="user_name">
                          {e?.first_name ?? ""} {e?.last_name ?? ""}
                        </div>
                        <div className="user_position">
                          {e?.job?.name ?? (
                            <span style={{ opacity: 0.5 }}>No job title</span>
                          )}
                        </div>
                      </div>
                    </>
                  ),
                  value: e?.id,
                }))}
                disabled={paragraph?.length > 0}
              />

              {completeData?.length > 0 && !participants && (
                <div className="participants_modal_wrapper">
                  {completeData?.map((e: Participants) => (
                    <Card className="participants_card_modal" key={e?.id}>
                      {e?.image ? (
                        <div className="participant_avatar">
                          <Avatar src={e?.image?.url} />
                        </div>
                      ) : (
                        <div className="participant_avatar">
                          <Avatar>
                            {getInitials(e?.first_name, e?.last_name)}
                          </Avatar>
                        </div>
                      )}
                      <div className="participant_info">
                        <h6>{`${e?.first_name ?? ""} ${
                          e?.last_name ?? ""
                        }`}</h6>
                        <p>
                          {e?.job?.name ?? (
                            <span style={{ opacity: 0.5 }}>No job title</span>
                          )}
                        </p>
                      </div>
                      <div className="participant_check">
                        <Button
                          type="link"
                          onClick={() =>
                            setSelectedItems(
                              selectedItems.filter((item) => item !== e?.id)
                            )
                          }
                        >
                          <Image
                            src="/images/close-icon.svg"
                            preview={false}
                            width={16}
                            height={16}
                          />
                        </Button>
                      </div>
                    </Card>
                  ))}
                </div>
              )}

              {participants?.length > 0 && (
                <div className="participants_modal_wrapper">
                  {participants?.map((e: Participants) => (
                    <Card className="participants_card_modal" key={e?.id}>
                      {e?.image ? (
                        <div className="participant_avatar">
                          <Avatar src={e?.image?.url} />
                        </div>
                      ) : (
                        <div className="participant_avatar">
                          <Avatar>
                            {getInitials(e?.first_name, e?.last_name)}
                          </Avatar>
                        </div>
                      )}
                      <div className="participant_info">
                        <h6>{`${e?.first_name ?? ""} ${
                          e?.last_name ?? ""
                        }`}</h6>
                        <p>
                          {e?.job?.name ?? (
                            <span style={{ opacity: 0.5 }}>No job title</span>
                          )}
                        </p>
                      </div>
                      <div className="participant_check">
                        <Button
                          type="link"
                          onClick={() =>
                            setSelectedItems(
                              selectedItems.filter((item) => item !== e?.id)
                            )
                          }
                        >
                          <Image
                            src="/images/close-icon.svg"
                            preview={false}
                            width={16}
                            height={16}
                          />
                        </Button>
                      </div>
                    </Card>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn defaultBtnColor"
            type="default"
            size="large"
            onClick={() => onCloseHandler()}
          >
            Cancel
          </Button>

          <Button
            className="actionBtn icon_dark_btn"
            type="primary"
            size="large"
            disabled={selectedItems.length === 0 && !participants}
            onClick={
              !participants
                ? onSubmit
                : () => navigate(`/ws-session/${jobId}/${companyId}`)
            }
          >
            {btnText} <CaretRightOutlined />
          </Button>
        </div>
      </div>
    </Spin>
  );
};
