import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import DashboardTemplate from "../../../../templates/Dashboard";
import {
  emptyJobDetailsData,
  getJobDetails,
} from "../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import JobDetails from "../../../../atoms/JobDetails";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";

export default function MyJob() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const [loading, setLoading] = useState(false);
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    dispatch(emptyJobDetailsData());
  }, [dispatch]);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  return (
    <DashboardTemplate loading={jobDetailData?.loading || loading}>
      <JobDetails
        jobDetailData={jobDetailData?.detailData}
        setLoading={setLoading}
      />
    </DashboardTemplate>
  );
}
