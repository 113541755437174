import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Alert, Button, Image, Spin } from "antd";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const { onClose, title, btnText, btnApi, loading, paragraph } = props;

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={onCloseHandler}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>

        <div className="modal_content">
          <p style={{ fontSize: "16px", lineHeight: "24px" }}>{paragraph}</p>
          <Alert
            message="You will not be able to restart this activity again."
            type="warning"
            className="warning_alert"
            showIcon
          />
        </div>

        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn dangerBtnColor"
            type="default"
            size="large"
            onClick={onCloseHandler}
          >
            No, I will wait
          </Button>

          <Button
            htmlType="button"
            className="actionBtn defaultBtnColor"
            type="primary"
            size="large"
            onClick={btnApi}
            loading={loading}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </Spin>
  );
};
