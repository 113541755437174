export default function ReportsTo() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        Reports To
      </h2>
      <p className="wg_caption">
        Refers to the job to which your job is accountable indicating the direct
        supervisor or manager responsible for overseeing the results of your
        work, your performance, giving assignments, etc.
      </p>
    </>
  );
}
