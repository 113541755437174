import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const MenuSelectedIconSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.665 2.03156C15.3691 1.80964 14.9252 1.80964 14.6292 2.17951L13.385 3.70714C12.2013 2.89341 10.7956 2.44956 9.242 2.44956C5.17297 2.44956 1.84375 5.77847 1.84375 9.84707C1.84375 13.9157 5.17297 17.2446 9.242 17.2446C13.311 17.2446 16.6403 13.9157 16.6403 9.84707C16.6403 8.36757 16.1964 7.03602 15.4565 5.85242L14.9387 6.44422L14.4208 7.03602L10.1298 12.4362C9.98185 12.5842 9.7599 12.7321 9.53795 12.7321C9.316 12.7321 9.16805 12.6582 9.0201 12.5102L5.8388 9.10732C5.61685 8.88542 5.61685 8.51552 5.69085 8.29362C5.69085 8.21962 5.76485 8.14567 5.8388 8.07167C6.13475 7.77577 6.57865 7.77577 6.8746 8.07167L9.46395 10.8827L13.533 5.77847L15.665 3.06722C15.9315 2.71921 15.925 2.2266 15.665 2.03156Z"
      fill="#0D9B54"
    />
  </svg>
);

const MenuSelectedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={MenuSelectedIconSvg} {...props} />
);

export default MenuSelectedIcon;
