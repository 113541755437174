export default function ProceduresPolicies() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are procedures, work instructions, and policies
      </h2>
      <p className="wg_caption">
        Procedures are detailed, step-by-step instructions that describe how to
        perform specific tasks or activities. They are often but not always
        attached to a workflow. <br /> <br /> Work Instructions are detailed
        guides that describe how to perform a specific task within a procedure.{" "}
        <br /> <br />
        Policies are formalized guidelines that outline the principles, rules,
        and guidelines set by an organization to govern behavior and ensure
        compliance with internal and external regulations.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define</h6>
          <ul>
            <li>
              Identify all tasks performed by your job/role and see if they are
              part of procedures or must follow any work instructions or
              policies.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6> Examples </h6>
          <ul>
            <li>
              Sales Manager:
              <ul>
                <li>Leads qualification checklist</li>
                <li>How to enter a new lead into CRM</li>
                <li>Sales ethics policy</li>
              </ul>
            </li>
            <li>
              Project Manager (Construction):
              <ul>
                <li>How to do a site inspection</li>
                <li>How to complete a safety checklist</li>
                <li>Health and safety policy</li>
              </ul>
            </li>
            <li>
              Customer Service Manager:
              <ul>
                <li>Handling customer complaints</li>
                <li>How to log a support ticket</li>
                <li>Customer privacy policy</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
