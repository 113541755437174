import { Button, Card, Col, Dropdown, Image, Row, Tag } from "antd";
import { Fragment } from "react/jsx-runtime";
import { RoleType } from "../../../../organisms/MyJob/ducks/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  addExistingRolesToJob,
  deleteRole,
} from "../../../../organisms/CompanyDetails/ducks/services";
import SuccessMessage from "../../../Toasts/SuccessMessage";
import ErrorMessage from "../../../Toasts/ErrorMessage";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobDetails } from "../../../../organisms/MyJob/ducks/actions";
import ConfirmDelete from "../../../ConfirmDelete";
import { Popup } from "../../../Popup";
import { flowerMapGen } from "../FlowerSession/SessionSteps/Components/FlowerMapGen";

type PropsType = {
  data: RoleType[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  activeData: RoleType;
  activeNumber: number;
  jobName: string;
  departmentName: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function JobTabs(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    activeNumber,
    jobName,
    departmentName,
    setLoading,
  } = props;
  const navigate = useNavigate();
  const url = useLocation();
  const dispatch = useAppDispatch();
  const jobId = url?.pathname.split("/")[2];
  const [existingRolesId, setExistingRolesId] = useState<Array<string>>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  const removeRoleFromThisJob = () => {
    setLoading(true);
    const filteredRoles = existingRolesId.filter((e) => e !== activeData?.id);
    const payload = {
      job_id: jobId,
      roles: filteredRoles,
    };

    addExistingRolesToJob(payload)
      .then(() => {
        SuccessMessage("Role removed");
        dispatch(getJobDetails(jobId));
        setLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      const existingRoles: string[] = [];
      data?.map((e: { id: string }) => {
        existingRoles.push(e?.id);
        return existingRoles;
      });
      setExistingRolesId(existingRoles);
    }
  }, [data]);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
          onClick={() =>
            navigate(`/edit-role/${activeData?.id}/${activeData?.company_id}`)
          }
        >
          Edit role
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={removeRoleFromThisJob}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          Remove from this job
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          Delete role
        </Button>
      ),
    },
  ];

  const handleDeleteRole = async () => {
    setDeleteLoading(true);
    deleteRole(activeData?.id as string)
      .then(() => {
        dispatch(getJobDetails(jobId));
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading="Are you sure you want to delete this role?"
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete role"
        btnApi={handleDeleteRole}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  return (
    <Row gutter={16}>
      <Col span={12} className="flowerArea">
        <div className="flower_wrapper finalizedFlower job_detail_flower_20">
          <div className={`flower ${flowerMapGen[data.length].class}`}>
            {data?.map((e: any, i: number) => (
              <Fragment key={i + 1}>
                <div
                  className={`leaf leaf-${i + 1} ${
                    parseInt(activeTab) === i + 1 && "active"
                  }`}
                  onClick={() => setActiveTab((i + 1).toString())}
                >
                  <div className="petal_svg">
                    {flowerMapGen[data.length].petalShape}
                  </div>
                  <div className="leaf_text">
                    <div className="leaf_detail">
                      <h4>{e?.name}</h4>
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          e?.purpose_statement as string
                        )}
                        className="leaf_paragraph"
                      />
                    </div>
                    <div className="leaf_number">{i + 1}</div>
                  </div>
                </div>
              </Fragment>
            ))}
            <div className="flower_position">{jobName}</div>
          </div>
        </div>
      </Col>
      <Col span={12} className="flowerDetailArea">
        <div className="flower_tab_wrapper small_tags">
          <div className="flower_tab_heading">
            <div className="active_state">
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              {activeData?.name}
            </div>

            {Object.keys(accountData)?.length > 0 &&
              accountData?.type !== "INDIVIDUAL_CONTRIBUTOR" && (
                <div className="ft_edit">
                  <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <Button style={{ border: "unset", boxShadow: "unset" }}>
                      <Image
                        src="/images/vertical_dots.svg"
                        width={3}
                        height={15}
                        alt="vertical dots"
                        preview={false}
                      />
                    </Button>
                  </Dropdown>
                </div>
              )}
          </div>

          <div className="inline_widgets">
            {/* <div className="flower_tab_para">
              <Card className="card_role">
                <p className="role_title">Coached by:</p>
                <div
                  className="role_name"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    Executive coach <img src="/images/visit_icon.svg" />
                  </div>
                  <div>
                    <Avatar.Group
                      className="avatarGroup"
                      size="small"
                      max={{
                        count: 3,
                        style: {
                          color: "#161616",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          fontSize: "10px",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <Avatar>AR</Avatar>
                      <Avatar src="/images/avatar_1.jpg" />
                      <Avatar>SN</Avatar>
                    </Avatar.Group>
                  </div>
                </div>
              </Card>
            </div> */}
            <div className="flower_tab_para">
              <Card className="card_role">
                <p className="role_title"> Department </p>
                <div className="role_name department_name">
                  {departmentName ?? "-"}
                </div>
              </Card>
            </div>
          </div>

          <div className="flower_tab_para full_width mb-20">
            <h4>Purpose statement</h4>
            {activeData?.purpose_statement ? (
              <div
                dangerouslySetInnerHTML={createMarkup(
                  activeData?.purpose_statement as string
                )}
              />
            ) : (
              "-"
            )}
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Objectives</h4>
              {activeData?.objectives ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    activeData?.objectives as string
                  )}
                />
              ) : (
                "-"
              )}
            </div>

            <div className="flower_tab_para">
              <h4>Key results</h4>
              {activeData?.key_results?.length > 0
                ? activeData?.key_results?.map((e, i) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Procedures/WIs/Policies</h4>
              {activeData?.procedures ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    activeData?.procedures as string
                  )}
                />
              ) : (
                "-"
              )}
            </div>

            <div className="flower_tab_para">
              <h4>Workflows</h4>
              {activeData?.workflows?.length > 0
                ? activeData?.workflows?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Skills</h4>
              {activeData?.skills?.length > 0
                ? activeData?.skills?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>

            <div className="flower_tab_para">
              <h4>Technologies</h4>
              {activeData?.technologies?.length > 0
                ? activeData?.technologies?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Teams</h4>
              {activeData?.teams?.length > 0
                ? activeData?.teams?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>

            <div className="flower_tab_para">
              <h4>Entities</h4>
              {activeData?.entities ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    activeData?.entities as string
                  )}
                />
              ) : (
                "-"
              )}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Meetings</h4>
              {activeData?.meetings?.length > 0
                ? activeData?.meetings?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>
        </div>
      </Col>
      <Popup {...deleteConfirmationPopup} />
    </Row>
  );
}
