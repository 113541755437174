import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const InfoIconOutlinedSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.75 8C0.75 4.0125 4.0125 0.75 8 0.75C11.9875 0.75 15.25 4.0125 15.25 8C15.25 11.9875 11.9875 15.25 8 15.25C4.0125 15.25 0.75 11.9875 0.75 8ZM8.00003 4.92332C8.46717 4.92332 8.84586 4.54463 8.84586 4.07749C8.84586 3.61035 8.46717 3.23166 8.00003 3.23166C7.53289 3.23166 7.1542 3.61035 7.1542 4.07749C7.1542 4.54463 7.53289 4.92332 8.00003 4.92332ZM7.27503 12.0432C7.27503 12.4782 7.56503 12.7682 8.00003 12.7682C8.43503 12.7682 8.72503 12.4057 8.72503 12.0432V6.70237C8.72503 6.26737 8.43503 5.97737 8.00003 5.97737C7.56503 5.97737 7.27503 6.26737 7.27503 6.70237V12.0432Z"
      fill="#9396A6"
    />
  </svg>
);

const InfoIconOutlined = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={InfoIconOutlinedSvg} {...props} />
);

export default InfoIconOutlined;
