import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getJobDetails = (jobId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.get(`${baseURL}getJobDetails/${jobId}`);
      dispatch({
        type: action_types.JOB_DETAILS,
        data: { data: data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.JOB_DETAILS,
        data: { loading: false },
      });
    }
  };
};

export const emptyJobDetailsData = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.JOB_DETAILS,
      data: { data: null },
    });
  };
};

export const getParticipantsForSession = (payload: {
  job_id: string;
  company_id: string;
}) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.post(
      `${baseURL}getParticipantsForSession`,
      payload
    );
    dispatch({
      type: action_types.PARTICIPANTS_LIST,
      data,
    });
  };
};

export const getSessionById = (sessionId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.get(`${baseURL}getSessionById/${sessionId}`);
      dispatch({
        type: action_types.SESSION_PARTICIPANTS,
        data: { data: data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.SESSION_PARTICIPANTS,
        data: { loading: false },
      });
    }
  };
};

export const clearSessionById = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SESSION_PARTICIPANTS,
      data: {},
    });
  };
};

export const getRoleDescriptorForSessionByUserId = (sessionId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(
      `${baseURL}getRoleDescriptorForSessionByUserId/${sessionId}`
    );
    dispatch({
      type: action_types.ROLE_DESCRIPTORS,
      data,
    });
  };
};

export const getPetalsWithRoleDescriptorsBySessionId = (session_id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(
      `${baseURL}getPetalsWithRoleDescriptorsBySessionId/${session_id}`
    );
    dispatch({
      type: action_types.ROLE_DESCRIPTORS_BY_SESSION_ID,
      data,
    });
  };
};

export const getFlowerDataBySessionID = (sessionId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(
      `${baseURL}getFlowerDataBySessionID/${sessionId}`
    );
    dispatch({
      type: action_types.SESSION_FLOWER_DATA,
      data,
    });
  };
};

export const clearFlowerDataBySessionID = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SESSION_FLOWER_DATA,
      data: {},
    });
  };
};

export const createPetalTeamsBySessionIdD = (payload: any) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.post(
      `${baseURL}createPetalTeamsBySessionId`,
      payload
    );
    dispatch({
      type: action_types.PETALS_SESSION_DATA,
      data,
    });
  };
};
