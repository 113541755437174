import { Row, Col, Tabs, Card, Button } from "antd";
import type { TabsProps } from "antd";
import GeneralSettings from "./GeneralSettings";
import FullPageLayout from "../../templates/FullPageLayout";
import { CloseIcon, EditIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import KeyResult from "./KeyResult";
import Skills from "./Skills";
import Technologies from "./Technologies";
import Teams from "./Teams";
import Meetings from "./Meetings";
import Department from "./Department";
import Workflows from "./Workflows";
import { useState } from "react";

export default function CompanySettings() {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("GENERAL_SETTING");

  const items: TabsProps["items"] = [
    {
      key: "GENERAL_SETTING",
      label: "General settings",
      children: <GeneralSettings />,
      icon: <EditIcon />,
    },
    {
      key: "2",
      label: "Jobs & roles settings",
      disabled: true,
    },
    {
      key: "DEPARTMENT",
      label: "Departments",
      children: <Department />,
    },
    {
      key: "KEY_RESULT",
      label: "Key results",
      children: <KeyResult />,
    },
    {
      key: "WORKFLOWS",
      label: "Workflows",
      children: <Workflows />,
    },
    {
      key: "SKILL",
      label: "Skills",
      children: <Skills />,
    },
    {
      key: "TECHNOLOGIES",
      label: "Technologies",
      children: <Technologies />,
    },
    {
      key: "TEAMS",
      label: "Teams",
      children: <Teams />,
    },
    {
      key: "MEETINGS",
      label: "Meetings",
      children: <Meetings />,
    },
  ];

  const onTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>Company settings</h3>
        </div>
      </Card>

      <div className="fp_layout_content job_roles_settings">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              items={items}
              tabPosition="left"
              className="company_settings_tabs"
              destroyInactiveTabPane={true}
              onChange={onTabChange}
              activeKey={activeKey}
            />
          </Col>
        </Row>
      </div>
    </FullPageLayout>
  );
}
