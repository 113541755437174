export default function Teams() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are Teams
      </h2>
      <p className="wg_caption">
        Teams are groups of individuals who work collaboratively towards common
        goals and objectives.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Identify projects (internal and external) or objectives that
              you’re working towards together with other individual
              contributors.
            </li>
            <li>Notice who you frequently interact with on your role/job.</li>
            <li>Consult with your supervisor.</li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6>Examples</h6>
          <ul>
            <li>Sales Team, Technical Support Team, Project X Team.</li>
          </ul>
        </div>
      </div>
    </>
  );
}
