import { Card, Button, Empty, Row, Col, Image } from "antd";
import WorksheetGuide from "../../../../../atoms/WorksheetGuide";
import { useState } from "react";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step1(props: PropsType) {
  const { setCurrent, setLoading } = props;
  const [openWorksheet, setOpenWorksheet] = useState(false);

  const nextStep = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCurrent(1);
    }, 500);
  };

  return (
    <>
      <Card className="fp_layout_content session_card">
        <div className="cardContent">
          <div className="empty_card">
            <Empty
              image={"/images/empty/ws-session-introduction.svg"}
              imageStyle={{ height: 220, marginBottom: 32 }}
              description={
                <>
                  <h4>Worksheet session introduction </h4>
                  <p>
                    During this session, you and your team digs deeper,
                    interrogating each role you own, thinking about why they
                    exist, what they should accomplish, and every node they
                    connect with – all relevant meetings, procedures, workflows,
                    teams, departments, and so on.
                  </p>
                </>
              }
            >
              <Row gutter={[16, 16]}>
                <Col
                  span={24}
                  className="empty_sessions_btn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className="icon_grey_btn"
                    onClick={() => setOpenWorksheet(true)}
                  >
                    <Image
                      src="/images/session/worksheet.svg"
                      preview={false}
                      width={18}
                    />
                    Open worksheet guide
                  </Button>

                  <Button
                    className="icon_dark_btn"
                    onClick={nextStep}
                    style={{ height: "40px", marginLeft: "10px" }}
                  >
                    Proceed to worksheet
                    <Image
                      src="/images/session/proceed.svg"
                      preview={false}
                      width={6}
                      height={12}
                    />
                  </Button>
                </Col>
              </Row>
            </Empty>
          </div>
        </div>
      </Card>
      <div className="ws_session_drawer">
        <WorksheetGuide
          openWorksheet={openWorksheet}
          setOpenWorksheet={setOpenWorksheet}
        />
      </div>
    </>
  );
}
