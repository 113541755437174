export default function MentoredBy() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        Mentored By
      </h2>
      <p className="wg_caption">
        A Mentor is someone you turn to in confidence for guidance about your
        career or path in the organization. Consider a time when you worked
        somewhere, and your ad-hoc mentor left for another company. “Well, what
        am I, chopped liver?” you wondered, after all that time spent building a
        relationship that’s now gone. Your second thought might have been, “I
        guess I better follow them...” Being overt about Mentoring is super
        healthy.
      </p>
    </>
  );
}
