export default function CoachedBy() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        Coached By
      </h2>
      <p className="wg_caption">
        Refers to another Role, typically a SME (Subject Matter Expert). You can
        ask yourself: “Who do I turn to with questions about my Role?”.
      </p>
    </>
  );
}
