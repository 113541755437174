import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  Radio,
  RadioChangeEvent,
  Row,
  Tooltip,
} from "antd";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import FpSessionReady from "../Components/FpSessionReady";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import {
  createPetalTeamsBySessionIdD,
  getFlowerDataBySessionID,
} from "../../../../../../organisms/MyJob/ducks/actions";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
// import { createPetalTeamsBySessionId } from "../../../../../../organisms/MyJob/ducks/services";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step5(props: PropsType) {
  const { setCurrent, sessionId, sessionData, setLoading } = props;
  const [divideData, setDivideData] = useState<null | string>(null);
  const dispatch = useAppDispatch();
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );
  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  const onChange = (e: RadioChangeEvent) => {
    setDivideData(e.target.value);
  };

  const makeTeams = () => {
    setLoading(true);

    let teamNumber: { name: string }[] = [];

    if (divideData === "1") {
      teamNumber = [
        {
          name: "team-A",
        },
      ];
    }
    if (divideData === "2") {
      teamNumber = [
        {
          name: "team-A",
        },
        {
          name: "team-B",
        },
      ];
    }
    if (divideData === "3") {
      teamNumber = [
        {
          name: "team-A",
        },
        {
          name: "team-B",
        },
        {
          name: "team-C",
        },
      ];
    }

    const payload = {
      session_id: sessionId,
      teamNumber: teamNumber,
    };

    dispatch(createPetalTeamsBySessionIdD(payload));
    setTimeout(() => {
      if (divideData === "1") {
        const payloadScreen = {
          last_session_screen: 7,
        };
        updateSessionById(payloadScreen, sessionId).then(() => {
          setLoading(false);
          setCurrent(7);
        });
      }
      if (divideData === "2" || divideData === "3") {
        const payloadScreen = {
          last_session_screen: 6,
        };
        updateSessionById(payloadScreen, sessionId).then(() => {
          setLoading(false);
          setCurrent(6);
        });
      }
    }, 1000);
  };

  useEffect(() => {
    if (flowerData?.petalTeams?.length === 1) {
      setDivideData("1");
    } else if (flowerData?.petalTeams?.length === 2) {
      setDivideData("2");
    } else if (flowerData?.petalTeams?.length === 3) {
      setDivideData("3");
    } else {
      setDivideData(null);
    }
  }, [flowerData]);

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            {flowerData?.sessionPetals && (
              <>
                <FpSessionReady
                  data={flowerData?.sessionPetals}
                  setCurrent={setCurrent}
                  activeTab="1"
                />
                <div className="flower_position">
                  <div className="flew_wrapper">
                    <h4>{sessionData?.job?.name}</h4>
                    <p>
                      {sessionData?.c_person?.first_name ?? ""}{" "}
                      {sessionData?.c_person?.last_name ?? ""}{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card
          className="session_fp_card fp_session_role"
          style={{ paddingTop: 137 }}
        >
          <div className="centered_div">
            <h4 style={{ marginBottom: 8 }}>Divide participants into teams</h4>
            <p style={{ marginBottom: "10px" }}>
              Each team will need to come up with a serious and funny name for
              each role.
            </p>
            <p style={{ marginBottom: "16px" }}>
              Then, the entire group debate each role name and select the one
              you agree is the best. Yes, it is like a competition, meant to be
              fun.
            </p>
            <div className="participants_card split_screen">
              <Radio.Group
                buttonStyle="solid"
                onChange={onChange}
                value={divideData}
              >
                <Radio.Button value="1">
                  <div className="participant_info" style={{ padding: 0 }}>
                    <h6>Don’t split</h6>
                  </div>
                  <div className="participant_check">
                    <Image
                      src="/images/session/checked-icon.svg"
                      preview={false}
                      width={18}
                      height={18}
                    />
                  </div>
                </Radio.Button>
                <Radio.Button
                  value="2"
                  disabled={sessionData?.participants?.length < 4}
                >
                  <Tooltip
                    title={
                      sessionData?.participants?.length < 4
                        ? "Requires at least 4 people"
                        : null
                    }
                  >
                    <div className="participant_info" style={{ padding: 0 }}>
                      <h6>2 teams</h6>
                    </div>
                    {sessionData?.participants?.length > 3 && (
                      <div className="participant_check">
                        <Image
                          src="/images/session/checked-icon.svg"
                          preview={false}
                          width={18}
                          height={18}
                        />
                      </div>
                    )}
                  </Tooltip>
                </Radio.Button>
                <Radio.Button
                  value="3"
                  disabled={sessionData?.participants?.length < 6}
                >
                  <Tooltip
                    title={
                      sessionData?.participants?.length < 6
                        ? "Requires at least 6 people"
                        : null
                    }
                  >
                    <div className="participant_info" style={{ padding: 0 }}>
                      <h6>3 teams</h6>
                    </div>
                    {sessionData?.participants?.length > 5 && (
                      <div className="participant_check">
                        <Image
                          src="/images/session/checked-icon.svg"
                          preview={false}
                          width={18}
                          height={18}
                        />
                      </div>
                    )}
                  </Tooltip>
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="steps_footer" style={{ border: 0 }}>
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(4)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={makeTeams}
                disabled={!divideData}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
