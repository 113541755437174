import { Row, Col, Button, Card, Form, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import TextEditor from "../../../../molecules/FormItems/TextEditor";
import { useCallback, useEffect, useState } from "react";
import { getUserDetails } from "../../../MyAccount/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import SelectField from "../../../../molecules/FormItems/SelectField";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
  getRoleDetails,
} from "../../ducks/actions";
import { generateAIDescription, updateRoleById } from "../../ducks/services";
import { UpdateRole } from "../../ducks/types";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";

export default function EditRoles() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const url = useLocation();
  const roleId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateRole>();
  const [checkNA, setCheckNA] = useState({
    objectives: false,
    key_results: false,
    procedures: false,
    workflows: false,
    skills: false,
    technologies: false,
    teams: false,
    entities: false,
    meetings: false,
    coached_by: false,
  });
  const [loading, setLoading] = useState(false);
  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );

  const roleData = useAppSelector(
    (state) => state?.companyDetails?.roleDetails
  );

  const onSubmit = (val: UpdateRole) => {
    setLoading(false);
    const payload = {
      name: roleData?.details?.name,
      jobs: val?.jobs,
      purpose_statement: val?.purpose_statement,
      objectives: checkNA?.objectives ? "N/A" : val?.objectives,
      key_results: checkNA?.key_results ? [] : val?.key_results,
      procedures: checkNA?.procedures ? "N/A" : val?.procedures,
      workflows: checkNA?.workflows ? [] : val?.workflows,
      skills: checkNA?.skills ? [] : val?.skills,
      technologies: checkNA?.technologies ? [] : val?.technologies,
      teams: checkNA?.teams ? [] : val?.teams,
      entities: checkNA?.entities ? "N/A" : val?.entities,
      meetings: checkNA?.meetings ? [] : val?.meetings,
      company_id: companyId,
      coached_by: checkNA?.coached_by
        ? "coached_by_not_available"
        : val?.coached_by,
      department: val?.department,
    };

    updateRoleById(payload, roleId)
      .then(() => {
        setLoading(false);
        SuccessMessage("Role updated.");
        navigate(`/company-detail/roles/${companyId}`);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  useEffect(() => {
    if (roleId) {
      dispatch(getRoleDetails(roleId));
    }
  }, [roleId]);

  useEffect(() => {
    if (roleData?.details) {
      setCheckNA({
        objectives: roleData?.details?.objectives === "N/A",
        key_results: roleData?.details?.key_results?.length === 0,
        procedures: roleData?.details?.procedures === "N/A",
        workflows: roleData?.details?.workflows?.length === 0,
        skills: roleData?.details?.skills?.length === 0,
        technologies: roleData?.details?.technologies?.length === 0,
        teams: roleData?.details?.teams?.length === 0,
        entities: roleData?.details?.entities === "N/A",
        meetings: roleData?.details?.meetings?.length === 0,
        coached_by:
          roleData?.details?.coached_by === "coached_by_not_available",
      });

      if (roleData?.details?.objectives !== "N/A") {
        setValue("objectives", roleData?.details?.objectives);
      }
      if (roleData?.details?.procedures !== "N/A") {
        setValue("procedures", roleData?.details?.procedures);
      }
      if (roleData?.details?.entities !== "N/A") {
        setValue("entities", roleData?.details?.entities);
      }
      if (roleData?.details?.key_results?.length > 0) {
        setValue("key_results", roleData?.details?.key_results);
      }
      if (roleData?.details?.workflows?.length > 0) {
        setValue("workflows", roleData?.details?.workflows);
      }
      if (roleData?.details?.skills?.length > 0) {
        setValue("skills", roleData?.details?.skills);
      }
      if (roleData?.details?.technologies?.length > 0) {
        setValue("technologies", roleData?.details?.technologies);
      }
      if (roleData?.details?.teams?.length > 0) {
        setValue("teams", roleData?.details?.teams);
      }
      if (roleData?.details?.meetings?.length > 0) {
        setValue("meetings", roleData?.details?.meetings);
      }
      if (roleData?.details?.coached_by !== "coached_by_not_available") {
        setValue("coached_by", roleData?.details?.coached_by);
      }

      setValue(
        "jobs",
        roleData?.details?.jobs?.map((e: { id: string }) => e?.id)
      );
      setValue("purpose_statement", roleData?.details?.purpose_statement);
      setValue("department", roleData?.details?.department);
    }
  }, [roleData?.details]);

  interface OnChangeEvent {
    target: {
      checked: boolean;
    };
  }

  const onChange = (e: OnChangeEvent, field: string): void => {
    setCheckNA({ ...checkNA, [field]: e.target.checked });
  };

  console.log("roleData?.details", roleData?.details);

  const setPurposeStatementText = () => {
    const payload = {
      name: roleData?.details?.name,
      type: "role",
    };
    generateAIDescription(payload).then((res) => {
      setValue("purpose_statement", res?.data?.description);
    });
  };

  return (
    <FullPageLayout loading={roleData?.loading || loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>Edit Role</h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "100%", maxWidth: "992px", margin: "0 auto" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "992px",
            paddingLeft: "68px",
            paddingRight: "68px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                <div className="mainTitle">
                  <h3> {roleData?.details?.name} </h3>
                </div>
              </Col>
              <Col span={24}>
                <SelectField
                  fieldName="jobs"
                  label="Jobs"
                  control={control}
                  selectOption={dropdownData?.jobs}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select one or multiple jobs",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.objectives}
                    onChange={(e) => onChange(e, "objectives")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="objectives"
                  control={control}
                  initValue=""
                  label={<>Objectives</>}
                  iProps={{
                    disabled: checkNA.objectives,
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired={!checkNA.objectives ? true : false}
                  rules={{
                    required: !checkNA.objectives
                      ? "Objectives is required."
                      : false,
                  }}
                  validate={!checkNA.objectives && errors.objectives && "error"}
                  validMessage={
                    !checkNA.objectives &&
                    errors.objectives &&
                    errors.objectives.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.key_results}
                    onChange={(e) => onChange(e, "key_results")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="key_results"
                  label={<>Key Results</>}
                  control={control}
                  selectOption={arrayDropdown("KEY_RESULT")}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select key results",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.key_results,
                  }}
                  isRequired={!checkNA.key_results ? true : false}
                  rules={{
                    required: !checkNA.key_results
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={
                    !checkNA.key_results && errors.key_results && "error"
                  }
                  validMessage={
                    !checkNA.key_results &&
                    errors.key_results &&
                    errors.key_results.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.procedures}
                    onChange={(e) => onChange(e, "procedures")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="procedures"
                  control={control}
                  initValue=""
                  label={<>Procedures</>}
                  isRequired={!checkNA.procedures ? true : false}
                  iProps={{
                    disabled: checkNA.procedures,
                  }}
                  rules={{
                    required: !checkNA.procedures
                      ? "Procedures is required."
                      : false,
                  }}
                  validate={!checkNA.procedures && errors.procedures && "error"}
                  validMessage={
                    !checkNA.procedures &&
                    errors.procedures &&
                    errors.procedures.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.workflows}
                    onChange={(e) => onChange(e, "workflows")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="workflows"
                  label={<>Workflows</>}
                  control={control}
                  selectOption={arrayDropdown("WORKFLOWS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select workflows",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.workflows,
                  }}
                  isRequired={!checkNA.workflows ? true : false}
                  rules={{
                    required: !checkNA.workflows
                      ? "Workflows is required."
                      : false,
                  }}
                  validate={!checkNA.workflows && errors.workflows && "error"}
                  validMessage={
                    !checkNA.workflows &&
                    errors.workflows &&
                    errors.workflows.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.skills}
                    onChange={(e) => onChange(e, "skills")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="skills"
                  label={<>Skills</>}
                  control={control}
                  selectOption={arrayDropdown("SKILL")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select skills",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.skills,
                  }}
                  isRequired={!checkNA.skills ? true : false}
                  rules={{
                    required: !checkNA.skills ? "Skills is required." : false,
                  }}
                  validate={!checkNA.skills && errors.skills && "error"}
                  validMessage={
                    !checkNA.skills && errors.skills && errors.skills.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.technologies}
                    onChange={(e) => onChange(e, "technologies")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="technologies"
                  label={<>Technologies</>}
                  control={control}
                  selectOption={arrayDropdown("TECHNOLOGIES")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select Technologies",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.technologies,
                  }}
                  isRequired={!checkNA.technologies ? true : false}
                  rules={{
                    required: !checkNA.technologies
                      ? "Technologies is required."
                      : false,
                  }}
                  validate={
                    !checkNA.technologies && errors.technologies && "error"
                  }
                  validMessage={
                    !checkNA.technologies &&
                    errors.technologies &&
                    errors.technologies.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.teams}
                    onChange={(e) => onChange(e, "teams")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="teams"
                  label={<>Teams</>}
                  control={control}
                  selectOption={arrayDropdown("TEAMS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select teams",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.teams,
                  }}
                  isRequired={!checkNA.teams ? true : false}
                  rules={{
                    required: !checkNA.teams ? "Teams is required." : false,
                  }}
                  validate={!checkNA.teams && errors.teams && "error"}
                  validMessage={
                    !checkNA.teams && errors.teams && errors.teams.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.entities}
                    onChange={(e) => onChange(e, "entities")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="entities"
                  label={<>Entities</>}
                  control={control}
                  initValue=""
                  isRequired={!checkNA.entities ? true : false}
                  iProps={{
                    disabled: checkNA.entities,
                  }}
                  rules={{
                    required: !checkNA.entities
                      ? "Entities is required."
                      : false,
                  }}
                  validate={!checkNA.entities && errors.entities && "error"}
                  validMessage={
                    !checkNA.entities &&
                    errors.entities &&
                    errors.entities.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.meetings}
                    onChange={(e) => onChange(e, "meetings")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="meetings"
                  label={<>Meetings</>}
                  control={control}
                  selectOption={arrayDropdown("MEETINGS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select meetings",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.meetings,
                  }}
                  isRequired={!checkNA.meetings ? true : false}
                  rules={{
                    required: !checkNA.meetings
                      ? "Meetings is required."
                      : false,
                  }}
                  validate={!checkNA.meetings && errors.meetings && "error"}
                  validMessage={
                    !checkNA.meetings &&
                    errors.meetings &&
                    errors.meetings.message
                  }
                />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col flex="0 0 395px">
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.coached_by}
                    onChange={(e) => onChange(e, "coached_by")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="coached_by"
                  label={<>Select role</>}
                  control={control}
                  selectOption={dropdownData?.roles}
                  iProps={{
                    placeholder: "Select role",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.coached_by,
                  }}
                  isRequired={!checkNA.coached_by ? true : false}
                  rules={{
                    required: !checkNA.coached_by ? "Role is required." : false,
                  }}
                  validate={!checkNA.coached_by && errors.coached_by && "error"}
                  validMessage={
                    !checkNA.coached_by &&
                    errors.coached_by &&
                    errors.coached_by.message
                  }
                />
              </Col>
              <Col flex="0 0 395px">
                <SelectField
                  fieldName="department"
                  label={<>Deparment</>}
                  control={control}
                  selectOption={arrayDropdown("DEPARTMENT")}
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                  isRequired
                  rules={{
                    required: "Department is required.",
                  }}
                  validate={errors.department && "error"}
                  validMessage={errors.department && errors.department.message}
                />
              </Col>
              <Col span={24}>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontFamily: '"DM Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: " 20px",
                    color: "#161616",
                  }}
                >
                  <div>
                    Role purpose statement
                    <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Button
                        className="generate_btn"
                        onClick={setPurposeStatementText}
                      >
                        <Image
                          src="/images/MagicWand.svg"
                          alt="Generate with AI"
                          width={16}
                          height={16}
                          preview={false}
                        />
                        Generate with AI
                      </Button>
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="purpose_statement"
                  control={control}
                  initValue=""
                  label=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Role purpose statement is required.",
                  }}
                  validate={errors.purpose_statement && "error"}
                  validMessage={
                    errors.purpose_statement && errors.purpose_statement.message
                  }
                />
              </Col>
              <Col
                className="fp_footer"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="icon_dark_btn"
                  >
                    Update Role
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
