import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Checkbox,
  Image,
  Alert,
  Tooltip,
} from "antd";
import { useForm } from "react-hook-form";
import { DropdownIcon, HintIcon } from "../../../../../atoms/CustomIcons";
import TextEditor from "../../../../../molecules/FormItems/TextEditor";
import { useCallback, useEffect, useState } from "react";
import { getUserDetails } from "../../../../MyAccount/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import SelectField from "../../../../../molecules/FormItems/SelectField";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
} from "../../../ducks/actions";
import { generateAIDescription, updateRoleById } from "../../../ducks/services";
import { UpdateRole } from "../../../ducks/types";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../atoms/Toasts/ErrorMessage";
import { RoleType } from "../../../../..//organisms/MyJob/ducks/types";
import { useLocation } from "react-router-dom";
import { getJobDetails } from "../../../../MyJob/ducks/actions";
import NextIcon from "../../../../../atoms/CustomIcons/NextIcon";
import PrevIcon from "../../../../../atoms/CustomIcons/PrevIcon";

type PropsType = {
  data: RoleType;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  totalLength?: number;
  activeNumber?: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenWorksheet?: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultTabSelected?: React.Dispatch<React.SetStateAction<string>>;
};

export default function RoleForm(props: PropsType) {
  const {
    data,
    setActiveTab,
    totalLength,
    activeNumber,
    setLoading,
    setOpenWorksheet,
    setDefaultTabSelected,
  } = props;
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateRole>();
  const [checkNA, setCheckNA] = useState({
    objectives: false,
    key_results: false,
    procedures: false,
    workflows: false,
    skills: false,
    technologies: false,
    teams: false,
    entities: false,
    meetings: false,
    coached_by: false,
  });
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];

  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );

  const onSubmit = (val: UpdateRole) => {
    setLoading(true);
    const payload = {
      name: data?.name,
      purpose_statement: val?.purpose_statement,
      objectives: checkNA?.objectives ? "N/A" : val?.objectives,
      key_results: checkNA?.key_results ? [] : val?.key_results,
      procedures: checkNA?.procedures ? "N/A" : val?.procedures,
      workflows: checkNA?.workflows ? [] : val?.workflows,
      skills: checkNA?.skills ? [] : val?.skills,
      technologies: checkNA?.technologies ? [] : val?.technologies,
      teams: checkNA?.teams ? [] : val?.teams,
      entities: checkNA?.entities ? "N/A" : val?.entities,
      meetings: checkNA?.meetings ? [] : val?.meetings,
      company_id: companyId,
      coached_by: checkNA?.coached_by
        ? "coached_by_not_available"
        : val?.coached_by,
      department: val?.department,
    };

    updateRoleById(payload, data?.id)
      .then(() => {
        dispatch(getJobDetails(jobId));

        setTimeout(() => {
          setLoading(false);
          if (setActiveTab && activeNumber && totalLength !== activeNumber) {
            setActiveTab((activeNumber + 1).toString());
          } else if (setActiveTab) {
            setActiveTab("jobName");
          }
        }, 1500);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  useEffect(() => {
    if (data) {
      setValue("purpose_statement", data?.purpose_statement);

      setCheckNA({
        objectives: data?.objectives === "N/A",
        key_results: data?.key_results?.length === 0,
        procedures: data?.procedures === "N/A",
        workflows: data?.workflows?.length === 0,
        skills: data?.skills?.length === 0,
        technologies: data?.technologies?.length === 0,
        teams: data?.teams?.length === 0,
        entities: data?.entities === "N/A",
        meetings: data?.meetings?.length === 0,
        coached_by: data?.coached_by === "coached_by_not_available",
      });

      if (data?.objectives !== "N/A") {
        setValue("objectives", data?.objectives);
      }
      if (data?.procedures !== "N/A") {
        setValue("procedures", data?.procedures);
      }
      if (data?.entities !== "N/A") {
        setValue("entities", data?.entities);
      }
      if (data?.key_results?.length > 0) {
        setValue("key_results", data?.key_results);
      }
      if (data?.workflows?.length > 0) {
        setValue("workflows", data?.workflows);
      }
      if (data?.skills?.length > 0) {
        setValue("skills", data?.skills);
      }
      if (data?.technologies?.length > 0) {
        setValue("technologies", data?.technologies);
      }
      if (data?.teams?.length > 0) {
        setValue("teams", data?.teams);
      }
      if (data?.meetings?.length > 0) {
        setValue("meetings", data?.meetings);
      }
      if (data?.coached_by !== "coached_by_not_available") {
        setValue("coached_by", data?.coached_by);
      }

      setValue("department", data?.department);
    }
  }, [data]);

  // console.log("totalLength", totalLength, activeNumber);

  interface OnChangeEvent {
    target: {
      checked: boolean;
    };
  }

  const onChange = (e: OnChangeEvent, field: string): void => {
    setCheckNA({ ...checkNA, [field]: e.target.checked });
  };
  console.log("checkNA.objectives", checkNA);

  const setPurposeStatementText = () => {
    const payload = {
      name: data?.name,
      type: "role",
    };
    generateAIDescription(payload).then((res) => {
      setValue("purpose_statement", res?.data?.description);
    });
  };
  return (
    <div className="contentPanel">
      <Card
        className="fp_layout_content role_card"
        style={{
          height: "100%",
          maxWidth: "100%",
          margin: "0 auto",
          border: "unset",
        }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: 0,
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <div className="mainTitle">
                  <div
                    className="active_state"
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <h6 className={`tagNumber_${activeNumber}`}>
                      {activeNumber}
                    </h6>
                    Role: {data?.name}
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <Alert
                  style={{ marginBottom: "24px" }}
                  message={
                    <>
                      Take the results from Flower Power session and complete
                      the information below. To ensure accuracy, consult with
                      everyone who owns this role. Refer to our &nbsp;
                      <a
                        href=""
                        style={{
                          color: "#161616",
                          textDecoration: "underline",
                        }}
                        onClick={(e) => {
                          e?.preventDefault();
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("objectives");
                        }}
                      >
                        Worksheet guide
                      </a>
                      &nbsp; for additional help.
                    </>
                  }
                  type="info"
                  className="warning_msg alert_for_session"
                  showIcon
                  closable
                />
              </Col>

              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.objectives}
                    onChange={(e) => onChange(e, "objectives")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="objectives"
                  control={control}
                  initValue=""
                  label={
                    <>
                      Objectives
                      <Button
                        className="hint_btn"
                        onClick={(e) => {
                          e?.preventDefault();
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("objectives");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  iProps={{
                    disabled: checkNA.objectives,
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired={!checkNA.objectives ? true : false}
                  rules={{
                    required: !checkNA.objectives
                      ? "Objectives is required."
                      : false,
                  }}
                  validate={!checkNA.objectives && errors.objectives && "error"}
                  validMessage={
                    !checkNA.objectives &&
                    errors.objectives &&
                    errors.objectives.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.key_results}
                    onChange={(e) => onChange(e, "key_results")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="key_results"
                  label={
                    <>
                      Key Results
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("key_results");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={arrayDropdown("KEY_RESULT")}
                  className="fp_select"
                  mode="tags"
                  iProps={{
                    placeholder: "Select key results",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.key_results,
                  }}
                  isRequired={!checkNA.key_results ? true : false}
                  rules={{
                    required: !checkNA.key_results
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={
                    !checkNA.key_results && errors.key_results && "error"
                  }
                  validMessage={
                    !checkNA.key_results &&
                    errors.key_results &&
                    errors.key_results.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.procedures}
                    onChange={(e) => onChange(e, "procedures")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="procedures"
                  control={control}
                  initValue=""
                  label={
                    <>
                      Procedures
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("procedures_wis_policies");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  isRequired={!checkNA.procedures ? true : false}
                  iProps={{
                    disabled: checkNA.procedures,
                  }}
                  rules={{
                    required: !checkNA.procedures
                      ? "Procedures is required."
                      : false,
                  }}
                  validate={!checkNA.procedures && errors.procedures && "error"}
                  validMessage={
                    !checkNA.procedures &&
                    errors.procedures &&
                    errors.procedures.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.workflows}
                    onChange={(e) => onChange(e, "workflows")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="workflows"
                  label={
                    <>
                      Workflows
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("workflows");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={arrayDropdown("WORKFLOWS")}
                  mode="tags"
                  iProps={{
                    placeholder: "Select workflows",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.workflows,
                  }}
                  isRequired={!checkNA.workflows ? true : false}
                  rules={{
                    required: !checkNA.workflows
                      ? "Workflows is required."
                      : false,
                  }}
                  validate={!checkNA.workflows && errors.workflows && "error"}
                  validMessage={
                    !checkNA.workflows &&
                    errors.workflows &&
                    errors.workflows.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.skills}
                    onChange={(e) => onChange(e, "skills")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="skills"
                  label={
                    <>
                      Skills
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("skills");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={arrayDropdown("SKILL")}
                  mode="tags"
                  iProps={{
                    placeholder: "Select skills",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.skills,
                  }}
                  isRequired={!checkNA.skills ? true : false}
                  rules={{
                    required: !checkNA.skills ? "Skills is required." : false,
                  }}
                  validate={!checkNA.skills && errors.skills && "error"}
                  validMessage={
                    !checkNA.skills && errors.skills && errors.skills.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.technologies}
                    onChange={(e) => onChange(e, "technologies")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="technologies"
                  label={
                    <>
                      Technologies
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("technologies");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={arrayDropdown("TECHNOLOGIES")}
                  mode="tags"
                  iProps={{
                    placeholder: "Select Technologies",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.technologies,
                  }}
                  isRequired={!checkNA.technologies ? true : false}
                  rules={{
                    required: !checkNA.technologies
                      ? "Technologies is required."
                      : false,
                  }}
                  validate={
                    !checkNA.technologies && errors.technologies && "error"
                  }
                  validMessage={
                    !checkNA.technologies &&
                    errors.technologies &&
                    errors.technologies.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.teams}
                    onChange={(e) => onChange(e, "teams")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="teams"
                  label={
                    <>
                      Teams
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("teams");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={arrayDropdown("TEAMS")}
                  mode="tags"
                  iProps={{
                    placeholder: "Select teams",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.teams,
                  }}
                  isRequired={!checkNA.teams ? true : false}
                  rules={{
                    required: !checkNA.teams ? "Teams is required." : false,
                  }}
                  validate={!checkNA.teams && errors.teams && "error"}
                  validMessage={
                    !checkNA.teams && errors.teams && errors.teams.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.entities}
                    onChange={(e) => onChange(e, "entities")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="entities"
                  label={
                    <>
                      Entities
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("entities");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  initValue=""
                  isRequired={!checkNA.entities ? true : false}
                  iProps={{
                    disabled: checkNA.entities,
                  }}
                  rules={{
                    required: !checkNA.entities
                      ? "Entities is required."
                      : false,
                  }}
                  validate={!checkNA.entities && errors.entities && "error"}
                  validMessage={
                    !checkNA.entities &&
                    errors.entities &&
                    errors.entities.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.meetings}
                    onChange={(e) => onChange(e, "meetings")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="meetings"
                  label={
                    <>
                      Meetings
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("meetings");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={arrayDropdown("MEETINGS")}
                  mode="tags"
                  iProps={{
                    placeholder: "Select meetings",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.meetings,
                  }}
                  isRequired={!checkNA.meetings ? true : false}
                  rules={{
                    required: !checkNA.meetings
                      ? "Meetings is required."
                      : false,
                  }}
                  validate={!checkNA.meetings && errors.meetings && "error"}
                  validMessage={
                    !checkNA.meetings &&
                    errors.meetings &&
                    errors.meetings.message
                  }
                />
              </Col>

              <Col span={12}>
                <h4 className="not_applicable_label">
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.coached_by}
                    onChange={(e) => onChange(e, "coached_by")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="coached_by"
                  label={
                    <>
                      Coached by
                      <Button
                        className="hint_btn"
                        onClick={() => {
                          setOpenWorksheet && setOpenWorksheet(true);
                          setDefaultTabSelected &&
                            setDefaultTabSelected("coached__by");
                        }}
                      >
                        <HintIcon />
                      </Button>
                    </>
                  }
                  control={control}
                  selectOption={dropdownData?.roles}
                  iProps={{
                    placeholder: "Select coach",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.coached_by,
                  }}
                  isRequired={!checkNA.coached_by ? true : false}
                  rules={{
                    required: !checkNA.coached_by
                      ? "Coach is required."
                      : false,
                  }}
                  validate={!checkNA.coached_by && errors.coached_by && "error"}
                  validMessage={
                    !checkNA.coached_by &&
                    errors.coached_by &&
                    errors.coached_by.message
                  }
                />
              </Col>
              <Col span={12}>
                <SelectField
                  fieldName="department"
                  label="Department"
                  control={control}
                  selectOption={arrayDropdown("DEPARTMENT")}
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                  isRequired
                  rules={{
                    required: "Department is required.",
                  }}
                  validate={errors.department && "error"}
                  validMessage={errors.department && errors.department.message}
                />
              </Col>
              <Col span={24}>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontFamily: '"DM Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: " 20px",
                    color: "#161616",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Role purpose statement
                    <Button
                      className="hint_btn"
                      onClick={(e) => {
                        e?.preventDefault();
                        setOpenWorksheet && setOpenWorksheet(true);
                        setDefaultTabSelected &&
                          setDefaultTabSelected("purpose_statement");
                      }}
                    >
                      <HintIcon />
                    </Button>
                    <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Button
                        className="generate_btn"
                        onClick={setPurposeStatementText}
                        style={{ height: "24px", paddingBottom: "0px" }}
                      >
                        <Image
                          src="/images/MagicWand.svg"
                          alt="Generate with AI"
                          width={16}
                          height={16}
                          preview={false}
                        />
                        Generate with AI
                      </Button>
                      <Tooltip title="To ensure precise and relevant results, complete the role details above before generating.">
                        <HintIcon />
                      </Tooltip>
                    </h4>
                  </div>
                </h4>

                <TextEditor
                  fieldName="purpose_statement"
                  control={control}
                  initValue=""
                  label=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Role purpose statement is required.",
                  }}
                  validate={errors.purpose_statement && "error"}
                  validMessage={
                    errors.purpose_statement && errors.purpose_statement.message
                  }
                />
              </Col>
              <Col
                className="fp_footer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {setActiveTab && (
                  <div className="button_wrapper">
                    <Button
                      htmlType="button"
                      type="primary"
                      className="icon_grey_btn"
                      disabled={activeNumber === 1}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });

                        if (totalLength !== activeNumber && activeNumber) {
                          setActiveTab((activeNumber - 1).toString());
                        } else {
                          setActiveTab("jobName");
                        }
                      }}
                    >
                      <PrevIcon /> Previous
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="icon_grey_btn"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Next <NextIcon />
                    </Button>
                  </div>
                )}
                <Button
                  htmlType="button"
                  type="primary"
                  className="icon_dark_btn"
                  disabled
                >
                  Finish
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </div>
  );
}
