import { useNavigate } from "react-router-dom";
import DashboardTemplate from "../../templates/Dashboard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useEffect } from "react";
import { getUserDetails } from "../MyAccount/ducks/actions";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountData = useAppSelector((state) => state?.account?.accountData);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (
      accountData &&
      Object.keys(accountData)?.length > 0 &&
      accountData?.type === "SUPER_ADMIN"
    ) {
      navigate("/companies");
    } else if (
      Object.keys(accountData)?.length > 0 &&
      accountData?.type === "COMPANY_ADMIN"
    ) {
      navigate(`/company-detail/jobs/${accountData?.company_id}`);
    } else if (
      Object.keys(accountData)?.length > 0 &&
      accountData?.type === "INDIVIDUAL_CONTRIBUTOR"
    ) {
      navigate(`/company-detail/jobs/${accountData?.company_id}`);
    }
  }, [accountData]);

  return <DashboardTemplate loading={false} />;
}
