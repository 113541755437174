import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf20 = () => (
  <svg
    width="62"
    height="291"
    viewBox="0 0 62 291"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.77215 68.051L31 290L60.2621 67.7911C62.057 54.1606 60.309 40.2976 55.1866 27.5393L49.6785 13.8203C42.9832 -2.85547 19.4019 -2.9277 12.6046 13.7068L7.03166 27.3449C1.76833 40.2253 -0.0445019 54.2558 1.77215 68.051Z"
      fill="currentColor"
      stroke="#ECEDF3"
      strokeWidth="1.62816"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf20} {...props} />
);

export default LeafIcon;
