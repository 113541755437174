import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Badge,
  Dropdown,
  Breadcrumb,
  Tooltip,
  TourProps,
  Tour,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import {
  ArrowIconRight,
  DropdownIcon,
  SortIcon,
} from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import { Popup } from "../../../atoms/Popup";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getJobFilters, getJobsListing } from "../ducks/actions";
import {
  getInitials,
  STATUS_TYPE,
  STATUS_TYPE_DROPDOWN,
} from "../../../config/constants";
import { deleteJob } from "../ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import Header from "../Components/Header";
import CompanyLinks from "../Components/CompanyLinks";
import store from "../../../config/store";
import * as action_types from "../ducks/constants";
import { getUserDetails } from "../../MyAccount/ducks/actions";
import AddRole from "../../../atoms/AddRole";

interface DataType {
  id: string;
  name: string;
  department_name: string;
  status: string;
  roles: { id: string; name: string }[];
  employees: string[];
}

export default function CompanyDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addRolePopUp, setAddRolePopUp] = useState(false);
  const [existingRolesId, setExistingRolesId] = useState<Array<string>>([]);
  const [roleFilter, setRoleFilter] = useState<undefined | string>(undefined);
  const [holderFilter, setHolderFilter] = useState<undefined | string>(
    undefined
  );
  const [departmentFilter, setDepartmentFilter] = useState<undefined | string>(
    undefined
  );
  const [statusFilter, setStatusFilter] = useState<undefined | string>(
    undefined
  );
  const [statusDropdown, setStatusDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const url = useLocation();
  const companyId = url?.pathname.split("/")[3];
  const urlType = url?.pathname.split("/")[2];
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );
  const jobsListData = useAppSelector(
    (state) => state?.companyDetails?.jobsList
  );
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const updateApi = () => {
    if (roleFilter || holderFilter || departmentFilter || statusFilter) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
        status: statusFilter ? [statusFilter] : undefined,
        roles: roleFilter ? [roleFilter] : undefined,
        departments: departmentFilter ? [departmentFilter] : undefined,
        employees: holderFilter ? [holderFilter] : undefined,
      };
      dispatch(getJobsListing(payload));
    } else if (companyId) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
      };
      dispatch(getJobsListing(payload));
    }
  };

  const addRolePopup = {
    visibility: addRolePopUp,
    class: "delete_confirmation_modal",
    content: (
      <AddRole
        title="Add roles to the job"
        heading={`Select roles you want to add to ${tableRecord?.name}.`}
        onClose={() => setAddRolePopUp(false)}
        btnText="Add role"
        existingRoles={existingRolesId}
        jobId={tableRecord?.id}
        companyId={companyId}
        updateApi={updateApi}
      />
    ),
    width: 520,
    onCancel: () => setAddRolePopUp(false),
  };

  useEffect(() => {
    if (tableRecord?.roles) {
      const existingRoles: string[] = [];
      tableRecord?.roles?.map((e: { id: string }) => {
        existingRoles.push(e?.id);
        return existingRoles;
      });
      setExistingRolesId(existingRoles);
    }
  }, [tableRecord?.roles]);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/job-detail/${tableRecord?.id}/${companyId}`);
          }}
        >
          Edit {companyData?.label_position === "Job" ? "job" : "seat"}
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setAddRolePopUp(true);
          }}
        >
          Add existing role
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          View sessions details
        </Button>
      ),
    },
    {
      key: "7",
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          className="noBorder"
        >
          Delete {companyData?.label_position === "Job" ? "job" : "seat"}
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: `${companyData?.label_position} name`,
      dataIndex: "name",
      width: 221,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      width: 477,
      sortIcon: () => <SortIcon />,
      render: (text: any[]) => (
        <div className="location_table_wrapper">
          {text && text?.length > 0 ? (
            <>
              {text?.length > 3 ? (
                <>
                  <div className="list_view">
                    {text?.map(
                      (e: any, index: number) =>
                        index < 3 && (
                          <Tag
                            className="defaultTag white_tag"
                            style={{ marginRight: "2px", marginLeft: "2px" }}
                            key={e?.id}
                          >
                            {e?.name}
                          </Tag>
                        )
                    )}
                  </div>
                  <div className="tooltip_location">
                    <Tooltip
                      title={
                        <>
                          {text?.map(
                            (e: any, index: number) =>
                              index > 2 && (
                                <div key={e?.id} style={{ marginTop: 5 }}>
                                  {e?.name}
                                  <br />
                                </div>
                              )
                          )}
                        </>
                      }
                    >
                      + {text?.length - 3}
                    </Tooltip>
                  </div>
                </>
              ) : (
                text?.map((e: { name: string; id: string }) => (
                  <Tag
                    className="defaultTag white_tag"
                    style={{ marginRight: "2px", marginLeft: "2px" }}
                    key={e?.id}
                  >
                    {e?.name}
                  </Tag>
                ))
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: `${companyData?.label_position} holders`,
      dataIndex: "employees",
      width: 221,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Avatar.Group
          className="avatarGroup"
          size="small"
          max={{
            count: 2,
            style: {
              color: "#161616",
              backgroundColor: "#fff",
              cursor: "pointer",
              fontSize: "10px",
              fontWeight: "500",
            },
          }}
        >
          {text?.length > 0
            ? text?.map(
                (e: {
                  first_name: string;
                  last_name: string;
                  id: string;
                  image: { url: string };
                }) => (
                  <Fragment key={e?.id}>
                    {e?.image ? (
                      <Avatar src={e?.image?.url} />
                    ) : (
                      <Avatar>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    )}
                  </Fragment>
                )
              )
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      width: 221,
      sorter: (a, b) => a?.department_name?.localeCompare(b?.department_name),
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 200,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text: keyof typeof STATUS_TYPE) => {
        let className = "";

        if (text === "pending_approval") {
          className = "light_blue_tag";
        } else if (text === "approved") {
          className = "green_tag";
        } else if (text === "suspended_fp_session") {
          className = "yellow_tag";
        } else if (text === "pending_fp_session") {
          className = "blue_tag";
        } else if (text === "pending_ws_session") {
          className = "blue_tag";
        } else if (text === "suspended_ws_session") {
          className = "yellow_tag";
        } else {
          className = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge`} /> {STATUS_TYPE[text]}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
          overlayClassName="full_width_btn"
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTableRecord(record);
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const individualColumns: TableColumnsType<DataType> = [
    {
      title: "Job Name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      sortIcon: () => <SortIcon />,
      render: (text: any[]) => (
        <div className="location_table_wrapper">
          {text && text?.length > 0 ? (
            <>
              {text?.length > 3 ? (
                <>
                  <div className="list_view">
                    {text?.map(
                      (e: any, index: number) =>
                        index < 3 && (
                          <Tag
                            className="defaultTag white_tag"
                            style={{ marginRight: "2px", marginLeft: "2px" }}
                            key={e?.id}
                          >
                            {e?.name}
                          </Tag>
                        )
                    )}
                  </div>
                  <div className="tooltip_location">
                    <Tooltip
                      title={
                        <>
                          {text?.map(
                            (e: any, index: number) =>
                              index > 2 && (
                                <div key={e?.id} style={{ marginTop: 5 }}>
                                  {e?.name}
                                  <br />
                                </div>
                              )
                          )}
                        </>
                      }
                    >
                      + {text?.length - 3}
                    </Tooltip>
                  </div>
                </>
              ) : (
                text?.map((e: { name: string; id: string }) => (
                  <Tag
                    className="defaultTag white_tag"
                    style={{ marginRight: "2px", marginLeft: "2px" }}
                    key={e?.id}
                  >
                    {e?.name}
                  </Tag>
                ))
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Holders",
      dataIndex: "employees",
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Avatar.Group
          className="avatarGroup"
          size="small"
          max={{
            count: 2,
            style: {
              color: "#161616",
              backgroundColor: "#fff",
              cursor: "pointer",
              fontSize: "10px",
              fontWeight: "500",
            },
          }}
        >
          {text?.length > 0
            ? text?.map(
                (e: {
                  first_name: string;
                  last_name: string;
                  id: string;
                  image: { url: string };
                }) => (
                  <Fragment key={e?.id}>
                    {e?.image ? (
                      <Avatar src={e?.image?.url} />
                    ) : (
                      <Avatar>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    )}
                  </Fragment>
                )
              )
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      sorter: (a, b) => a?.department_name?.localeCompare(b?.department_name),
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text: keyof typeof STATUS_TYPE) => {
        let className = "";
        if (text === "pending_approval") {
          className = "light_blue_tag";
        } else if (text === "approved") {
          className = "green_tag";
        } else if (text === "suspended_fp_session") {
          className = "yellow_tag";
        } else if (text === "pending_fp_session") {
          className = "blue_tag";
        } else if (text === "pending_ws_session") {
          className = "blue_tag";
        } else if (text === "suspended_ws_session") {
          className = "yellow_tag";
        } else {
          className = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge`} /> {STATUS_TYPE[text]}
          </Tag>
        ) : (
          "-"
        );
      },
    },
  ];

  // const updateApi = () => {
  //   const payload = {
  //     offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
  //     limit: limit,
  //     companyId: companyId,
  //     status: undefined,
  //     roles: undefined,
  //     departments: undefined,
  //     employees: undefined,
  //   };
  //   dispatch(getJobsListing(payload));

  //   setJobCreate(false);
  // };

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    deleteJob(tableRecord?.id as string)
      .then(() => {
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getJobsListing(payload));
        if (accountData?.type === "COMPANY_ADMIN") {
          dispatch(getUserDetails());
        }

        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading={`Are you sure you want to delete this ${
          companyData?.label_position === "Job" ? "job" : "seat"
        }?`}
        onClose={() => setIsDeleteModalOpen(false)}
        btnText={`Delete ${
          companyData?.label_position === "Job" ? "job" : "seat"
        }`}
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  // useEffect(() => {
  //   if (companyId) {
  //     const payload = {
  //       offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
  //       limit: limit,
  //       companyId: companyId,
  //     };
  //     dispatch(getJobsListing(payload));
  //   }
  // }, [pagination, limit, dispatch, companyId]);

  useEffect(() => {
    if (roleFilter || holderFilter || departmentFilter || statusFilter) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
        status: statusFilter ? [statusFilter] : undefined,
        roles: roleFilter ? [roleFilter] : undefined,
        departments: departmentFilter ? [departmentFilter] : undefined,
        employees: holderFilter ? [holderFilter] : undefined,
      };
      dispatch(getJobsListing(payload));
    } else if (companyId) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
      };
      dispatch(getJobsListing(payload));
    }
  }, [
    pagination,
    limit,
    dispatch,
    companyId,
    roleFilter,
    holderFilter,
    departmentFilter,
    statusFilter,
  ]);

  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  useEffect(() => {
    if (jobsFilterOptions?.status) {
      const statusArray: { label: string; value: string }[] = [];
      jobsFilterOptions?.status?.map(
        (e: { label: keyof typeof STATUS_TYPE_DROPDOWN; value: string }) => {
          statusArray.push({
            label: STATUS_TYPE_DROPDOWN[e?.label],
            value: e?.value,
          });
        }
      );
      setStatusDropdown(statusArray);
    }
  }, [jobsFilterOptions?.status]);

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        navigate(`/job-detail/${record?.id}/${companyId}`);
      },
    };
  };

  const filtersSelected = (type: string, value: string) => {
    console.log("type", type, value);
    if (type === "holder") {
      setHolderFilter(value);
    }
    if (type === "roles") {
      setRoleFilter(value);
    }
    if (type === "department") {
      setDepartmentFilter(value);
    }
    if (type === "status") {
      setStatusFilter(value);
    }
  };

  const resetFilters = () => {
    setRoleFilter(undefined);
    setHolderFilter(undefined);
    setDepartmentFilter(undefined);
    setStatusFilter(undefined);
  };

  useEffect(() => {
    store.dispatch({
      type: action_types.JOBS_LIST,
      data: { data: {}, loading: true },
    });
  }, [dispatch, companyId]);

  const buttonRef = useRef(null);
  const [tableTour, setTableTour] = useState<boolean>(false);
  const tableStep: TourProps["steps"] = [
    {
      title: "New employees",
      description: (
        <>
          Now you are ready for the session. <br /> Click on the job to open it
          and start the session.
        </>
      ),
      target: () => buttonRef.current,
    },
  ];

  const stepCreateTable = () => {
    localStorage.setItem("tourTable", "done");
    setTableTour(false);
  };

  useEffect(() => {
    const isTourEmployee = localStorage.getItem("tourTable");
    console.log("isTourEmployee", isTourEmployee);

    if (
      !isTourEmployee &&
      isTourEmployee !== "done" &&
      accountData?.type === "COMPANY_ADMIN" &&
      jobsListData?.data?.length > 0
    ) {
      setTimeout(() => {
        setTableTour(true);
      }, 500);
    }
  }, [
    localStorage?.getItem("tourTable"),
    accountData?.type,
    jobsListData?.data,
  ]);

  return (
    <DashboardTemplate loading={jobsListData?.loading}>
      {accountData?.type === "SUPER_ADMIN" && (
        <Row className="secondary_header">
          <Col span={24}>
            <Breadcrumb
              separator={<ArrowIconRight />}
              // style={{ cursor: "pointer" }}
            >
              <Breadcrumb.Item onClick={() => navigate("/companies")}>
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                Companies
              </Breadcrumb.Item>
              <Breadcrumb.Item>{companyData?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header companyId={companyId} jobData={jobsListData?.data} />
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <CompanyLinks companyId={companyId} urlType={urlType} />
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTitle"> Filter: </span>
                    <Select
                      placeholder="Roles"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.role}
                      onChange={(e) => filtersSelected("roles", e)}
                      allowClear
                      value={roleFilter}
                    />
                    <Select
                      placeholder="Holders"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.employee}
                      onChange={(e) => filtersSelected("holder", e)}
                      allowClear
                      value={holderFilter}
                    />
                    <Select
                      placeholder="Departments"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.department}
                      onChange={(e) => filtersSelected("department", e)}
                      allowClear
                      value={departmentFilter}
                    />
                    <Select
                      placeholder="Status"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={statusDropdown}
                      onChange={(e) => filtersSelected("status", e)}
                      allowClear
                      value={statusFilter}
                    />
                    <Button type="text" onClick={resetFilters}>
                      <Image src="/images/filter_btn.svg" preview={false} />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} ref={buttonRef}>
          {jobsListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={
                Object.keys(accountData)?.length > 0 &&
                accountData?.type === "INDIVIDUAL_CONTRIBUTOR"
                  ? individualColumns
                  : columns
              }
              dataSource={jobsListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: jobsListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
              onRow={onClickRow}
            />
          )}

          {jobsListData?.data?.length === 0 && (
            <EmptyCard
              image="/images/empty/no_jobs.svg"
              title={`There are no ${
                companyData?.label_position === "Job" ? "jobs" : "seats"
              }`}
              description=""
              buttonText={`Create ${companyData?.label_position?.toLowerCase()}`}
              navigateTo={`/create-job/${companyId}`}
            />
          )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
      <Popup {...addRolePopup} />
      <Tour
        open={tableTour}
        onClose={() => stepCreateTable()}
        steps={tableStep}
        placement="bottom"
      />
    </DashboardTemplate>
  );
}
