import { Button, Card } from "antd";
import FullPageLayout from "../../../templates/FullPageLayout";
import { CloseIcon } from "../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { Fragment, useEffect } from "react";
import { getRoleDescriptorForSessionByUserId } from "../../MyJob/ducks/actions";

export default function SorryScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[3];
  const roleData = useAppSelector((state) => state?.jobDetail?.roleData);

  useEffect(() => {
    if (sessionId) {
      dispatch(getRoleDescriptorForSessionByUserId(sessionId));
    }
  }, [dispatch, sessionId]);

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
          <h3> Flower Power session </h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "auto", minHeight: "calc(100vh - 128px)" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "612px",
            padding: "0px",
            border: "unset",
            marginTop: "55px",
          }}
        >
          <div className="submission_unsuccessful">
            <div className="inner_area_1">
              <img src="/images/Submission_Unsuccessful.svg" alt="" />
              <h2> Sorry, you can't submit the list anymore </h2>
              <p style={{ marginBottom: "18px" }}>
                It looks like the session facilitator has proceeded to the
                another step.
              </p>
              <p style={{ marginBottom: "12px" }}>
                You can voice out or send your list by other means so that the
                facilitator can add items manually.
              </p>
            </div>

            <div className="inner_area_2">
              <h4> Generated list </h4>
              <ul>
                {roleData?.length > 0 &&
                  roleData?.map((e: any, i: number) => (
                    <Fragment key={i}>
                      <li>{e?.descriptor}</li>
                    </Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Card>
    </FullPageLayout>
  );
}
