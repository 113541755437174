import { Button, Image, Spin, Form } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import InputField from "../../molecules/FormItems/InputField";

const antIcon = <LoadingOutlined spin />;

type FormValues = {
  company_name: string;
};

export default (props: any) => {
  const {
    onClose,
    title,
    heading,
    paragraph_1,
    paragraph_2,
    btnText,
    companyName,
    loading,
    btnApi,
  } = props;

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch, // Watch function to track input values
  } = useForm<FormValues>();

  const companyNameValue = watch("company_name"); // Watch the company_name field

  const onSubmit: SubmitHandler<FormValues> = (val: any) => {
    if (val?.company_name?.toLowerCase() !== companyName?.toLowerCase()) {
      setError("company_name", {
        message: "Please type correct company name",
      });
      return;
    }
    btnApi();
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form onFinish={handleSubmit(onSubmit)} className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={onCloseHandler}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>

        <div className="modal_content">
          <h4>{heading}</h4>
          {paragraph_1 && <p style={{ marginBottom: "20px" }}>{paragraph_1}</p>}
          {paragraph_2 && <p>{paragraph_2}</p>}

          <div className="delete_input">
            <InputField
              fieldName="company_name"
              label=""
              control={control}
              initValue=""
              iProps={{
                placeholder: "Company name",
              }}
              isRequired
              rules={{
                required: "Company name is required.",
              }}
              validate={errors.company_name && "error"}
              validMessage={errors.company_name && errors.company_name.message}
            />
          </div>
        </div>

        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn defaultBtnColor"
            type="default"
            size="large"
            onClick={onCloseHandler}
          >
            Cancel
          </Button>

          <Button
            htmlType="submit"
            className="actionBtn icon_dark_btn"
            type="primary"
            size="large"
            loading={loading}
            disabled={companyNameValue?.length === 0} // Disable if no value in company_name
          >
            {btnText}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};
