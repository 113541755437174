import { useEffect, useState } from "react";
import { Card, Button, Avatar, Image, Select } from "antd";
import { Participants } from "../../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../../config/constants";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  getParticipantsForSession,
  getSessionById,
} from "../../../../../organisms/MyJob/ducks/actions";
import { SearchIcon } from "../../../../../atoms/CustomIcons";
import { updateJobById } from "../../../ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../atoms/Toasts/ErrorMessage";
import WorksheetGuide from "../../../../../atoms/WorksheetGuide";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sessionId: string;

  jobId: string;
  companyId: string;
};

export default function StepSelectParticipants(props: PropsType) {
  const { setCurrent, setLoading, sessionId, jobId, companyId } = props;
  const dispatch = useAppDispatch();
  const [openWorksheet, setOpenWorksheet] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const sessionData = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.data
  );

  const participantsData = useAppSelector(
    (state) => state?.jobDetail?.participantsData
  );

  useEffect(() => {
    if (sessionData?.company_id && sessionData?.job_id) {
      const payload = {
        job_id: sessionData?.job_id,
        company_id: sessionData?.company_id,
      };

      dispatch(getParticipantsForSession(payload));
    }
  }, [dispatch, sessionData?.company_id, sessionData?.job_id]);

  const filteredOptions = participantsData?.filter(
    (o: any) => !selectedItems.includes(o?.id)
  );

  const completeData = participantsData?.filter((o: any) =>
    selectedItems.includes(o?.id)
  );

  const setSelectedParticipants = (value: string) => {
    const dummyArray = [];
    dummyArray.push(value);
    setSelectedItems([...selectedItems, ...dummyArray]);
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (sessionData?.participants?.length > 0) {
      const dummyArray: string[] = [];
      sessionData?.participants?.map(
        (e: any) => e?.id && dummyArray.push(e?.id)
      );

      setSelectedItems([...dummyArray]);
    }
  }, [sessionData]);

  const proceedToWorksheet = () => {
    setLoading(true);
    const payload = {
      status: "suspended_ws_session",
      company_id: companyId,
    };
    updateJobById(payload, jobId)
      .then(() => {
        setLoading(false);
        setCurrent(2);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  return (
    <>
      <Card className="fp_layout_content session_card">
        <div className="cardContent">
          <div
            className="select_individual_head select_participants"
            style={{ flexDirection: "column" }}
          >
            <div className="individual_left ">
              <h4>Select participants</h4>
              <p>
                Here is everyone who participated in the Flower Power session.
                It's recommended to perform this session with the same people.
              </p>
            </div>
            <Select
              className="participant_select"
              placeholder="Search employees"
              value={null}
              disabled
              onChange={setSelectedParticipants}
              style={{ width: "100%" }}
              suffixIcon={<SearchIcon />}
              options={filteredOptions.map((e: any) => ({
                label: (
                  <>
                    <div className="detail_option">
                      {e?.image?.url ? (
                        <div className="participant_avatar">
                          <Avatar src={e?.image?.url} />
                        </div>
                      ) : (
                        <div className="participant_avatar">
                          <Avatar>
                            {getInitials(e?.first_name, e?.last_name)}
                          </Avatar>
                        </div>
                      )}

                      <div className="user_name">
                        {e?.first_name ?? ""} {e?.last_name ?? ""}
                      </div>
                      <div className="user_position">
                        {e?.job?.name ?? (
                          <span style={{ opacity: 0.5 }}>No job title</span>
                        )}
                      </div>
                    </div>
                  </>
                ),
                value: e?.id,
              }))}
            />

            {completeData?.length > 0 && (
              <div className="participants_modal_wrapper">
                {completeData?.map((e: Participants) => (
                  <Card className="participants_card_modal" key={e?.id}>
                    {e?.image ? (
                      <div className="participant_avatar">
                        <Avatar src={e?.image?.url} />
                      </div>
                    ) : (
                      <div className="participant_avatar">
                        <Avatar>
                          {getInitials(e?.first_name, e?.last_name)}
                        </Avatar>
                      </div>
                    )}
                    <div className="participant_info">
                      <h6>{`${e?.first_name ?? ""} ${e?.last_name ?? ""}`}</h6>
                      <p>
                        {e?.job?.name ?? (
                          <span style={{ opacity: 0.5 }}>No job title</span>
                        )}
                      </p>
                    </div>
                    <div className="participant_check">
                      <Image
                        src="/images/close-icon.svg"
                        preview={false}
                        width={12}
                        height={12}
                      />
                    </div>
                  </Card>
                ))}
              </div>
            )}
          </div>

          <div className="steps_btn_wrapper">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              onClick={() => setCurrent(0)}
            >
              Back
            </Button>
            <Button
              htmlType="button"
              className="icon_dark_btn"
              type="primary"
              size="large"
              onClick={proceedToWorksheet}
            >
              Continue
            </Button>
          </div>
        </div>
      </Card>

      <WorksheetGuide
        openWorksheet={openWorksheet}
        setOpenWorksheet={setOpenWorksheet}
      />
    </>
  );
}
