import React from "react";
import { Form, Select, Button, Space, Divider, Image } from "antd";
import { Controller } from "react-hook-form";
import {
  InfoIconOutlined,
  MenuSelectedIcon,
  SelectArrowIcon,
} from "../../../atoms/CustomIcons";

type OptionType = {
  label?: string;
  value?: string | number;
  desc?: string | React.ReactElement;
};
export interface SelectFieldProps {
  fieldName: string;
  isRequired?: boolean;
  label?: string | React.ReactElement;
  validate?: any;
  validMessage?: any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  selectOption: Array<OptionType>;
  prefix?: any;
  hintMessage?: string;
  onSelectChange?: any;
  prefixIcon?: any;
  mode?: string;
  maxTagCount?: number;
  tooltipTitle?: string;
  optionalLabel?: string;
  setCreateEmployee: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectFieldWithButton: React.FunctionComponent<SelectFieldProps> = ({
  fieldName,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  iProps,
  selectOption,
  onSelectChange,
  tooltipTitle,
  mode,
  setCreateEmployee,
}: SelectFieldProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      label={label ?? null}
      validateStatus={validate}
      help={validMessage}
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoIconOutlined style={{ color: "#505560", fontSize: "14px" }} />
          ),
          placement: "topLeft",
          arrow: false,
        }
      }
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue ?? null}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={(e, k) => {
              onChange(e);
              onSelectChange && onSelectChange(k);
            }}
            suffixIcon={<SelectArrowIcon />}
            menuItemSelectedIcon={<MenuSelectedIcon />}
            {...iProps}
            mode={mode}
            value={value ?? null}
            showSearch
            placeholder="Select employees"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Button
                    type="text"
                    className="add_employee"
                    onClick={() => setCreateEmployee(true)}
                  >
                    <Image
                      src="/images/user-icon.svg"
                      preview={false}
                      width={16}
                    />{" "}
                    Add employee
                  </Button>
                </Space>
              </>
            )}
            options={selectOption?.map((item) => ({
              label: item?.label,
              value: item?.value,
            }))}
          />
        )}
      />
    </Form.Item>
  );
};

export default SelectFieldWithButton;
