import React, { useCallback, useEffect, useState } from "react";
import DashboardTemplate from "../../templates/Dashboard";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Image,
  TableColumnsType,
  Avatar,
} from "antd";
import { PlusIcon, SortIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import EmptyCard from "../../molecules/EmptyCard";
import { Popup } from "../../atoms/Popup";
import CompanyDelete from "../../atoms/CompanyDelete";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getCompanyListing } from "./ducks/actions";
import { deleteCompany } from "./ducks/services";
import SuccessMessage from "../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";
import { getCompanyInitials } from "../../config/constants";

interface DataType {
  key: React.Key;
  id: string;
  name: string;
  location: string;
  jobs: string;
  roles: string;
  employees: string;
  image: { url: string };
}

export default function Companies() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const companiesList = useAppSelector(
    (state) => state?.companies?.companiesList
  );

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <Button
    //       className="noBorder"
    //       style={{ padding: "0px", width: "100%" }}
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         navigate(`/company-detail/jobs/${tableRecord?.id}`);
    //       }}
    //     >
    //       Edit
    //     </Button>
    //   ),
    // },
    {
      key: "2",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/company-settings/${tableRecord?.id}`);
          }}
        >
          Company settings
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          Delete company
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Company Name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <div className="table_with_image">
          {record?.image?.url ? (
            <Image
              src={record?.image?.url}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar>{text ? getCompanyInitials(text) : ""}</Avatar>
          )}
          <div className="table_text">
            <span className="main_text">{text}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a?.location?.localeCompare(b?.location),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Jobs",
      dataIndex: "jobs",
      sorter: (a, b) => a?.jobs?.length - b?.jobs?.length,
      sortIcon: () => <SortIcon />,
      render: (text) => text?.length ?? "-",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      sorter: (a, b) => a?.roles?.length - b?.roles?.length,
      sortIcon: () => <SortIcon />,
      render: (text) => text?.length ?? "-",
    },
    {
      title: "Employees",
      dataIndex: "employees",
      sorter: (a, b) => a?.employees?.length - b?.employees?.length,
      sortIcon: () => <SortIcon />,
      render: (text) => text?.length ?? "-",
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTableRecord(record);
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleDeleteCompany = async () => {
    setDeleteLoading(true);
    deleteCompany(tableRecord?.id as string)
      .then(() => {
        SuccessMessage("Company deleted");
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
        };
        dispatch(getCompanyListing(payload));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <CompanyDelete
        title="Confirm deletion"
        heading="Delete company?"
        paragraph_1="This action will permanently delete all data for this company and cannot be undone."
        paragraph_2="Confirm this operation by typing the company name below."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete company"
        btnApi={handleDeleteCompany}
        loading={deleteLoading}
        companyName={tableRecord?.name}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    const payload = {
      offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
      limit: limit,
    };
    dispatch(getCompanyListing(payload));
  }, [pagination, limit, dispatch]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        navigate(`/company-detail/jobs/${record?.id}`);
      },
    };
  };

  return (
    <DashboardTemplate loading={companiesList?.loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="fp_main_details">
            <div className="companyInfo">
              <h1>Companies</h1>
            </div>
            <div className="actionBtn">
              <Button
                className="icon_dark_btn"
                icon={<PlusIcon />}
                onClick={() => navigate("/create-company")}
              >
                Create company
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24}>
          {companiesList?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={companiesList?.data}
              pagination={{
                defaultPageSize: limit,
                total: companiesList?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
              onRow={onClickRow}
            />
          )}

          {companiesList?.data?.length === 0 && (
            <EmptyCard
              image="/images/empty/no-results.svg"
              title="There are no company"
              description='Click the "Create company" button to add a new company.'
              buttonText="Create company"
              navigateTo="/create-company"
            />
          )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
