export default function PurposeStatement() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What is a purpose statement
      </h2>
      <p className="wg_caption">
        A purpose statement succinctly defines the primary reason for the Role's
        existence, focusing on its key responsibilities and the value it brings
        to the organization. It outlines the main goals and objectives the role
        aims to achieve. (AI Enabled in FlowerPower.software)
      </p>

      <div className="guide_sections">
        <div className="guide_card blueBorder">
          <h6> Template </h6>
          <p>
            The purpose of Role X is to drive ... (explain the why). Role X goes
            about doing this by ... (explain the how). Success can be measured
            weekly by tracking these numbers... (explain the what, the goal
            line).
          </p>
        </div>
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>1 to 3 sentences</li>
            <li>
              Ensure role purpose statements clearly and concisely cover a
              role's why, how, and what.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6> Examples </h6>
          <ul>
            <li>
              Social Media Strategies Role: To boost our online presence and
              leverage word of mouth to augment our marketing efforts with
              minimal expenditure. This is mostly a Thinking Role where I am
              tasked with thinking and strategy around how the organization does
              Social Media.
            </li>
            <li>
              Risk Management Role: To be accountable for identifying and
              evaluating risks organization-wide and then responsible for
              executing strategies and applying resources to minimize them while
              maximizing opportunities.
            </li>
            <li>
              Role X: Is primarily accountable for thinking about and forming
              strategies and processes that the organization can form around and
              follow to execute.
            </li>
            <li>
              Role Y: Is primarily responsible for executing the plans and
              following the processes that are determined in Role X.
            </li>
            <li>
              Sales Manager Job: Increase market share and drive revenue growth
              by developing and implementing effective sales strategies and
              fostering strong customer relationships.
            </li>
            <li>
              HR Manager Job: Cultivate a positive and productive work
              environment by implementing policies and programs that support
              employee development, engagement, and retention.
            </li>
            <li>
              Product Manager Role: Deliver innovative and high-quality products
              that meet market demands by leading cross-functional teams through
              the product development lifecycle.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
