import React, { useCallback, useEffect, useState } from "react";
import DashboardTemplate from "../../templates/Dashboard";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Image,
  TableColumnsType,
  Tag,
  Badge,
} from "antd";
import { PlusIcon, SortIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import EmptyCard from "../../molecules/EmptyCard";
import { Popup } from "../../atoms/Popup";
import ConfirmDelete from "../../atoms/ConfirmDelete";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUsersListing } from "./ducks/actions";
import { getInitials, USER_TYPE } from "../../config/constants";
import {
  deleteUserById,
  updateEmployeeById,
} from "../CompanyDetails/ducks/services";
import SuccessMessage from "../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";

interface DataType {
  key: React.Key;
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  type: string;
  status: string;
  image: { url: string };
}

export default function SystemSettings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeActiveModalOpen, setIsDeActiveModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const usersList = useAppSelector((state) => state?.system?.usersList);
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/edit-user/${tableRecord?.id}`);
          }}
        >
          Edit details
        </Button>
      ),
    },
    {
      key: "2",
      disabled: tableRecord?.id === accountData?.id,
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          disabled={tableRecord?.id === accountData?.id}
          onClick={(e) => {
            e.stopPropagation();
            tableRecord?.status === "active"
              ? setIsDeActiveModalOpen(true)
              : handleActiveUser();
          }}
        >
          {tableRecord?.status === "active" ? "Deactivate" : "Activate"}
        </Button>
      ),
    },
    {
      key: "3",
      disabled: tableRecord?.id === accountData?.id,
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          disabled={tableRecord?.id === accountData?.id}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "first_name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <div className="table_with_image">
          {record?.image?.url ? (
            <Image
              src={record?.image?.url}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar>{getInitials(text, record?.last_name)}</Avatar>
          )}
          <div className="table_text">
            <span className="main_text">{`${text} ${record?.last_name}`}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Access level",
      dataIndex: "type",
      render: (text: keyof typeof USER_TYPE) => (text ? USER_TYPE[text] : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        let className = "";
        if (text === "active") {
          className = "green_tag";
        } else if (text === "inactive") {
          className = "grey_tag";
        } else {
          className = "defaultTag";
        }
        return (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge green_badge`} />{" "}
            {text === "inactive" ? "Deactivated" : "Active"}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTableRecord(record);
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    deleteUserById(tableRecord?.id as string)
      .then(() => {
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          userType: "SUPER_ADMIN",
        };
        dispatch(getUsersListing(payload));
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const handleDeActiveUser = async () => {
    setDeleteLoading(true);

    const payload = {
      status: "inactive",
    };
    updateEmployeeById(payload, tableRecord?.id as string)
      .then(() => {
        SuccessMessage("User deactivated");
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          userType: "SUPER_ADMIN",
        };
        dispatch(getUsersListing(payload));
        setDeleteLoading(false);
        setIsDeActiveModalOpen(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const handleActiveUser = async () => {
    setLoading(true);

    const payload = {
      status: "active",
    };
    updateEmployeeById(payload, tableRecord?.id as string)
      .then(() => {
        setLoading(false);
        SuccessMessage("User activated");
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          userType: "SUPER_ADMIN",
        };
        dispatch(getUsersListing(payload));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading={`Delete ${tableRecord?.first_name ?? ""} ${
          tableRecord?.last_name ?? ""
        }?`}
        paragraph_1="This user will be deleted. This action can't be undone."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete user"
        btnApi={handleDeleteUser}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  const deActiveConfirmationPopup = {
    visibility: isDeActiveModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deactivation"
        heading={`Deactivate ${tableRecord?.first_name ?? ""} ${
          tableRecord?.last_name ?? ""
        } user?`}
        paragraph_1="The user will be archived and will lose access to the system."
        onClose={() => setIsDeActiveModalOpen(false)}
        btnText="Deactivate user"
        btnApi={handleDeActiveUser}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeActiveModalOpen(false),
  };

  useEffect(() => {
    const payload = {
      offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
      limit: limit,
      userType: "SUPER_ADMIN",
    };
    dispatch(getUsersListing(payload));
  }, [pagination, limit, dispatch]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        navigate(`/edit-user/${record?.id}`);
      },
    };
  };

  return (
    <DashboardTemplate loading={loading}>
      <Row gutter={[24, 24]}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="fp_main_details">
            <div className="companyInfo">
              <h1>Flower Power team </h1>
            </div>
          </div>
          <div className="actionBtn">
            <Button
              className="icon_dark_btn"
              icon={<PlusIcon />}
              onClick={() => navigate("/add-user")}
            >
              Create user
            </Button>
          </div>
        </Col>

        <Col span={24}>
          {usersList?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={usersList?.data}
              pagination={{
                defaultPageSize: limit,
                total: usersList?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
              onRow={onClickRow}
            />
          )}

          {usersList?.data?.length === 0 && (
            <EmptyCard
              image="/images/empty/no-results.svg"
              title="There are no user"
              description='Click the "Create user" button to add a new user.'
              buttonText="Create user"
              navigateTo="/add-user"
            />
          )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
      <Popup {...deActiveConfirmationPopup} />
    </DashboardTemplate>
  );
}
