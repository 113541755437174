import { Button, Card, Col, Row } from "antd";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";
import SessionFlower from "../SessionFlower";
import { useEffect, useState } from "react";
import {
  addRoleDescriptor,
  deleteRoleDescriptorById,
  updateIndividualPetalInfoByTeamId,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import {
  clearFlowerDataBySessionID,
  getFlowerDataBySessionID,
} from "../../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../../config/hooks";
import { Id, Role } from "../../../../../../../molecules/KanbanBoard/types";
import RoleCardWithoutSort from "../../../../../../../molecules/KanbanBoard/Components/RoleCardWithoutSort";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FpSessionRole(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionData,
    sessionId,
    setLoading,
  } = props;
  const [seriousName, setSeriousName] = useState("");
  const [funnyName, setFunnyName] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);
  const dispatch = useAppDispatch();

  const addNames = () => {
    const payload = {
      funny_name: funnyName,
      serious_name: seriousName,
      petal_id: activeData?.id,
    };

    updateIndividualPetalInfoByTeamId(petalTeams[0].id, payload).then(() => {
      updateApi();
      if (+activeTab === data?.sessionPetals?.length) {
        setEnableSubmit(true);
      }
    });
  };

  const submitSFNames = () => {
    setLoading(true);
    const payloadScreen = {
      last_session_screen: 8,
    };
    updateSessionById(payloadScreen, sessionId).then(() => {
      setLoading(false);
      dispatch(clearFlowerDataBySessionID());
      setCurrent(8);
    });
  };

  useEffect(() => {
    if (petalTeams?.length > 0) {
      petalTeams?.map((e: any) => {
        const seriousName = e?.contributions.find(
          (item1: any) => activeData?.id === item1.petal_id
        )?.serious_name;

        const funnyName = e?.contributions.find(
          (item1: any) => activeData?.id === item1.petal_id
        )?.funny_name;

        setSeriousName(seriousName);
        setFunnyName(funnyName);

        if (seriousName && funnyName) {
          setEnableSubmit(true);
        }
      });
    }
  }, [petalTeams]);

  const addMoreDescriptor = () => {
    const payload = {
      session_id: sessionId,
      descriptor: "descriptor",
      index: activeData?.descriptors?.length + 1,
      petal_id: activeData?.id,
    };

    addRoleDescriptor(payload).then(() => {
      updateApi();
    });
  };

  const deleteRole = (id: Id) => {
    deleteRoleDescriptorById(id).then(() => {
      updateApi();
    });
  };

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = activeData?.descriptors.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = activeData?.descriptors.find(
      (role: { id: string }) => role.id === id
    );
    const updatedRole = { ...findRoleById, descriptor };

    setRoleToUpdate(updatedRole);
    console.log(updatedLocal);
  };

  const updateApi = () => {
    dispatch(getFlowerDataBySessionID(sessionId));
  };

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            {data?.sessionPetals?.length > 0 && (
              <>
                <SessionFlower
                  data={data?.sessionPetals}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  activeData={activeData}
                  petalTeams={petalTeams}
                />
                <div className="flower_position">
                  <div className="flew_wrapper">
                    <h4>{sessionData?.job?.name}</h4>
                    <p>
                      {sessionData?.c_person?.first_name ?? ""}{" "}
                      {sessionData?.c_person?.last_name ?? ""}{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4>Come up with role names</h4>
          <p>Brainstorm and enter serious and funny names for each role.</p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              Role
            </div>
            {petalTeams?.map((e: any) => (
              <div className="inputFields">
                <div style={{ width: "100%" }}>
                  <label> Serious name: </label>
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSeriousName(e.target.value)
                    }
                    className="ant-input ant-input-outlined formControl"
                    placeholder="Type a serious name here"
                    defaultValue={
                      e?.contributions.find(
                        (item1: any) => activeData?.id === item1.petal_id
                      )?.serious_name
                    }
                    onBlur={() => addNames()}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <label> Funny name: </label>
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFunnyName(e.target.value)
                    }
                    className="ant-input ant-input-outlined formControl"
                    placeholder="Type a funny name here"
                    defaultValue={
                      e?.contributions.find(
                        (item1: any) => activeData?.id === item1.petal_id
                      )?.funny_name
                    }
                    onBlur={() => addNames()}
                  />
                </div>
              </div>
            ))}

            <div className="role_description">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <h4> Role descriptors: </h4>
                <Button onClick={addMoreDescriptor} type="text">
                  + Add More
                </Button>
              </div>
              {activeData?.descriptors?.map((role: Role) => (
                <RoleCardWithoutSort
                  key={role.id}
                  role={role}
                  deleteRole={deleteRole}
                  updateRole={updateRole}
                  roleToUpdate={roleToUpdate}
                  updateApi={updateApi}
                  petalsSession={data?.sessionPetals}
                  oldPetalId={activeData?.id}
                  createRole={addMoreDescriptor}
                />
              ))}
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={
                +activeTab === data?.sessionPetals?.length ||
                seriousName === "" ||
                funnyName === "" ||
                !seriousName ||
                !funnyName
              }
              onClick={() => setActiveTab((+activeTab + 1).toString())}
            >
              Next role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() =>
                  petalTeams?.length > 1 ? setCurrent(6) : setCurrent(5)
                }
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                disabled={
                  (+activeTab !== data?.sessionPetals?.length ||
                    !enableSubmit) &&
                  sessionData?.last_session_screen < 8
                }
                onClick={submitSFNames}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
