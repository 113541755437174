import { useEffect, useMemo, useState } from "react";
import { Card, Button, Tabs, TabsProps, Dropdown } from "antd";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, PencilIcon, PlusIcon } from "../../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
// import WorksheetGuide from "../../../../atoms/WorksheetGuide";
import { Popup } from "../../../../atoms/Popup";
import AddRole from "../../../../atoms/AddRole";
// import EditJobForm from "../../../../molecules/FormItems/EditJobForm";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobDetails } from "../../../../organisms/MyJob/ducks/actions";
import { RoleType } from "../../../..//organisms/MyJob/ducks/types";
import RoleForm from "../Components/RoleForm";
import EditJobForm from "../../../..//molecules/FormItems/EditJobForm";
import AddRoleForm from "../Components/AddRoleForm";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";
import WorksheetGuide from "../../../../atoms/WorksheetGuide";

export default function EditJob() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [jobTabsItems, setJobTabsItems] = useState<TabsProps["items"]>([]);
  const [existingRolesId, setExistingRolesId] = useState<Array<string>>([]);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const customArray: TabsProps["items"] = [];
  const [openWorksheet, setOpenWorksheet] = useState(false);
  const [addedNewRole, setAddedNewRole] = useState(false);
  const [defaultTabSelected, setDefaultTabSelected] = useState("");
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const jobItems: TabsProps["items"] = [
    {
      key: "jobTitle",
      label: (
        <span style={{ textAlign: "left", display: "block" }}>
          {companyData?.label_position ?? "Job"}{" "}
        </span>
      ),
      disabled: true,
    },
    {
      key: "jobName",
      label: (
        <div className="job_name">
          <PencilIcon className="jobName-icon" />{" "}
          {jobDetailData?.detailData?.name}
        </div>
      ),
      children: (
        <EditJobForm
          setLoading={setLoading}
          setDefaultTabSelected={setDefaultTabSelected}
          setOpenWorksheet={setOpenWorksheet}
          jobName={jobDetailData?.detailData?.name}
        />
      ),
    },
  ];

  const addRolePopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <AddRole
        title="Add roles to the job"
        heading={`Select roles you want to add to ${jobDetailData?.detailData?.name}.`}
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Add role"
        existingRoles={existingRolesId}
        jobId={jobId}
        companyId={companyId}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  useMemo(() => {
    if (jobDetailData?.detailData?.roles?.length > 0) {
      jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
        customArray.push({
          key: `${i + 1}`,
          label: (
            <div
              className="tab-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="tab-number">{i + 1}</span> {e?.name}
            </div>
          ),
          children: (
            <RoleForm
              data={e}
              setLoading={setLoading}
              setDefaultTabSelected={setDefaultTabSelected}
              setOpenWorksheet={setOpenWorksheet}
              activeNumber={i + 1}
            />
          ),
        })
      );

      const tabItems = [...customArray, ...jobItems];
      setJobTabsItems(tabItems);
    } else {
      // const newRoleArray: TabsProps["items"] = [
      //   {
      //     key: "newRole",
      //     label: (
      //       <div
      //         className="tab-label"
      //         style={{ display: "flex", alignItems: "center" }}
      //       >
      //         <span className="tab-number">1</span>{" "}
      //         {selectedRoleName ? selectedRoleName : "Role name"}
      //       </div>
      //     ),
      //     children: (
      //       <AddRoleForm
      //         companyId={companyId}
      //         jobId={jobId}
      //         setSelectedRoleName={setSelectedRoleName}
      //         setLoading={setLoading}
      //         setActiveTab={setActiveTab}
      //         setDefaultTabSelected={setDefaultTabSelected}
      //         setOpenWorksheet={setOpenWorksheet}
      //       />
      //     ),
      //   },
      // ];

      const tabItems = [...jobItems];
      setActiveTab("jobName");
      setJobTabsItems(tabItems);
    }

    const existingRoles: string[] = [];
    jobDetailData?.detailData?.roles?.map((e: { id: string }) => {
      existingRoles.push(e?.id);
      return existingRoles;
    });
    setExistingRolesId(existingRoles);
  }, [jobDetailData?.detailData?.roles, selectedRoleName, companyData]);

  const addNewRole = () => {
    jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
      customArray.push({
        key: `${i + 1}`,
        label: (
          <div
            className="tab-label"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="tab-number">{i + 1}</span> {e?.name}
          </div>
        ),
        children: (
          <RoleForm
            data={e}
            setLoading={setLoading}
            setDefaultTabSelected={setDefaultTabSelected}
            setOpenWorksheet={setOpenWorksheet}
            activeNumber={i + 1}
          />
        ),
      })
    );
    const addRoleArray: TabsProps["items"] = [
      {
        key: "newRole",
        label: (
          <div
            className="tab-label"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="tab-number">
              {jobDetailData?.detailData?.roles?.length > 0
                ? jobDetailData?.detailData?.roles?.length + 1
                : 1}
            </span>{" "}
            {selectedRoleName ? selectedRoleName : "Role name"}
          </div>
        ),
        children: (
          <AddRoleForm
            companyId={companyId}
            jobId={jobId}
            setSelectedRoleName={setSelectedRoleName}
            setLoading={setLoading}
            setActiveTab={setActiveTab}
            setDefaultTabSelected={setDefaultTabSelected}
            setOpenWorksheet={setOpenWorksheet}
          />
        ),
      },
    ];

    const tabItems = [...customArray, ...addRoleArray, ...jobItems];
    setActiveTab("newRole");
    setAddedNewRole(true);
    setJobTabsItems(tabItems);
  };

  const addRoleDropdown = [
    {
      key: "1",
      label: (
        <Button onClick={() => setIsDeleteModalOpen(true)}>
          Add existing role
        </Button>
      ),
    },
    {
      key: "2",
      label: <Button onClick={addNewRole}>Add new role</Button>,
    },
  ];

  useEffect(() => {
    if (jobDetailData?.detailData?.roles?.length > 0) {
      setActiveTab("jobName");
    } else {
      setActiveTab("newRole");
    }
  }, [jobDetailData?.detailData]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);
  return (
    <FullPageLayout loading={loading || jobDetailData?.loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>Edit {companyData?.label_position} </h3>
        </div>
      </Card>

      <div className="fp_layout_content" style={{ height: "auto" }}>
        <div className="editJob">
          <div className="controlPanel">
            <div className="title">
              <h6>
                {jobDetailData?.detailData?.roles?.length > 0 || addedNewRole
                  ? "Roles"
                  : ""}
              </h6>
              <Dropdown
                menu={{ items: addRoleDropdown }}
                trigger={["click"]}
                placement="bottomRight"
                className="actionButton"
                overlayClassName="full_width_btn"
              >
                <Button>
                  <span>
                    <PlusIcon className="PlusIcon" />
                    Add role
                  </span>
                </Button>
              </Dropdown>
            </div>
            <div
              className={
                jobDetailData?.detailData?.roles?.length > 0 || addedNewRole
                  ? "role_job_list"
                  : "role_job_list only_jobs"
              }
            >
              <Tabs
                tabPosition="left"
                items={jobTabsItems}
                activeKey={activeTab}
                onChange={onTabChange}
              />
            </div>
          </div>
        </div>
      </div>

      <Popup {...addRolePopup} />

      <WorksheetGuide
        openWorksheet={openWorksheet}
        setOpenWorksheet={setOpenWorksheet}
        defaultTabSelected={defaultTabSelected}
        setDefaultTabSelected={setDefaultTabSelected}
      />
    </FullPageLayout>
  );
}
