import { ReactElement, useEffect, useState } from "react";
import { Id, Role } from "../../types";
import { Button, Dropdown, Image, Input, Select } from "antd";
import {
  swapRoleDescriptor,
  updateRoleDescriptor,
} from "../../../../organisms/MyJob/ducks/services";
import { SelectArrowIcon } from "../../../../atoms/CustomIcons";
import { useAppDispatch } from "../../../../config/hooks";
import { getFlowerDataBySessionID } from "../../../../organisms/MyJob/ducks/actions";

const { TextArea } = Input;
interface PropsType {
  role: Role;
  deleteRole: (id: string | number) => void;
  updateRole?: (id: string | number, descriptor: string) => void;
  roleToUpdate?: any;
  updateApi?: () => void;
  petalsSession: [];
  oldPetalId: string;
  sessionId?: string;
  createRole?: any;
}

export default function RoleCard(props: PropsType) {
  const {
    role,
    deleteRole,
    updateRole,
    roleToUpdate,
    updateApi,
    petalsSession,
    oldPetalId,
    sessionId,
    createRole,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const [petalData, setPetalData] = useState<
    { key: string; label: ReactElement }[]
  >([]);

  const moveToPetal = (descriptorId: Id, newPetalId: string) => {
    const payload = {
      role_descriptor_id: descriptorId,
      current_petal_id: oldPetalId,
      new_petal_id: newPetalId,
    };
    swapRoleDescriptor(payload).then(() => {
      updateApi && updateApi();
    });
  };

  useEffect(() => {
    if (petalsSession?.length > 0) {
      const petalArray = petalsSession?.map(
        (petal: { id: string; finalized_name: string }, index: number) => {
          return {
            key: petal?.id,
            label: (
              <Button
                className="role_btn"
                onClick={(e) => {
                  e.stopPropagation();
                  moveToPetal(role.id, petal.id);
                }}
              >
                {petal?.finalized_name ? petal?.finalized_name : "Role"}{" "}
                <span className="role_desc_count">{index + 1}</span>
              </Button>
            ),
          };
        }
      );
      setPetalData(petalArray);
    }
  }, [petalsSession]);

  const items = [
    {
      key: "1",
      label: (
        <div>
          <img
            src="/images/move_to_role.svg"
            alt="icon"
            style={{ marginRight: "8px" }}
          />
          Move to role
        </div>
      ),
      children: petalData,
    },
    {
      key: "2",
      label: (
        <Button
          className="role_btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteRole(role.id);
          }}
        >
          <img src="/images/move_to_delete.svg" alt="delete" />
          Delete
        </Button>
      ),
    },
  ];

  const toggleEditMode = () => {
    if (editMode && roleToUpdate?.id) {
      const payload = {
        id: roleToUpdate?.id,
        descriptor: roleToUpdate?.descriptor?.trimStart().trimEnd(),
      };
      updateRoleDescriptor(payload).then(() => {
        setEditMode(false);
        updateApi && updateApi();
        createRole && createRole();
      });
    }
  };

  const selectDescriptor = (value: string, text: string, id: Id) => {
    if (sessionId) {
      const payload = {
        id: id,
        descriptor: text,
        category: value,
      };
      updateRoleDescriptor(payload).then(() => {
        dispatch(getFlowerDataBySessionID(sessionId));
      });
    }
  };

  const saveOnBlur = () => {
    if (roleToUpdate?.id) {
      const payload = {
        id: roleToUpdate?.id,
        descriptor: roleToUpdate?.descriptor?.trimStart().trimEnd(),
      };
      updateRoleDescriptor(payload).then(() => {
        setEditMode(false);
      });
    } else {
      setEditMode(false);
    }
  };

  if (editMode) {
    return (
      <div className="role_wrapper" style={{ justifyContent: "space-between" }}>
        <div className="role_content" style={{ width: "calc(100% - 46px" }}>
          <TextArea
            autoSize
            defaultValue={
              role?.descriptor === "descriptor" ? "" : role?.descriptor
            }
            onBlur={() => saveOnBlur()}
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toggleEditMode();
                e.preventDefault();
              }
            }}
            onChange={(e) =>
              updateRole &&
              e.target.value?.trimStart().trimEnd() &&
              updateRole(role.id, e.target.value?.trimStart().trimEnd())
            }
            placeholder="Type role descriptor..."
          />
        </div>
        <div className="detail_dropdown">
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            className="actionButton"
            overlayClassName="full_width_btn role_dropddown_cs"
          >
            <Button onClick={(e) => e.stopPropagation()}>
              <Image
                src="/images/vertical_dots.svg"
                width={3}
                height={15}
                alt="vertical dots"
                preview={false}
              />
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }

  return (
    <div className="role_wrapper" style={{ justifyContent: "space-between" }}>
      <div className="role_content" style={{ width: "calc(100% - 46px" }}>
        {/* <p onClick={() => setEditMode(true)}>
          {role?.descriptor === "descriptor" ? "" : role?.descriptor}
        </p> */}
        <TextArea
          autoSize
          autoFocus={role?.descriptor === "descriptor"}
          style={{ border: "1px solid #fff" }}
          defaultValue={
            role?.descriptor === "descriptor" ? "" : role?.descriptor
          }
          placeholder="Type role descriptor..."
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onClick={() => setEditMode(true)}
          onFocus={() => setEditMode(true)}
          onBlur={() => setEditMode(false)}
        />
      </div>
      {sessionId && (
        <Select
          className="select_category"
          suffixIcon={<SelectArrowIcon />}
          popupMatchSelectWidth={false}
          defaultValue={role?.category ? role?.category : "UNCATEGORIZED"}
          onChange={(value) =>
            selectDescriptor(value, role.descriptor, role?.id)
          }
          options={[
            { value: "UNCATEGORIZED", label: "Uncategorized" },
            { value: "PROCEDURE_TASK", label: "Procedure/Task" },
            { value: "RESPONSIBILITY", label: "Responsibility" },
          ]}
        />
      )}

      <div className="detail_dropdown">
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
          overlayClassName="full_width_btn role_dropddown_cs"
        >
          <Button onClick={(e) => e.stopPropagation()}>
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}
