import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const HintSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.727539 8.33329C0.727539 4.33328 4.00027 1.06055 8.00029 1.06055C12.0003 1.06055 15.273 4.33328 15.273 8.33329C15.273 12.3333 12.0003 15.606 8.00029 15.606C4.00027 15.606 0.727539 12.3333 0.727539 8.33329ZM7.9996 10.4421C7.5632 10.4421 7.27231 10.1512 7.27231 9.71489V8.9876C7.27231 8.55125 7.5632 8.26031 7.9996 8.26031C8.94503 8.26031 9.67231 7.53307 9.67231 6.5876C9.67231 5.64214 8.94503 4.91489 7.9996 4.91489C7.05414 4.91489 6.32685 5.64214 6.32685 6.5876C6.32685 7.02396 6.03596 7.31489 5.5996 7.31489C5.1632 7.31489 4.87231 7.02396 4.87231 6.5876C4.87231 4.84214 6.25414 3.46033 7.9996 3.46033C9.74503 3.46033 11.1268 4.84214 11.1268 6.5876C11.1268 8.04214 10.1087 9.27849 8.72685 9.64214V9.71489C8.72685 10.1512 8.43596 10.4421 7.9996 10.4421ZM7.9997 13.2059C7.56335 13.2059 7.27246 12.9149 7.27246 12.4786V11.7513C7.27246 11.3149 7.56335 11.024 7.9997 11.024C8.4361 11.024 8.72699 11.3149 8.72699 11.7513V12.4786C8.72699 12.9149 8.4361 13.2059 7.9997 13.2059Z"
      fill="#9396A6"
    />
  </svg>
);

const HintIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HintSvg} {...props} />
);

export default HintIcon;
