import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const WaitingIconSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="6" fill="#ACAEBD" />
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M6 12.75C6 16.4625 9.0375 19.5 12.75 19.5C16.4625 19.5 19.5 16.4625 19.5 12.75C19.5 9.0375 16.4625 6 12.75 6C9.0375 6 6 9.0375 6 12.75ZM12.75 13.4252H16.0575C16.4625 13.4252 16.7325 13.1552 16.7325 12.7502C16.7325 12.3452 16.4625 12.0752 16.0575 12.0752H13.425V9.44269C13.425 9.03769 13.155 8.76769 12.75 8.76769C12.345 8.76769 12.075 9.03769 12.075 9.44269V12.7502C12.075 13.1552 12.345 13.4252 12.75 13.4252Z"
      fill="white"
    />
  </svg>
);

const WaitingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WaitingIconSvg} {...props} />
);

export default WaitingIcon;
