import { Button, Card, Col, Row } from "antd";
import {
  RoleType,
  SessionTypeResponse,
} from "../../../organisms/MyJob/ducks/types";
import { useEffect, useState } from "react";
import {
  updateIndividualPetalInfoByTeamId,
  updatePetalInfoByTeamId,
} from "../../../organisms/MyJob/ducks/services";
import SessionFlower from "../SessionFlower";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import { useAppDispatch } from "../../../config/hooks";
import { getFlowerDataBySessionID } from "../../MyJob/ducks/actions";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  activeData?: any;
  activeNumber: number;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  teamName: string;
};

export default function FpSessionRoleContributor(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    activeNumber,
    petalTeams,
    sessionData,
    setLoading,
    teamName,
    sessionId,
  } = props;
  const [seriousName, setSeriousName] = useState("");
  const [funnyName, setFunnyName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [newData, setNewData] = useState<RoleType[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const addNames = () => {
    const payload = {
      funny_name: funnyName,
      serious_name: seriousName,
      petal_id: activeData?.id,
    };

    updateIndividualPetalInfoByTeamId(teamId, payload).then(() => {
      dispatch(getFlowerDataBySessionID(sessionId));
    });
  };

  useEffect(() => {
    setNewData(data?.sessionPetals);
  }, [data]);

  const submitSFNames = () => {
    setLoading(true);

    updatePetalInfoByTeamId(teamId, "SUBMITTED")
      .then(() => {
        setLoading(false);
        navigate(`/fp-session/thanks/${sessionId}`);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  // const submitSFNames = () => {
  //   setLoading(true);
  //   const contributions: any = [];
  //   const currentTeamDetails = petalTeams.find(
  //     (e: { team_name: string }) => e.team_name === teamName
  //   );
  //   newData?.map((res: any) => {
  //     contributions.push({
  //       funny_name: res?.funny_name,
  //       serious_name: res?.serious_name,
  //       petal_id: res?.id,
  //     });
  //   });

  console.log("teamId", teamId);

  useEffect(() => {
    if (petalTeams?.length > 0) {
      petalTeams?.map((e: any) => {
        if (e?.team_name === teamName) {
          setTeamId(e?.id);

          updatePetalInfoByTeamId(e?.id, "PENDING");

          const seriousName = e?.contributions.find(
            (item1: any) => activeData?.id === item1.petal_id
          )?.serious_name;

          const funnyName = e?.contributions.find(
            (item1: any) => activeData?.id === item1.petal_id
          )?.funny_name;

          setSeriousName(seriousName);
          setFunnyName(funnyName);
        }
      });
    }
  }, [petalTeams]);

  console.log("newData", newData, activeTab, seriousName, funnyName);
  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            {newData?.length > 0 && (
              <>
                <SessionFlower
                  data={newData}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  activeData={activeData}
                  petalTeams={petalTeams}
                  teamName={teamName}
                />
                <div className="flower_position">
                  <div className="flew_wrapper">
                    <h4>{sessionData?.job?.name}</h4>
                    <p>
                      {sessionData?.c_person?.first_name ?? ""}{" "}
                      {sessionData?.c_person?.last_name ?? ""}{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4> Come up with role names </h4>
          <ol>
            <li>Share your screen with other members of your group.</li>
            <li>Together, brainstorm serious and funny names for each role.</li>
            <li>
              Enter names for each role in the fields and submit the results.
            </li>
          </ol>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              Role
            </div>
            {petalTeams?.map(
              (e: any) =>
                e?.team_name === teamName && (
                  <div className="inputFields">
                    <div style={{ width: "100%" }}>
                      <label> Serious name: </label>
                      <input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSeriousName(e.target.value)
                        }
                        className="ant-input ant-input-outlined formControl"
                        placeholder="Type a serious name here"
                        value={
                          e?.contributions.find(
                            (item1: any) => activeData?.id === item1.petal_id
                          )?.serious_name
                        }
                        onBlur={() => addNames()}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <label> Funny name: </label>
                      <input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFunnyName(e.target.value)
                        }
                        className="ant-input ant-input-outlined formControl"
                        placeholder="Type a funny name here"
                        value={
                          e?.contributions.find(
                            (item1: any) => activeData?.id === item1.petal_id
                          )?.funny_name
                        }
                        onBlur={() => addNames()}
                      />
                    </div>
                  </div>
                )
            )}

            <div className="role_description">
              <h4> Role descriptors: </h4>
              <ul>
                {activeData?.descriptors?.map((e: { descriptor: string }) => (
                  <li>{e?.descriptor}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={+activeTab === data?.sessionPetals?.length}
              onClick={() => {
                setActiveTab((+activeTab + 1).toString());
              }}
            >
              Next role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={submitSFNames}
              >
                Submit
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
