export default function GuideWorkflows() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are workflows
      </h2>
      <p className="wg_caption">
        Steps or processes that transfer tasks or information between roles or
        systems, adding value through procedural rules.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Think of the procedures your job or role follows and tasks it’s
              responsible for.
            </li>
            <li>
              What’s the name of the higher level “process” these are part of?
            </li>
            <li>
              Does the procedure have an interdependency with another job/role?
              If yes, what is the name of that job/role and what is the name of
              the Workflow/Process this interdependency is happening inside of?
            </li>
            <li>Does the workflow flow into or support another workflow?</li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6> Examples </h6>
          <ul>
            <li>
              Sales Manager:
              <ul>
                <li>Client onboarding</li>
                <li>Sales forecasting and reporting</li>
                <li>Lead nurturing</li>
              </ul>
            </li>
            <li>
              Project Manager (Construction):
              <ul>
                <li>Construction project planning</li>
                <li>Resource allocation and scheduling</li>
                <li>Risk management</li>
              </ul>
            </li>
            <li>
              Customer Service Manager:
              <ul>
                <li>Service ticket management</li>
                <li>Feedback collection and analysis</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
