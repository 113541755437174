export default function GuideTechnologies() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" /> What are Technologies
      </h2>
      <p className="wg_caption">
        Tools, software, hardware, or systems used to perform job or role
        functions, typically requiring login or activation.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Observe and analyze all the software applications, websites, and
              various technological platforms where you input, manipulate,
              transfer, or source the data from.
            </li>
            <li>
              Look for the names of HRIS, ERP, CRM, IMS, LMS, etc. your job/role
              relies on.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6>Examples</h6>
          <ul>
            <li>Salesforce.com, Quickbooks, MS Excel, Zoom, Gmail, Zendesk.</li>
            <li> PC/Laptop, mobile phone, Unmanned aerial vehicles (UAV) </li>
          </ul>
        </div>
      </div>
    </>
  );
}
