export default function GuideMeetings() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are Meetings
      </h2>
      <p className="wg_caption">
        Meetings are gatherings where team members discuss topics, share
        information, make decisions, and coordinate efforts.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>Analyze all the meetings you participate in.</li>
            <li>
              Identify the role you are in during the meeting:
              <ul>
                <li>What topics do you discuss?</li>
                <li>What assignments do you receive?</li>
              </ul>
            </li>
            <li>Reflect on why the meetings matter to your job/role.</li>
            <li>
              If you define that the meeting you’re attending has nothing to do
              with your job/role’s responsibilities or teams, do not add this
              meeting to this job/role.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6> Examples </h6>
          <ul>
            <li>
              Sales strategy meeting, Project planning meeting, Customer support
              review.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
