export default function KeyResults() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are key results
      </h2>
      <p className="wg_caption">
        Key results are specific, measurable outcomes that track progress
        towards achieving the objectives of a job or role. They are typically
        controllable on a short term level, and may be tracked in something like
        a weekly scorecard.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Specify measurable outcomes, and think about the controllable
              activities that can be measured.
            </li>
            <li>
              Each key result should clearly track progress towards an
              objective.
            </li>
            <li>
              Make sure they are specific, measurable, achievable, relevant, and
              time-bound (SMART).
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6> Examples </h6>
          <ul>
            <li>Calls made this week</li>
            <li>Days shipped on time</li>
            <li>New warm leads this week</li>
            <li>Cycle counts made</li>
          </ul>
        </div>
      </div>
    </>
  );
}
