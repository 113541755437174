import { Avatar, Button, Col, Row } from "antd";
import { CloseIcon, PlusIcon } from "../../../../atoms/CustomIcons";
// import TrashIcon from "../../../../atoms/CustomIcons/TrashIcon";
import { getInitials } from "../../../../config/constants";
import TextAreaField from "../../../../molecules/FormItems/TextArea";

export default (props: any) => {
  const { control, append, remove, fields, sessionData } = props;

  const initQ: any = {
    descriptor: "",
  };

  const onAdd = () => {
    append(initQ);
  };

  const removeWeekTime = (ind: number) => {
    remove(ind);
  };
  console.log("sessionData", sessionData);
  return (
    <Col span={24} className="task_list">
      <div className="tasks_section">
        <h4> Generate a list </h4>
        <p>
          Reflect and write down everything that{" "}
          <b>
            {sessionData?.data?.c_person?.first_name ?? ""}{" "}
            {sessionData?.data?.c_person?.last_name ?? ""}
          </b>{" "}
          does or thinks about - every second, minute, hour, week, month,
          quarter, and year.
        </p>

        <div className="user_profile">
          {sessionData?.data?.c_person?.image?.url ? (
            <div className="participant_avatar">
              <Avatar src={sessionData?.data?.c_person?.image?.url} />
            </div>
          ) : (
            <div className="participant_avatar">
              <Avatar>
                {getInitials(
                  sessionData?.data?.c_person?.first_name,
                  sessionData?.data?.c_person?.last_name
                )}
              </Avatar>
            </div>
          )}

          <div style={{ marginLeft: 12 }}>
            <h4>
              {" "}
              {sessionData?.data?.c_person?.first_name ?? ""}{" "}
              {sessionData?.data?.c_person?.last_name ?? ""}{" "}
            </h4>
            <div className="job_title">
              {sessionData?.data?.c_person?.job?.name ?? (
                <span style={{ opacity: 0.5 }}>No job title</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <Row className="add_more">
        <Col flex={fields?.length > 0 ? "0 0 100%" : "0 0 100%"}>
          {fields.map((item: any, ind: number) => (
            <div className="addBook_wrapper" key={item.id}>
              <div className="book_name">
                <TextAreaField
                  fieldName={`RoleDescriptors.${ind}.descriptor`}
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder:
                      "Enter responsibility, task, procedure, activity, etc.",
                    autoSize: true,
                  }}
                  // isRequired
                  // rules={{
                  //   required: "Book name is required.",
                  // }}
                  // validMessage={
                  //   Object.entries(errors).length > 0 &&
                  //   errors?.fav_business_books &&
                  //   errors?.fav_business_books[ind]?.businessBook &&
                  //   errors?.fav_business_books?.length &&
                  //   errors?.fav_business_books[ind]?.businessBook?.message
                  // }
                  // validate={
                  //   Object.entries(errors).length > 0 &&
                  //   errors?.fav_business_books &&
                  //   errors?.fav_business_books?.length &&
                  //   errors?.fav_business_books[ind]?.businessBook &&
                  //   "error"
                  // }
                />
              </div>

              <div className="book_delete">
                <Button
                  htmlType="button"
                  className="removeFieldButton"
                  onClick={() => removeWeekTime(ind)}
                >
                  <CloseIcon className="darkIcon" />
                </Button>
              </div>
            </div>
          ))}
        </Col>
        <Col span={24}>
          <Button
            htmlType="button"
            onClick={onAdd}
            className="addFieldButton"
            icon={<PlusIcon className="darkIcon" />}
          >
            Add more
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
