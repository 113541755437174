export default function Skills() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are Skills
      </h2>
      <p className="wg_caption">
        Skills are the specific abilities and expertise that an individual
        possesses, enabling them to perform tasks and responsibilities
        effectively in their job or role. These can be technical skills, soft
        skills, or industry-specific knowledge. For instance, someone certified
        as a Microsoft Office Specialist should have the Skills to comfortably
        navigate Office programs. Degrees, certifications, licenses, etc. are
        not Skills but often indicate a set of necessary Skills.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Reflect what qualities which are required from you to perform the
              tasks for your job or role.
            </li>
            <li>
              Consider the skills you have gained through education, training
              programs, and certifications required for your job and role.
            </li>
            <li>
              Observe and analyze the skills of high performers in your role.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6>Examples</h6>
          <ul>
            <li>
              Sales Manager Role:
              <ul>
                <li>Negotiation</li>
                <li>CRM proficiency</li>
                <li>Effective Communication</li>
              </ul>
            </li>
            <li>
              Project Manager (Construction) Job:
              <ul>
                <li>Project planning</li>
                <li>Risk management</li>
                <li>Leadership</li>
              </ul>
            </li>
            <li>
              Customer Service Manager Role:
              <ul>
                <li>Conflict resolution</li>
                <li>Empathy</li>
                <li>Customer service metrics analysis</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
