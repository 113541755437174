import { Fragment, useEffect, useState } from "react";
import { Button, Tag, Avatar, Image, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../../../../atoms/ConfirmDelete";
import { Popup } from "../../../../atoms/Popup";
import AddRole from "../../../../atoms/AddRole";
import { JobDetailType } from "../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../config/constants";
import { deleteJob } from "../../../../organisms/CompanyDetails/ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../Toasts/ErrorMessage";
import { useAppSelector } from "../../../../config/hooks";

type PropsType = {
  companyId: string;
  jobId: string;
  jobDetailData: JobDetailType;
};

export default function DetailTop(props: PropsType) {
  const { jobId, companyId, jobDetailData } = props;
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [addRolePopUp, setAddRolePopUp] = useState(false);
  const [existingRolesId, setExistingRolesId] = useState<Array<string>>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const myJobDropdown = [
    {
      key: "1",
      label: (
        <Button onClick={() => navigate(`/edit-job/${jobId}/${companyId}`)}>
          Edit {companyData?.label_position === "Job" ? "job" : "seat"}
        </Button>
      ),
    },
    // {
    //   key: "2",
    //   label: <Button>Add new role</Button>,
    // },
    {
      key: "3",
      label: (
        <Button onClick={() => setAddRolePopUp(true)}>Add existing role</Button>
      ),
    },
    {
      key: "4",
      label: <Button>View sessions details</Button>,
    },
    // {
    //   key: "5",
    //   label: <Button>Export CSV</Button>,
    // },
    // {
    //   key: "6",
    //   label: <Button>Export PDF</Button>,
    // },
    {
      key: "7",
      label: (
        <Button onClick={() => setIsDeleteModalOpen(true)}>
          Delete {companyData?.label_position === "Job" ? "job" : "seat"}
        </Button>
      ),
    },
  ];

  const myJobIndividualDropdown = [
    // {
    //   key: "1",
    //   label: <Button>Export CSV</Button>,
    // },
    // {
    //   key: "2",
    //   label: <Button>Export PDF</Button>,
    // },
    {
      key: "3",
      label: <Button>View sessions details</Button>,
    },
  ];

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    deleteJob(jobId as string)
      .then(() => {
        navigate(`/company-detail/jobs/${companyId}`);
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading={`Are you sure you want to delete this ${
          companyData?.label_position === "Job" ? "job" : "seat"
        }?`}
        onClose={() => setIsDeleteModalOpen(false)}
        btnText={`Delete ${
          companyData?.label_position === "Job" ? "job" : "seat"
        }`}
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  const addRolePopup = {
    visibility: addRolePopUp,
    class: "delete_confirmation_modal",
    content: (
      <AddRole
        title="Add roles to the job"
        heading={`Select roles you want to add to ${jobDetailData?.name}.`}
        onClose={() => setAddRolePopUp(false)}
        btnText="Add role"
        existingRoles={existingRolesId}
        jobId={jobId}
        companyId={companyId}
      />
    ),
    width: 520,
    onCancel: () => setAddRolePopUp(false),
  };

  useEffect(() => {
    if (jobDetailData?.roles) {
      const existingRoles: string[] = [];
      jobDetailData?.roles?.map((e: { id: string }) => {
        existingRoles.push(e?.id);
        return existingRoles;
      });
      setExistingRolesId(existingRoles);
    }
  }, [jobDetailData?.roles]);

  return (
    <>
      <div className="detail_top">
        <h2 className="jobName">{jobDetailData?.name}</h2>
        <div className="right_top_job">
          {jobDetailData?.department_name && (
            <Tag className="icon_white_btn">
              Department: <b>{jobDetailData?.department_name}</b>
            </Tag>
          )}
          {jobDetailData?.employees?.length > 0 && (
            <Tag
              className="icon_white_btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {companyData?.label_position === "Job" ? "Job" : "Seat"} holders:{" "}
              <Avatar.Group
                className="avatarGroup"
                size="small"
                max={{
                  count: 3,
                  style: {
                    color: "#161616",
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    fontSize: "10px",
                    fontWeight: "500",
                  },
                }}
              >
                {jobDetailData?.employees?.map(
                  (e: {
                    id: string;
                    first_name: string;
                    last_name: string;
                    image: { url: string };
                  }) => (
                    <Fragment key={e?.id}>
                      {e?.image?.url ? (
                        <div className="participant_avatar">
                          <Avatar src={e?.image?.url} />
                        </div>
                      ) : (
                        <div className="participant_avatar">
                          <Avatar>
                            {getInitials(e?.first_name, e?.last_name)}
                          </Avatar>
                        </div>
                      )}
                    </Fragment>
                  )
                )}
              </Avatar.Group>
            </Tag>
          )}

          <div className="detail_dropdown">
            <Dropdown
              menu={{
                items:
                  Object.keys(accountData)?.length > 0 &&
                  accountData?.type === "INDIVIDUAL_CONTRIBUTOR"
                    ? myJobIndividualDropdown
                    : myJobDropdown,
              }}
              trigger={["click"]}
              placement="bottomRight"
              className="actionButton"
              overlayClassName="full_width_btn"
            >
              <Button>
                <Image
                  src="/images/vertical_dots.svg"
                  width={3}
                  height={15}
                  alt="vertical dots"
                  preview={false}
                />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <Popup {...addRolePopup} />
      <Popup {...deleteConfirmationPopup} />
    </>
  );
}
