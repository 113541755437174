import { useEffect, useState } from "react";
import DashboardTemplate from "../../templates/Dashboard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { emptyJobDetailsData, getJobDetails } from "./ducks/actions";
import JobDetails from "../../atoms/JobDetails";
import { useLocation } from "react-router-dom";
import EmptyCard from "../../molecules/EmptyCard";
import CreateJob from "../../atoms/CreateJob";
import { Popup } from "../../atoms/Popup";
import { getUserDetails } from "../MyAccount/ducks/actions";
import { getCompanyDetails } from "../CompanySettings/ducks/actions";

export default function MyJob() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const [loading, setLoading] = useState(false);
  const [jobCreate, setJobCreate] = useState(false);
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const accountData = useAppSelector((state) => state?.account?.accountData);

  useEffect(() => {
    if (jobId && jobId !== "no-job") {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    dispatch(emptyJobDetailsData());
  }, [dispatch]);

  const updateApi = () => {
    dispatch(getUserDetails());
    setJobCreate(false);
  };

  const createJob = {
    visibility: jobCreate,
    class: "upload_photo_modal",
    content: (
      <CreateJob
        title={`Create ${companyData?.label_position?.toLowerCase()}`}
        onClose={() => setJobCreate(false)}
        btnText={`Create ${companyData?.label_position?.toLowerCase()}`}
        updateApi={updateApi}
        companyId={companyId}
        labelPosition={companyData?.label_position}
      />
    ),
    width: 600,
    onCancel: () => setJobCreate(false),
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (jobId === "no-job" && accountData?.job_id) {
      window.location.assign(
        `/my-job/${accountData?.job_id ?? "no-job"}/${accountData?.company_id}`
      );
    }
  }, [jobId, accountData]);

  return (
    <DashboardTemplate loading={jobDetailData?.loading || loading}>
      {jobId !== "no-job" ? (
        <JobDetails
          jobDetailData={jobDetailData?.detailData}
          setLoading={setLoading}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "calc(100vh - 125px)",
          }}
        >
          <EmptyCard
            image="/images/empty/session-icon.svg"
            title={`No ${
              companyData?.label_position === "Job" ? "job" : "seat"
            } assigned yet`}
            description="Create a job and assign it to yourself."
            buttonText={`Create ${companyData?.label_position?.toLowerCase()}`}
            showPopUp={(e) => setJobCreate(e)}
          />
        </div>
      )}
      <Popup {...createJob} />
    </DashboardTemplate>
  );
}
