import { Fragment, useEffect, useState } from "react";
import { Card, Button, Avatar, Image, Radio, RadioChangeEvent } from "antd";
import { useLocation } from "react-router-dom";
import {
  Participants,
  SessionTypeResponse,
} from "../../../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../../../config/constants";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../Toasts/ErrorMessage";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sessionData: SessionTypeResponse;
};

export default function Step1(props: PropsType) {
  const { setCurrent, setLoading, sessionData } = props;
  const [participantId, setParticipantId] = useState("");
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[2];

  const onChange = (e: RadioChangeEvent) => {
    setParticipantId(e.target.value);
  };

  const participantSelected = () => {
    setLoading(true);
    const payload = {
      center_person: participantId,
      last_session_screen: 2,
    };
    updateSessionById(payload, sessionId)
      .then(() => {
        setLoading(false);
        setCurrent(2);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (sessionData?.center_person) {
      setParticipantId(sessionData?.center_person);
    } else {
      setParticipantId("");
    }
  }, [sessionData]);

  const handleRandomSelect = () => {
    const randomIndex = Math.floor(
      Math.random() * sessionData?.participants?.length
    );
    setParticipantId(sessionData?.participants[randomIndex]?.id);
  };

  return (
    <Card className="fp_layout_content session_card">
      <div className="cardContent">
        <div className="select_individual_head">
          <div className="individual_left">
            <h4>Select one individual from the participants</h4>
            <p>Decide who you put in the center of the flower.</p>
          </div>
          <div className="individual_right">
            <Button
              className="icon_grey_btn"
              onClick={handleRandomSelect}
              disabled={sessionData?.participants?.length === 0}
            >
              <Image src="/images/session/magic-icon.svg" preview={false} />
              Select randomly
            </Button>
          </div>
        </div>

        <div className="participants_card">
          <Radio.Group
            buttonStyle="solid"
            onChange={onChange}
            value={participantId}
          >
            {sessionData?.participants?.map((e: Participants) => (
              <Fragment key={e?.id}>
                <Radio.Button value={e?.id}>
                  {e?.image ? (
                    <div className="participant_avatar">
                      <Avatar src={e?.image?.url} />
                    </div>
                  ) : (
                    <div className="participant_avatar">
                      <Avatar style={{ fontSize: 15 }}>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    </div>
                  )}
                  <div className="participant_info">
                    <h6>{`${e?.first_name ?? ""} ${e?.last_name ?? ""}`}</h6>
                    <p>
                      {e?.job?.name ?? (
                        <span style={{ opacity: 0.5 }}>No job title</span>
                      )}
                    </p>
                  </div>
                  <div className="participant_check">
                    <Image
                      src="/images/session/checked-icon.svg"
                      preview={false}
                      width={18}
                      height={18}
                    />
                  </div>
                </Radio.Button>
              </Fragment>
            ))}
          </Radio.Group>

          <div className="steps_btn_wrapper">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              onClick={() => setCurrent(0)}
            >
              Back
            </Button>
            <Button
              htmlType="button"
              className="icon_dark_btn"
              type="primary"
              size="large"
              onClick={() => participantSelected()}
              disabled={!participantId}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
