import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const SubmitIconSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="6" fill="#0D9B54" />
    <path
      d="M19.665 6.03229C19.3691 5.81037 18.9252 5.81037 18.6292 6.18024L17.385 7.70787C16.2013 6.89415 14.7956 6.45029 13.242 6.45029C9.17297 6.45029 5.84375 9.77921 5.84375 13.8478C5.84375 17.9165 9.17297 21.2454 13.242 21.2454C17.311 21.2454 20.6403 17.9165 20.6403 13.8478C20.6403 12.3683 20.1964 11.0368 19.4565 9.85316L18.9387 10.445L18.4208 11.0368L14.1298 16.437C13.9818 16.5849 13.7599 16.7329 13.5379 16.7329C13.316 16.7329 13.168 16.6589 13.0201 16.511L9.8388 13.1081C9.61685 12.8862 9.61685 12.5163 9.69085 12.2944C9.69085 12.2204 9.76485 12.1464 9.8388 12.0724C10.1347 11.7765 10.5786 11.7765 10.8746 12.0724L13.464 14.8835L17.533 9.77921L19.665 7.06795C19.9315 6.71994 19.925 6.22733 19.665 6.03229Z"
      fill="white"
    />
  </svg>
);

const SubmitIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SubmitIconSvg} {...props} />
);

export default SubmitIcon;
