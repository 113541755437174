import { Button, Col, Image, Row, Form, Spin, Alert } from "antd";
import InputField from "../../molecules/FormItems/InputField";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../CustomIcons";
import SelectField from "../../molecules/FormItems/SelectField";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getJobFilters } from "../../organisms/CompanyDetails/ducks/actions";
import { createJobByCompanyId } from "../../organisms/CompanyDetails/ducks/services";
import { AxiosError } from "axios";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import ErrorMessage from "../Toasts/ErrorMessage";
import SuccessMessage from "../Toasts/SuccessMessage";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const {
    onClose,
    title,
    btnText,
    btnApi,
    updateApi,
    companyId,
    labelPosition,
  } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [otherJobsHolder, setOtherJobsHolder] = useState([]);
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const onCloseHandler = () => {
    onClose();
  };

  type FormValues = {
    jobName: string;
    jobHolders: string[];
    department: string;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      jobName: val?.jobName,
      jobHolders: val?.jobHolders,
      department: val?.department ?? "",
      companyId: companyId,
    };
    createJobByCompanyId(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage(`${labelPosition} created.`);
        updateApi && updateApi();
        onClose();
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const checkHoldersJob = (val: { value: string }[]) => {
    const jobHolders = jobsFilterOptions?.employee?.filter(
      (emp: { value: string; job_id?: string }) =>
        val?.some((v) => v?.value === emp?.value && emp?.job_id)
    );
    setOtherJobsHolder(jobHolders);
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <div className="modal_content">
            <Row gutter={16}>
              <Col flex="0 0 100%">
                <InputField
                  fieldName="jobName"
                  label={`${labelPosition} name`}
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: `Type ${labelPosition?.toLowerCase()} name`,
                  }}
                  isRequired
                  rules={{
                    required: labelPosition + " name is required.",
                  }}
                  validate={errors.jobName && "error"}
                  validMessage={errors.jobName && errors.jobName.message}
                />
              </Col>
              <Col flex="0 0 100%">
                <SelectField
                  fieldName="jobHolders"
                  label={`${labelPosition} holders`}
                  control={control}
                  selectOption={jobsFilterOptions?.employee}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select employees",
                    suffixIcon: <DropdownIcon />,
                  }}
                  onSelectChange={(val: { value: string }[]) =>
                    checkHoldersJob(val)
                  }
                  isRequired
                  rules={{
                    required: `${labelPosition} holders is required.`,
                  }}
                  validate={errors.jobHolders && "error"}
                  validMessage={errors.jobHolders && errors.jobHolders.message}
                  tooltipTitle="Employees in your company holding this position."
                />
              </Col>
              {otherJobsHolder.length > 0 && (
                <Col span={24} className="warning_alert_popup">
                  <Alert
                    message={
                      <>
                        <b>
                          {otherJobsHolder
                            ?.map((item: { label: string }) => item?.label)
                            ?.join(", ")}
                        </b>
                        {otherJobsHolder.length === 1
                          ? ` occupy other ${
                              companyData?.label_position === "Job"
                                ? "job"
                                : "seat"
                            }. Their ${
                              companyData?.label_position === "Job"
                                ? "job"
                                : "seat"
                            } will be changed to this one if you proceed.`
                          : ` occupies other ${
                              companyData?.label_position === "Job"
                                ? "jobs"
                                : "seats"
                            }. Their ${
                              companyData?.label_position === "Job"
                                ? "jobs"
                                : "seats"
                            } will be changed to this one if you proceed.`}
                      </>
                    }
                    type="warning"
                    showIcon
                  />
                </Col>
              )}

              <Col flex="0 0 100%">
                <SelectField
                  fieldName="department"
                  label="Departments"
                  control={control}
                  selectOption={jobsFilterOptions?.department}
                  className="fp_select"
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                  // isRequired
                  // rules={{
                  //   required: "Department is required.",
                  // }}
                  // validate={errors.department && "error"}
                  // validMessage={errors.department && errors.department.message}
                  tooltipTitle="A division in your company which the job belongs to."
                />
              </Col>
            </Row>
          </div>

          <div className="modal_footer">
            <Button
              htmlType="button"
              className="cancelBtn defaultBtnColor"
              type="default"
              size="large"
              onClick={() => onCloseHandler()}
            >
              Cancel
            </Button>

            <Button
              htmlType="submit"
              className="actionBtn dangerBtnColor"
              type="primary"
              size="large"
              onClick={btnApi}
            >
              {btnText}
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};
