import { useEffect, useMemo, useState } from "react";
import { Tabs, TabsProps, Image } from "antd";
import { PencilIcon } from "../../../../../atoms/CustomIcons";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { getJobDetails } from "../../../../../organisms/MyJob/ducks/actions";
import { RoleType } from "../../../../../organisms/MyJob/ducks/types";
import EditJobForm from "../../../../../molecules/FormItems/EditJobForm";
import WorksheetGuide from "../../../../../atoms/WorksheetGuide";
import RoleForm from "../../Components/RoleForm";
import { getCompanyDetails } from "../../../../CompanySettings/ducks/actions";

type PropsType = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step2(props: PropsType) {
  const { setLoading } = props;
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [openWorksheet, setOpenWorksheet] = useState(false);
  const [defaultTabSelected, setDefaultTabSelected] = useState("");
  const [jobTabsItems, setJobTabsItems] = useState<TabsProps["items"]>([]);
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const customArray: TabsProps["items"] = [];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useMemo(() => {
    if (jobDetailData?.detailData?.roles?.length > 0) {
      jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
        customArray.push({
          key: `${i + 1}`,
          label: (
            <div className="tab-label">
              <span className="tab-number">{i + 1}</span> {e?.name}{" "}
              <Image
                src={
                  e?.department
                    ? "/images/session/checked-circle.svg"
                    : "/images/session/unchecked-circle.svg"
                }
                preview={false}
                width={20}
              />
            </div>
          ),
          children: (
            <RoleForm
              data={e}
              setActiveTab={setActiveTab}
              totalLength={jobDetailData?.detailData?.roles?.length}
              activeNumber={i + 1}
              setLoading={setLoading}
              setDefaultTabSelected={setDefaultTabSelected}
              setOpenWorksheet={setOpenWorksheet}
            />
          ),
        })
      );

      const jobItems: TabsProps["items"] = [
        {
          key: "jobTitle",
          label: (
            <span style={{ textAlign: "left", display: "block" }}>
              {companyData?.label_position}{" "}
            </span>
          ),
          disabled: true,
        },
        {
          key: "jobName",
          label: (
            <div className="job_name">
              <PencilIcon className="jobName-icon" />{" "}
              {jobDetailData?.detailData?.name}
            </div>
          ),
          children: (
            <EditJobForm
              setLoading={setLoading}
              setActiveTab={setActiveTab}
              totalLength={jobDetailData?.detailData?.roles?.length}
              activeNumber={jobDetailData?.detailData?.roles?.length + 1}
              setDefaultTabSelected={setDefaultTabSelected}
              setOpenWorksheet={setOpenWorksheet}
              jobName={jobDetailData?.detailData?.name}
            />
          ),
        },
      ];

      const tabItems = [...customArray, ...jobItems];
      setJobTabsItems(tabItems);
    }
  }, [jobDetailData?.detailData?.roles, companyData?.label_position]);

  useEffect(() => {
    if (jobDetailData?.detailData?.company_id) {
      dispatch(getCompanyDetails(jobDetailData?.detailData?.company_id));
    }
  }, [dispatch, jobDetailData?.detailData?.company_id]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div className="fp_layout_content" style={{ height: "auto" }}>
      <div className="editJob">
        <div className="controlPanel">
          <div className="title">
            <h6>Roles</h6>
          </div>
          <div className="role_job_list">
            <Tabs
              tabPosition="left"
              items={jobTabsItems}
              activeKey={activeTab}
              onChange={onTabChange}
              destroyInactiveTabPane={true}
              defaultActiveKey={
                jobDetailData?.detailData?.roles?.length > 0 ? "1" : "newRole"
              }
            />
          </div>
        </div>
      </div>
      <WorksheetGuide
        openWorksheet={openWorksheet}
        setOpenWorksheet={setOpenWorksheet}
        defaultTabSelected={defaultTabSelected}
        setDefaultTabSelected={setDefaultTabSelected}
      />
    </div>
  );
}
