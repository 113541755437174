import React from "react";
import { Form, Input } from "antd";
import { Controller } from "react-hook-form";
import { InfoIconOutlined } from "../../../atoms/CustomIcons";

export interface InputFieldProps {
  fieldName: string;
  isRequired?: boolean;
  label?: string;
  validate?: any;
  validMessage?: string | any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  prefix?: any;
  disabled?: boolean;
  tooltipTitle?: string | any;
  optionalLabel?: string;
  hintMessage?: string;
  onInputChange?: any;
}

const InputField: React.FunctionComponent<InputFieldProps> = ({
  fieldName,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  iProps,
  prefix,
  disabled,
  tooltipTitle,
  optionalLabel,
  hintMessage,
  onInputChange,
}: InputFieldProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoIconOutlined style={{ color: "#505560", fontSize: "14px" }} />
          ),
        }
      }
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue ?? ""}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <>
            <Input
              disabled={disabled && disabled}
              value={value}
              className="formControl"
              prefix={prefix}
              autoComplete="new-state"
              onChange={(e) => {
                onChange(e);
                onInputChange && onInputChange(e);
              }}
              {...iProps}
            />
            {optionalLabel && (
              <div className="optional-label">{optionalLabel}</div>
            )}
            {hintMessage && <div className="hint-label">{hintMessage}</div>}
          </>
        )}
      />
    </Form.Item>
  );
};

export default InputField;
