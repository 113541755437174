import { useEffect, PropsWithChildren } from "react";
import { VerticalDots } from "../../atoms/CustomIcons";
import { Button, Layout, Image, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUserDetails } from "../../organisms/MyAccount/ducks/actions";

const { Sider, Content } = Layout;

const NoPermissionTemplate = (props: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const logoutHandler = () => {
    localStorage.removeItem("@fpToken");
    window.location.assign("/login");
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Button className="footer_logout" onClick={logoutHandler}>
          {" "}
          Log Out{" "}
        </Button>
      ),
      key: "1",
    },
  ];

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (
      accountData &&
      Object.keys(accountData)?.length > 0 &&
      accountData?.type !== "member"
    ) {
      navigate("/");
    }
  }, [accountData]);

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={true}
        className="dashboard_sidebar"
        width={264}
      >
        <div className="sidebar_logo">
          <Link to="/dashboard" className="logo_area">
            <div className="logo_bg">
              <Image
                src="/images/logo.svg"
                width={189}
                height={48}
                alt="Logo"
                preview={false}
              />
            </div>
          </Link>
        </div>

        <div className="ant-layout-sider-trigger sidebar_footer">
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="topRight"
            overlayClassName="ft_dropdown"
          >
            <a onClick={(e) => e.preventDefault()}>
              <div className="user_info">
                <div className="parent">
                  <div className="logo_bg">
                    {accountData?.files?.length > 0 && (
                      <Image
                        src={accountData?.files[0]?.url}
                        width={40}
                        height={40}
                        alt="Logo"
                        preview={false}
                      />
                    )}
                  </div>
                  <div className="logo_text">
                    <h4>
                      {`${accountData?.first_name ?? ""} ${
                        accountData?.last_name ?? ""
                      }`}{" "}
                    </h4>
                    <p> {accountData?.email ?? ""} </p>
                  </div>
                </div>

                <VerticalDots />
              </div>
            </a>
          </Dropdown>
        </div>
      </Sider>
      <Layout className="main_layout">
        <Content className="content_area">{props.children}</Content>
      </Layout>
    </Layout>
  );
};

export default NoPermissionTemplate;
