import { Card, Button, Col, Form } from "antd";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import FullPageLayout from "../../templates/FullPageLayout";
import { CloseIcon } from "../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import List from "./Components/List";
import { useEffect, useState } from "react";
import {
  getRoleDescriptorForSessionByUserId,
  getSessionById,
} from "../MyJob/ducks/actions";
import { addRoleDescriptorsInBulk } from "../MyJob/ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";
import { updateEmployeeById } from "../CompanyDetails/ducks/services";

export default function GenerateList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[3];
  const sessionData = useAppSelector((state) => state?.jobDetail?.sessionData);
  const roleData = useAppSelector((state) => state?.jobDetail?.roleData);
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      RoleDescriptors: [
        {
          descriptor: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "RoleDescriptors",
  });

  const onSubmit = (val: any) => {
    setLoading(true);
    const listArray: string[] = [];
    val?.RoleDescriptors?.map((e: any) => {
      if (e?.descriptor) {
        return listArray.push(e?.descriptor);
      }
    });

    const payload = {
      session_id: sessionId,
      descriptors: listArray,
    };

    addRoleDescriptorsInBulk(payload)
      .then(() => {
        setLoading(false);
        if (accountData?.id) {
          const payload = {
            participant_status: "SUBMITTED",
          };
          updateEmployeeById(payload, accountData?.id as string)
            .then(() => {
              navigate(`/fp-session/thanks/${sessionId}`);
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                ErrorMessage("Something went wrong");
              }
            });
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);

          if (e?.response?.data?.error?.statusCode === 400) {
            navigate(`/fp-session/sorry-screen/${sessionId}`);
          } else {
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
          }
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (roleData?.length > 0) {
      setValue("RoleDescriptors", roleData);
    }
  }, [roleData]);

  useEffect(() => {
    if (sessionId) {
      dispatch(getRoleDescriptorForSessionByUserId(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (accountData?.id && accountData?.participant_status !== "SUBMITTED") {
      const payload = {
        participant_status: "PENDING",
      };
      updateEmployeeById(payload, accountData?.id as string)
        .then()
        .catch((e: unknown) => {
          if (e instanceof AxiosError) {
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
          } else {
            ErrorMessage("Something went wrong");
          }
        });
    }
  }, [accountData]);

  console.log("fields", fields);
  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
          <h3> Flower Power session </h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "auto", minHeight: "calc(100vh - 128px)" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "612px",
            padding: "0",
            border: "unset",
            marginTop: "0px",
          }}
        >
          <Form onFinish={handleSubmit(onSubmit)}>
            <Col flex="0 0 265px">
              <List
                control={control}
                append={append}
                remove={remove}
                fields={fields}
                errors={errors}
                sessionData={sessionData}
              />
            </Col>
            <Col>
              <div>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="icon_dark_btn"
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
