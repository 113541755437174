export default function Entities() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" /> What are Entities
      </h2>
      <p className="wg_caption">
        This is a broad group that typically captures elements outside of the
        organization or outside of your functional unit, an internal customer
        for instance. An Entity can be a relationship inside a customer, vendor,
        supplier or regulator. An Entity can be a contract or project. There are
        often connections that need to be spelled out not captured in the other
        elements, Entities can be your wildcard.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Examine the processes and workflows in your job/role to identify
              external and internal dependencies.
            </li>
            <li>
              Determine which projects your job/role is part of and the entities
              involved in those projects.
            </li>
            <li>
              List the stakeholders and collaborators you interact with
              regularly.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6>Examples</h6>
          <ul>
            <li>
              Customers, Marketing agencies, Suppliers, Regulatory bodies, and
              any other vendor names.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
