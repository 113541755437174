import * as action_types from "./constants";

export type MyCompanyState = {
  jobsList: {
    loading: boolean;
    count: number;
    data: [];
  };
  rolesList: {
    loading: boolean;
    count: number;
    data: [];
  };
  employeesList: {
    loading: boolean;
    count: number;
    data: [];
  };
  jobFiltersOptions: {
    roles: string;
  };
  rolesFiltersOptions: {
    job: string;
  };
  employeeFiltersOptions: {
    job: string;
  };
  fieldsDropdown: {
    label: string;
    value: string;
  }[];

  roleDropdown: {
    jobs: {
      label: string;
      value: string;
    }[];
    roles: {
      label: string;
      value: string;
    }[];
    users: {
      label: string;
      value: string;
    }[];
  };
  roleDetails: {
    loading: boolean;
    details: null;
  };
  roleNames: {
    seriousName1: string;
    seriousName2: string;
    seriousName3: string;
    funnyName1: string;
    funnyName2: string;
    funnyName3: string;
  };
};

const initialState: MyCompanyState = {
  jobsList: {
    loading: true,
    count: 0,
    data: [],
  },
  rolesList: {
    loading: true,
    count: 0,
    data: [],
  },
  employeesList: {
    loading: true,
    count: 0,
    data: [],
  },
  jobFiltersOptions: {
    roles: "",
  },
  rolesFiltersOptions: {
    job: "",
  },
  employeeFiltersOptions: {
    job: "",
  },
  fieldsDropdown: [
    {
      label: "",
      value: "",
    },
  ],
  roleDropdown: {
    jobs: [
      {
        label: "",
        value: "",
      },
    ],
    roles: [
      {
        label: "",
        value: "",
      },
    ],
    users: [
      {
        label: "",
        value: "",
      },
    ],
  },
  roleDetails: {
    loading: true,
    details: null,
  },
  roleNames: {
    seriousName1: "",
    seriousName2: "",
    seriousName3: "",
    funnyName1: "",
    funnyName2: "",
    funnyName3: "",
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.JOBS_LIST:
      return {
        ...state,
        jobsList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.ROLES_LIST:
      return {
        ...state,
        rolesList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.EMPLOYEES_LIST:
      return {
        ...state,
        employeesList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.COMPANY_DROP:
      return { ...state, fieldsDropdown: data?.data };

    case action_types.ROLE_DROP:
      return { ...state, roleDropdown: data };

    case action_types.JOB_FILTERS:
      return { ...state, jobFiltersOptions: data };

    case action_types.ROLES_FILTERS:
      return { ...state, rolesFiltersOptions: data };

    case action_types.EMPLOYEES_FILTERS:
      return { ...state, employeeFiltersOptions: data };

    case action_types.ROLES_DETAILS:
      return {
        ...state,
        roleDetails: { details: data?.details, loading: action?.data?.loading },
      };

    case action_types.SET_ROLE_NAMES:
      return {
        ...state,
        roleNames: {
          ...state.roleNames,
          ...action.payload.roleNames,
        },
      };

    default:
      return state;
  }
};
