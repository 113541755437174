export default function GuideSkills() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        What are Skills
      </h2>
      <p className="wg_caption">
        Abilities and expertise enabling effective task performance, including
        technical, soft, or industry-specific knowledge.
      </p>

      <div className="guide_sections">
        <div className="guide_card greenBorder">
          <h6> How to define </h6>
          <ul>
            <li>
              Reflect what qualities which are required from you to perform the
              tasks for your job or role.
            </li>
            <li>
              Consider the skills you have gained through education, training
              programs, and certifications required for your job and role.
            </li>
            <li>
              Observe and analyze the skills of high performers in your role.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <h6>Examples</h6>
          <ul>
            <li>
              Sales Manager Role:
              <ul>
                <li>Negotiation</li>
                <li>CRM proficiency</li>
                <li>Effective Communication</li>
              </ul>
            </li>
            <li>
              Project Manager (Construction) Job:
              <ul>
                <li>Project planning</li>
                <li>Risk management</li>
                <li>Leadership</li>
              </ul>
            </li>
            <li>
              Customer Service Manager Role:
              <ul>
                <li>Conflict resolution</li>
                <li>Empathy</li>
                <li>Customer service metrics analysis</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
