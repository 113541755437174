import { Form, Button, Row, Col, Checkbox, Image, Alert, Tooltip } from "antd";
import { useForm } from "react-hook-form";
import TextEditor from "../TextEditor";
import PrevIcon from "../../../atoms/CustomIcons/PrevIcon";
import NextIcon from "../../../atoms/CustomIcons/NextIcon";
import { DropdownIcon, HintIcon } from "../../../atoms/CustomIcons";
import SelectField from "../SelectField";
import { UpdateJob } from "../../../organisms/CompanyDetails/ducks/types";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
  getJobFilters,
} from "../../../organisms/CompanyDetails/ducks/actions";
import {
  generateAIDescription,
  updateJobById,
} from "../../../organisms/CompanyDetails/ducks/services";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { getCompanyDetails } from "../../../organisms/CompanySettings/ducks/actions";

type PropsType = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  totalLength?: number;
  activeNumber?: number;
  setOpenWorksheet?: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultTabSelected?: React.Dispatch<React.SetStateAction<string>>;
  jobName: string;
};

interface OnChangeEvent {
  target: {
    checked: boolean;
  };
}

export default function EditJobForm(props: PropsType) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<UpdateJob>();
  const {
    setLoading,
    setActiveTab,
    activeNumber,
    setOpenWorksheet,
    setDefaultTabSelected,
    jobName,
  } = props;
  const dispatch = useAppDispatch();
  const url = useLocation();
  const navigate = useNavigate();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );

  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const [checkNA, setCheckNA] = useState({
    objectives: false,
    key_results: false,
    procedures: false,
    workflows: false,
    skills: false,
    technologies: false,
    teams: false,
    entities: false,
    meetings: false,
    reports_to: false,
    mentored_by: false,
  });

  const onSubmit = (val: UpdateJob) => {
    setLoading(true);
    console.log("val", val);
    const payload = {
      name: jobDetailData?.detailData?.name,
      jobHolders: val?.jobHolders,
      department_name: val?.department_name,
      purpose_statement: val?.purpose_statement,
      reports_to: checkNA?.reports_to ? "N/A" : val?.reports_to,
      mentored_by: checkNA?.mentored_by ? "N/A" : val?.mentored_by,
      objectives: checkNA?.objectives ? "N/A" : val?.objectives,
      key_results: checkNA?.key_results ? [] : val?.key_results,
      procedures: checkNA?.procedures ? "N/A" : val?.procedures,
      workflows: checkNA?.workflows ? [] : val?.workflows,
      skills: checkNA?.skills ? [] : val?.skills,
      technologies: checkNA?.technologies ? [] : val?.technologies,
      teams: checkNA?.teams ? [] : val?.teams,
      entities: checkNA?.entities ? "N/A" : val?.entities,
      meetings: checkNA?.meetings ? [] : val?.meetings,

      company_id: companyId,
      status: "pending_approval",
    };
    updateJobById(payload, jobId)
      .then(() => {
        setLoading(false);
        SuccessMessage("Job updated.");
        navigate(-1);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
      dispatch(getJobFilters(companyId));
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  useEffect(() => {
    if (jobDetailData) {
      setCheckNA({
        objectives: jobDetailData?.detailData?.objectives === "N/A",
        key_results: jobDetailData?.detailData?.key_results?.length === 0,
        procedures: jobDetailData?.detailData?.procedures === "N/A",
        workflows: jobDetailData?.detailData?.workflows?.length === 0,
        skills: jobDetailData?.detailData?.skills?.length === 0,
        technologies: jobDetailData?.detailData?.technologies?.length === 0,
        teams: jobDetailData?.detailData?.teams?.length === 0,
        entities: jobDetailData?.detailData?.entities === "N/A",
        meetings: jobDetailData?.detailData?.meetings?.length === 0,
        reports_to: jobDetailData?.detailData?.reports_to === "N/A",
        mentored_by: jobDetailData?.detailData?.mentored_by === "N/A",
      });

      if (jobDetailData?.detailData?.objectives !== "N/A") {
        setValue("objectives", jobDetailData?.detailData?.objectives);
      }
      if (jobDetailData?.detailData?.procedures !== "N/A") {
        setValue("procedures", jobDetailData?.detailData?.procedures);
      }
      if (jobDetailData?.detailData?.entities !== "N/A") {
        setValue("entities", jobDetailData?.detailData?.entities);
      }
      if (jobDetailData?.detailData?.key_results?.length > 0) {
        setValue("key_results", jobDetailData?.detailData?.key_results);
      }
      if (jobDetailData?.detailData?.workflows?.length > 0) {
        setValue("workflows", jobDetailData?.detailData?.workflows);
      }
      if (jobDetailData?.detailData?.skills?.length > 0) {
        setValue("skills", jobDetailData?.detailData?.skills);
      }
      if (jobDetailData?.detailData?.technologies?.length > 0) {
        setValue("technologies", jobDetailData?.detailData?.technologies);
      }
      if (jobDetailData?.detailData?.teams?.length > 0) {
        setValue("teams", jobDetailData?.detailData?.teams);
      }
      if (jobDetailData?.detailData?.meetings?.length > 0) {
        setValue("meetings", jobDetailData?.detailData?.meetings);
      }

      setValue("department_name", jobDetailData?.detailData?.department_name);
      setValue(
        "jobHolders",
        jobDetailData?.detailData?.employees?.map((e: { id: string }) => e?.id)
      );
      setValue(
        "purpose_statement",
        jobDetailData?.detailData?.purpose_statement
      );

      if (jobDetailData?.detailData?.reports_to !== "N/A") {
        setValue("reports_to", jobDetailData?.detailData?.reports_to);
      }

      if (jobDetailData?.detailData?.mentored_by !== "N/A") {
        setValue("mentored_by", jobDetailData?.detailData?.mentored_by);
      }
    }
  }, [jobDetailData]);

  const onChange = (e: OnChangeEvent, field: string): void => {
    setCheckNA({ ...checkNA, [field]: e.target.checked });
  };

  const setPurposeStatementText = () => {
    const payload = {
      name: jobDetailData?.detailData?.name,
      type: "job",
    };
    generateAIDescription(payload).then((res) => {
      setValue("purpose_statement", res?.data?.description);
    });
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <div className="job_card">
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: "16px" }}>
            <div className="mainTitle">
              <h3
                style={{
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  marginBottom: "0px",
                  fontFamily: "'DM Sans'",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img src="/images/dark_pencil.svg" alt="" /> Job: {jobName}
              </h3>
            </div>
          </Col>

          <Col span={24}>
            <Alert
              style={{ marginBottom: "24px" }}
              message={
                <>
                  Take the results from Flower Power session and complete the
                  information below. To ensure accuracy, consult with everyone
                  who holds this position. Refer to our &nbsp;
                  <a
                    href=""
                    style={{
                      color: "#161616",
                      textDecoration: "underline",
                    }}
                    onClick={(e) => {
                      e?.preventDefault();
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("objectives");
                    }}
                  >
                    Worksheet guide
                  </a>
                  &nbsp; for additional help.
                </>
              }
              type="info"
              className="warning_msg alert_for_session"
              showIcon
              closable
            />
          </Col>
          <Col span={24}>
            <SelectField
              fieldName="jobHolders"
              label={
                `${companyData?.label_position === "Job" ? "Job" : "Seat"}` +
                " holders"
              }
              control={control}
              selectOption={jobsFilterOptions?.employee}
              mode="multiple"
              iProps={{
                placeholder:
                  `${companyData?.label_position === "Job" ? "Job" : "Seat"}` +
                  " holders",
                suffixIcon: <DropdownIcon />,
              }}
              tooltipTitle="Employees in your company holding this position."
            />
          </Col>
          <Col span={24}>
            <SelectField
              fieldName="department_name"
              label="Department"
              control={control}
              selectOption={arrayDropdown("DEPARTMENT")}
              iProps={{
                placeholder: "Department",
                suffixIcon: <DropdownIcon />,
              }}
              tooltipTitle="A division in your company which the job belongs to."
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.objectives}
                onChange={(e) => onChange(e, "objectives")}
              />{" "}
              N/A
            </h4>
            <TextEditor
              fieldName="objectives"
              control={control}
              initValue=""
              label={
                <>
                  Objectives
                  <Button
                    className="hint_btn"
                    onClick={(e) => {
                      e?.preventDefault();
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("objectives");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              iProps={{
                disabled: checkNA.objectives,
                placeholder:
                  "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
              }}
              isRequired={!checkNA.objectives ? true : false}
              rules={{
                required: !checkNA.objectives
                  ? "Objectives is required."
                  : false,
              }}
              validate={!checkNA.objectives && errors.objectives && "error"}
              validMessage={
                !checkNA.objectives &&
                errors.objectives &&
                errors.objectives.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.key_results}
                onChange={(e) => onChange(e, "key_results")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="key_results"
              label={
                <>
                  Key Results
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("key_results");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={arrayDropdown("KEY_RESULT")}
              className="fp_select"
              mode="tags"
              iProps={{
                placeholder: "Select key results",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.key_results,
              }}
              isRequired={!checkNA.key_results ? true : false}
              rules={{
                required: !checkNA.key_results
                  ? "Key Results is required."
                  : false,
              }}
              validate={!checkNA.key_results && errors.key_results && "error"}
              validMessage={
                !checkNA.key_results &&
                errors.key_results &&
                errors.key_results.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.procedures}
                onChange={(e) => onChange(e, "procedures")}
              />{" "}
              N/A
            </h4>
            <TextEditor
              fieldName="procedures"
              control={control}
              initValue=""
              label={
                <>
                  Procedures
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("procedures_wis_policies");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              isRequired={!checkNA.procedures ? true : false}
              iProps={{
                disabled: checkNA.procedures,
              }}
              rules={{
                required: !checkNA.procedures
                  ? "Procedures is required."
                  : false,
              }}
              validate={!checkNA.procedures && errors.procedures && "error"}
              validMessage={
                !checkNA.procedures &&
                errors.procedures &&
                errors.procedures.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.workflows}
                onChange={(e) => onChange(e, "workflows")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="workflows"
              label={
                <>
                  Workflows
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("workflows");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={arrayDropdown("WORKFLOWS")}
              mode="tags"
              iProps={{
                placeholder: "Select workflows",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.workflows,
              }}
              isRequired={!checkNA.workflows ? true : false}
              rules={{
                required: !checkNA.workflows ? "Workflows is required." : false,
              }}
              validate={!checkNA.workflows && errors.workflows && "error"}
              validMessage={
                !checkNA.workflows &&
                errors.workflows &&
                errors.workflows.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.skills}
                onChange={(e) => onChange(e, "skills")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="skills"
              label={
                <>
                  Skills
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected && setDefaultTabSelected("skills");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={arrayDropdown("SKILL")}
              mode="tags"
              iProps={{
                placeholder: "Select skills",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.skills,
              }}
              isRequired={!checkNA.skills ? true : false}
              rules={{
                required: !checkNA.skills ? "Skills is required." : false,
              }}
              validate={!checkNA.skills && errors.skills && "error"}
              validMessage={
                !checkNA.skills && errors.skills && errors.skills.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.technologies}
                onChange={(e) => onChange(e, "technologies")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="technologies"
              label={
                <>
                  Technologies
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("technologies");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={arrayDropdown("TECHNOLOGIES")}
              mode="tags"
              iProps={{
                placeholder: "Select Technologies",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.technologies,
              }}
              isRequired={!checkNA.technologies ? true : false}
              rules={{
                required: !checkNA.technologies
                  ? "Technologies is required."
                  : false,
              }}
              validate={!checkNA.technologies && errors.technologies && "error"}
              validMessage={
                !checkNA.technologies &&
                errors.technologies &&
                errors.technologies.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.teams}
                onChange={(e) => onChange(e, "teams")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="teams"
              label={
                <>
                  Teams
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected && setDefaultTabSelected("teams");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={arrayDropdown("TEAMS")}
              mode="tags"
              iProps={{
                placeholder: "Select teams",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.teams,
              }}
              isRequired={!checkNA.teams ? true : false}
              rules={{
                required: !checkNA.teams ? "Teams is required." : false,
              }}
              validate={!checkNA.teams && errors.teams && "error"}
              validMessage={
                !checkNA.teams && errors.teams && errors.teams.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.entities}
                onChange={(e) => onChange(e, "entities")}
              />{" "}
              N/A
            </h4>
            <TextEditor
              fieldName="entities"
              label={
                <>
                  Entities
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("entities");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              initValue=""
              isRequired={!checkNA.entities ? true : false}
              iProps={{
                disabled: checkNA.entities,
              }}
              rules={{
                required: !checkNA.entities ? "Entities is required." : false,
              }}
              validate={!checkNA.entities && errors.entities && "error"}
              validMessage={
                !checkNA.entities && errors.entities && errors.entities.message
              }
            />
          </Col>
          <Col span={24}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.meetings}
                onChange={(e) => onChange(e, "meetings")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="meetings"
              label={
                <>
                  Meetings
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("meetings");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={arrayDropdown("MEETINGS")}
              mode="tags"
              iProps={{
                placeholder: "Select meetings",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.meetings,
              }}
              isRequired={!checkNA.meetings ? true : false}
              rules={{
                required: !checkNA.meetings ? "Meetings is required." : false,
              }}
              validate={!checkNA.meetings && errors.meetings && "error"}
              validMessage={
                !checkNA.meetings && errors.meetings && errors.meetings.message
              }
            />
          </Col>

          <Col span={12}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.reports_to}
                onChange={(e) => onChange(e, "reports_to")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="reports_to"
              label={
                <>
                  Reports To
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("reports__to");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={dropdownData?.jobs}
              iProps={{
                placeholder: "Select reports to",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.reports_to,
              }}
              isRequired={!checkNA.reports_to ? true : false}
              rules={{
                required: !checkNA.reports_to
                  ? "Reports To is required."
                  : false,
              }}
              validate={!checkNA.reports_to && errors.reports_to && "error"}
              validMessage={
                !checkNA.reports_to &&
                errors.reports_to &&
                errors.reports_to.message
              }
            />
          </Col>
          <Col span={12}>
            <h4 className="not_applicable_label">
              <Checkbox
                style={{ marginRight: "4px" }}
                checked={checkNA.mentored_by}
                onChange={(e) => onChange(e, "mentored_by")}
              />{" "}
              N/A
            </h4>

            <SelectField
              fieldName="mentored_by"
              label={
                <>
                  Mentored by
                  <Button
                    className="hint_btn"
                    onClick={() => {
                      setOpenWorksheet && setOpenWorksheet(true);
                      setDefaultTabSelected &&
                        setDefaultTabSelected("mentored__by");
                    }}
                  >
                    <HintIcon />
                  </Button>
                </>
              }
              control={control}
              selectOption={dropdownData?.mentoredBy}
              iProps={{
                placeholder: "Select mentored by",
                suffixIcon: <DropdownIcon />,
                disabled: checkNA.mentored_by,
              }}
              isRequired={!checkNA.mentored_by ? true : false}
              rules={{
                required: !checkNA.mentored_by
                  ? "Mentored By is required."
                  : false,
              }}
              validate={!checkNA.mentored_by && errors.mentored_by && "error"}
              validMessage={
                !checkNA.mentored_by &&
                errors.mentored_by &&
                errors.mentored_by.message
              }
            />
          </Col>
          <Col span={24}>
            <h4
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: '"DM Sans", sans-serif',
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: " 20px",
                color: "#161616",
              }}
            >
              <div>
                Role purpose statement{" "}
                <Button
                  className="hint_btn"
                  onClick={(e) => {
                    e?.preventDefault();
                    setOpenWorksheet && setOpenWorksheet(true);
                    setDefaultTabSelected &&
                      setDefaultTabSelected("purpose_statement");
                  }}
                >
                  <HintIcon />
                </Button>
                <span className="red_color">*</span>
              </div>
              <div>
                <h4 style={{ display: "flex", gap: "6px" }}>
                  <Button
                    className="generate_btn"
                    onClick={setPurposeStatementText}
                  >
                    <Image
                      src="/images/MagicWand.svg"
                      alt="Generate with AI"
                      width={16}
                      height={16}
                      preview={false}
                    />
                    Generate with AI
                  </Button>
                  <Tooltip title="To ensure precise and relevant results, complete the job and every role details before generating.">
                    <HintIcon />
                  </Tooltip>
                </h4>
              </div>
            </h4>
            <TextEditor
              fieldName="purpose_statement"
              control={control}
              initValue=""
              // label="Role purpose statement"
              // tooltipTitle=""
              iProps={{
                placeholder: "Explain the Role purpose statement",
              }}
              isRequired
              rules={{
                required: "Role purpose statement is required.",
              }}
              validate={errors.purpose_statement && "error"}
              validMessage={
                errors.purpose_statement && errors.purpose_statement.message
              }
            />
          </Col>
        </Row>

        <div
          className="steps"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              className="icon_grey_btn"
              icon={<PrevIcon />}
              style={{ marginRight: "10px" }}
              onClick={() =>
                activeNumber &&
                setActiveTab &&
                setActiveTab((activeNumber - 1).toString())
              }
            >
              Previous
            </Button>
            <Button className="icon_grey_btn" disabled>
              Next <NextIcon />
            </Button>
          </div>

          <Button className="icon_dark_btn" htmlType="submit">
            Finish
          </Button>
        </div>
      </div>
    </Form>
  );
}
