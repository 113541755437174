import Lottie from "react-lottie";
import { Card, Button, Row, Col, Alert, Input } from "antd";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
import {
  getFlowerDataBySessionID,
  getSessionById,
} from "../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import SuccessMessage from "../../../../../Toasts/SuccessMessage";
import { useEffect, useState } from "react";
import {
  BASE_URLS,
  ENVIRONMENT_MODE,
} from "../../../../../../config/constants";
import * as animationData from "../Components/LottieAnimations/FP_role_cards_animation.json";
import {
  SubmitIcon,
  WaitingIcon,
  WritingIcon,
} from "../../../../../CustomIcons";
import ConfirmProceeding from "../../../../../ConfirmProceeding";
import { Popup } from "../../../../../Popup";

const webUrl = BASE_URLS[ENVIRONMENT_MODE].REACT_WEB_URL;

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step6(props: PropsType) {
  const { setCurrent, sessionId, setLoading, loading } = props;
  const dispatch = useAppDispatch();
  const [confirmProceeding, setConfirmProceeding] = useState(false);
  const [participantStatus, setParticipantStatus] = useState<null | string>(
    "NOT_STARTED"
  );
  const generateListLink = `${webUrl}/fp-session/role-names`;
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  const updateLastScreen = () => {
    setLoading(true);
    const payload = {
      last_session_screen: 8,
    };
    updateSessionById(payload, sessionId).then(() => {
      setCurrent(8);
      setLoading(false);
      dispatch(getSessionById(sessionId));
    });
  };

  const checkCurrentStatus = () => {
    if (
      participantStatus === "NOT_STARTED" ||
      participantStatus === "PENDING"
    ) {
      setConfirmProceeding(true);
    } else {
      updateLastScreen();
    }
  };

  const copyLink = (teamName: string) => {
    const link = `${generateListLink}/${teamName}/${sessionId}`;
    navigator?.clipboard?.writeText(link);
    SuccessMessage("Link copied");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (!flowerData?.petalTeams || flowerData?.petalTeams?.length === 0) {
      setParticipantStatus("NOT_STARTED"); // Handle empty or null data
      return;
    }
    console.log("flowerData?.petalTeams", flowerData?.petalTeams);
    const hasPending = flowerData?.petalTeams?.some(
      (item: { team_status: string }) => item?.team_status === "PENDING"
    );
    const allSubmitted = flowerData?.petalTeams?.every(
      (item: { team_status: string }) => item?.team_status === "SUBMITTED"
    );

    if (hasPending) {
      setParticipantStatus("PENDING");
    } else if (allSubmitted) {
      setParticipantStatus("SUBMITTED");
    }
    console.log("hasPending", hasPending, allSubmitted);
  }, [flowerData]);

  console.log("participantStatus", participantStatus);

  const confirmProceed = {
    visibility: confirmProceeding,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmProceeding
        title="Names are not completed"
        paragraph="Some participants haven't finished their names and won't be able to
            submit them if you proceed. Are you sure you want to continue?"
        onClose={() => setConfirmProceeding(false)}
        btnText="Yes, continue anyway"
        btnApi={() => updateLastScreen()}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmProceeding(false),
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionId) {
        dispatch(getFlowerDataBySessionID(sessionId));
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [dispatch, sessionId]);
  return (
    <Row>
      <Col span={12}>
        <Card className="fp_layout_content link_card">
          <div className="copy_link_content">
            <div className="session2_content">
              <h2>Generate role names</h2>
              <p>
                The psychology behind the serious and funny is that it forces
                people to use different brain parts. This creates a deeper
                understanding of each role.
              </p>
              <ol style={{ marginTop: 12 }}>
                <li>
                  Each team must select their facilitator who will be writing
                  the names down.
                </li>
                <li>Share the following links with the teams' facilitators.</li>
              </ol>
              <div style={{ marginTop: 24 }}>
                {flowerData?.petalTeams?.map((team: { team_name: string }) => (
                  <div className="link_team">
                    <span className="link_label">
                      {team?.team_name?.replace("-", " ")}
                    </span>
                    <div
                      className="copyLinkText"
                      style={{ position: "relative" }}
                    >
                      <Input
                        value={`${generateListLink}/${team?.team_name}/${sessionId}`}
                        placeholder={`${generateListLink}/${team?.team_name}/${sessionId}`}
                      />
                      <Button
                        className="copyLinkBtn"
                        onClick={() => copyLink(team?.team_name)}
                      >
                        <img src="/images/attachment_icon.svg" alt="" /> Copy
                        link
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {participantStatus === "NOT_STARTED" && (
              <div className="info_alert">
                <Alert
                  message="Status: Teams are waiting for the link"
                  description="Once the link is shared, participants will start writing the names."
                  icon={<WaitingIcon />}
                  type="info"
                  showIcon
                />
              </div>
            )}

            {participantStatus === "PENDING" && (
              <div className="warning_alert">
                <Alert
                  message="Status: Teams are writing the names ..."
                  description="Wait for everyone to submit their names. This status will turn green when all teams submit their name lists."
                  icon={<WritingIcon />}
                  type="warning"
                  showIcon
                />
              </div>
            )}

            {participantStatus === "SUBMITTED" && (
              <div className="submitted_icon">
                <Alert
                  message="Status: All name lists submitted"
                  description={`Everyone's lists are in! Click "Continue" to proceed.`}
                  icon={<SubmitIcon />}
                  type="success"
                  showIcon
                />
              </div>
            )}
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(5)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={checkCurrentStatus}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={12}>
        <div className="step2_role" style={{ marginBottom: 0 }}>
          <Lottie options={defaultOptions} width={610} />
        </div>
      </Col>

      <Popup {...confirmProceed} />
    </Row>
  );
}
