import React from "react";
import { Button, Col, Empty, Image, Row } from "antd";
import { useNavigate } from "react-router-dom";
import NextIcon from "../../atoms/CustomIcons/NextIcon";

interface EmptyCardProps {
  title: string;
  description: JSX.Element | string;
  buttonText: string;
  image: JSX.Element | string;
  companyId?: string;
  jobId?: string;
  buttonImage: string;
  description2: string;
}

const EmptySessionCard: React.FC<EmptyCardProps> = ({
  title,
  description,
  buttonText,
  image,
  jobId,
  companyId,
  buttonImage,
  description2,
}) => {
  const navigate = useNavigate();
  return (
    <div className="empty_card">
      <Empty
        image={image}
        imageStyle={{ height: 220 }}
        description={
          <>
            <h4>{title}</h4>
            <p>{description}</p>
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={24} className="empty_sessions_btn">
            <div>
              <Button
                className="icon_grey_btn"
                onClick={() => navigate(`/edit-job/${jobId}/${companyId}`)}
              >
                Skip session & complete job details <NextIcon />
              </Button>
              <h6
                className="confirmationText"
                style={{ marginTop: "8px", marginBottom: "0px" }}
              >
                {description2}
              </h6>
            </div>

            <div>
              <Button
                className="icon_dark_btn"
                onClick={() => navigate(`/ws-session/${jobId}/${companyId}`)}
              >
                <Image src={buttonImage} preview={false} width={20} />
                {buttonText}
              </Button>
              <h6 className="confirmationText" style={{ visibility: "hidden" }}>
                -
              </h6>
            </div>
          </Col>
        </Row>
      </Empty>
    </div>
  );
};

export default EmptySessionCard;
