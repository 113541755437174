import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import { JobList } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getJobsListing = (payload: JobList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getJobsListing`, payload);
      dispatch({
        type: action_types.JOBS_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.JOBS_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getRolesListing = (payload: JobList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getRolesListing`, payload);
      dispatch({
        type: action_types.ROLES_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.ROLES_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getUsersListing = (payload: JobList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getUsersListing`, payload);
      dispatch({
        type: action_types.EMPLOYEES_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.EMPLOYEES_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getJobFilters = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getJobFilters/${companyId}`);
    dispatch({
      type: action_types.JOB_FILTERS,
      data,
    });
  };
};

export const getRolesFilters = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getRolesFilters/${companyId}`);
    dispatch({
      type: action_types.ROLES_FILTERS,
      data,
    });
  };
};

export const getUserFilters = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getUserFilters/${companyId}`);
    dispatch({
      type: action_types.EMPLOYEES_FILTERS,
      data,
    });
  };
};

export const getCompanyFieldsByIdDrop = (payload: { companyId: string }) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.post(
      `${baseURL}getCompanyFieldsById`,
      payload
    );
    dispatch({
      type: action_types.COMPANY_DROP,
      data,
    });
  };
};

export const getDropdownsByCompany = (payload: {
  companyId: string;
  sendRoles: boolean;
  sendUsers: boolean;
  sendJobs: boolean;
}) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.post(
      `${baseURL}getDropdownsByCompany`,
      payload
    );
    dispatch({
      type: action_types.ROLE_DROP,
      data,
    });
  };
};

export const getRoleDetails = (roleId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.get(`${baseURL}getRoleDetails/${roleId}`);
      dispatch({
        type: action_types.ROLES_DETAILS,
        data: { details: data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.ROLES_DETAILS,
        data: { loading: false },
      });
    }
  };
};

export const setRoleNames = (
  type: string,
  value: string | undefined | string[]
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_ROLE_NAMES,
      payload: { roleNames: { [type]: value } },
    });
  };
};
