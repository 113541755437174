import { TabsProps, Tabs } from "antd";
import { FlowerIcon, TableIcon } from "../../../../atoms/CustomIcons";
import FlowerView from "../FlowerView";
import TableView from "../TableView";
import { JobDetailType } from "../../../../organisms/MyJob/ducks/types";
import { useAppSelector } from "../../../../config/hooks";

type PropsType = {
  jobDetailData: JobDetailType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function JobRoles(props: PropsType) {
  const { jobDetailData, setLoading } = props;
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Flower view",
      children: (
        <FlowerView jobDetailData={jobDetailData} setLoading={setLoading} />
      ),
      icon: <FlowerIcon />,
    },
    {
      key: "2",
      label: "Table view",
      children: <TableView jobDetailData={jobDetailData} />,
      icon: <TableIcon />,
    },
  ];

  return (
    <>
      <h2
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {companyData?.label_position} roles
        <div className="length_tag"> {jobDetailData?.roles?.length} </div>
      </h2>
      <Tabs defaultActiveKey="1" items={items} className="job_roles_tabs" />
    </>
  );
}
