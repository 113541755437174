export enum ENVIRONMENTS {
  STAGING = "staging",
  LOCAL_SHEHAN = "local_shehan",
  LOCAL_ALI = "local_ali",
}
export const ENVIRONMENT_MODE: string = ENVIRONMENTS.STAGING;

export const BASE_URLS: {
  [key: string]: {
    REACT_APP_API_BASE_URL: string;
    REACT_WEB_URL: string;
  };
} = {
  staging: {
    REACT_APP_API_BASE_URL: "https://www.fpbackend.darkhorsesolutions.co/",
    REACT_WEB_URL: "https://flowerpower.darkhorsesolutions.co",
  },
  local_shehan: {
    REACT_APP_API_BASE_URL: "http://192.168.100.5:3000/",
    REACT_WEB_URL: "https://flowerpower.darkhorsesolutions.co",
  },
  local_ali: {
    REACT_APP_API_BASE_URL: "http://192.168.100.7:3000/",
    REACT_WEB_URL: "https://flowerpower.darkhorsesolutions.co",
  },
};

export enum USER_TYPE {
  INDIVIDUAL_CONTRIBUTOR = "Individual Contributor",
  COMPANY_ADMIN = "Company Admin",
  SUPER_ADMIN = "Super Admin",
}

export enum CATEGORIES {
  UNCATEGORIZED = "Uncategorized",
  PROCEDURE_TASK = "Procedure/Task",
  RESPONSIBILITY = "Responsibility",
}

export enum STATUS_TYPE {
  pending_approval = "Pending approval",
  approved = "Approved",
  suspended_fp_session = "Suspended FP session",
  pending_fp_session = "Pending FP session",
  pending_ws_session = "Pending WS session",
  suspended_ws_session = "Suspended WS session",
}

export enum STATUS_TYPE_DROPDOWN {
  PENDING_APPROVAL = "Pending approval",
  APPROVED = "Approved",
  SUSPENDED_FP_SESSION = "Suspended FP session",
  PENDING_FP_SESSION = "Pending FP session",
  SUSPENDED_WS_SESSION = "Suspended WS session",
  PENDING_WS_SESSION = "Pending WS session",
}

export const getInitials = (firstName: string, lastName: string) => {
  const firstInitial = firstName?.charAt(0).toUpperCase() ?? "";
  const lastInitial = lastName?.charAt(0).toUpperCase() ?? "";

  return firstInitial + lastInitial;
};

export const getCompanyInitials = (name: string) => {
  const words = name?.split(" ");
  const initials = words
    ?.slice(0, 3)
    ?.map((word) => word?.charAt(0))
    ?.join("");
  return initials.toUpperCase();
};
