import React, { useState } from "react";
import { Button, Col, Empty, Image, Row } from "antd";
import { createSession } from "../../organisms/MyJob/ducks/services";
import { AxiosError, AxiosResponse } from "axios";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";
import NextIcon from "../../atoms/CustomIcons/NextIcon";
import ConfirmDelete from "../../atoms/ConfirmDelete";
import { Popup } from "../../atoms/Popup";

interface EmptyCardProps {
  title: string;
  description: JSX.Element | string;
  buttonText: string;
  showOnlyHeading?: boolean;
  image: JSX.Element | string;
  sessionId?: string | undefined;
  companyId?: string;
  jobId?: string;
  buttonImage: string;
  description2: string;
  continueWSSession?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmptySessionCardSessionStart: React.FC<EmptyCardProps> = ({
  title,
  description,
  buttonText,
  image,
  jobId,
  companyId,
  buttonImage,
  setLoading,
  sessionId,
  description2,
}) => {
  const [completeManually, setCompleteManually] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    if (sessionId) {
      window.location.assign(`/flower-session/${sessionId}`);
    } else {
      const payload = {
        job_id: jobId,
        company_id: companyId,
      };

      createSession(payload)
        .then((res: AxiosResponse) => {
          if (res && res?.data?.id) {
            setLoading(false);
            window.location.assign(`/flower-session/${res?.data?.id}`);
          }
        })
        .catch((e: unknown) => {
          if (e instanceof AxiosError) {
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
            setLoading(false);
          } else {
            ErrorMessage("Something went wrong");
            setLoading(false);
          }
        });
    }
  };

  const confirmProceed = () => {
    window.location.assign(`/edit-job/${jobId}/${companyId}`);
  };

  const skipConfirm = {
    visibility: completeManually,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Skip & complete manually"
        heading={
          <>
            Use this option if you completed{" "}
            <b>both Flower Power and Worksheet sessions</b> for this job outside
            of this platform and want to complete the job and role details
            manually.
          </>
        }
        onClose={() => setCompleteManually(false)}
        btnText="Confirm"
        btnApi={confirmProceed}
        loading={false}
      />
    ),
    width: 520,
    onCancel: () => setCompleteManually(false),
  };

  return (
    <div className="empty_card">
      <Empty
        image={image}
        imageStyle={{ height: 220 }}
        description={
          <>
            <h4>{title}</h4>
            <p>{description}</p>
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={24} className="empty_sessions_btn">
            <Button className="icon_dark_btn" onClick={() => onSubmit()}>
              <Image src={buttonImage} preview={false} width={20} />
              {buttonText}
            </Button>

            <div>
              <h6 className="confirmationText">{description2}</h6>
              <Button
                className="icon_grey_btn"
                onClick={() => setCompleteManually(true)}
              >
                Skip session & complete job details
                <NextIcon />
              </Button>
            </div>
          </Col>
        </Row>
      </Empty>

      <Popup {...skipConfirm} />
    </div>
  );
};

export default EmptySessionCardSessionStart;
