import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const CheckMarkSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1809 2.74754C11.4085 2.93567 11.4406 3.27271 11.2525 3.50036L5.52026 10.4369L5.51896 10.4385C5.40553 10.5747 5.26316 10.6839 5.10222 10.7582C4.94127 10.8325 4.7658 10.8699 4.58855 10.8679C4.4084 10.8656 4.23063 10.8226 4.06948 10.742C3.90881 10.6617 3.76836 10.5461 3.65861 10.4039C3.6583 10.4035 3.65798 10.4031 3.65766 10.4027L1.86266 8.09484C1.68135 7.86173 1.72335 7.52578 1.95646 7.34447C2.18957 7.16316 2.52552 7.20516 2.70683 7.43827L4.50515 9.7504C4.51652 9.76517 4.53108 9.77718 4.54775 9.78551C4.56442 9.79385 4.58276 9.7983 4.6014 9.79853C4.61957 9.79867 4.63756 9.79479 4.65406 9.78718C4.67053 9.77958 4.68511 9.76844 4.69679 9.75456C4.69666 9.75472 4.69691 9.75441 4.69679 9.75456L10.4281 2.81911C10.6162 2.59146 10.9532 2.55942 11.1809 2.74754Z"
      fill="#068344"
    />
  </svg>
);

const CheckMark = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CheckMarkSvg} {...props} />
);

export default CheckMark;
