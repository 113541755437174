import { useEffect, useMemo, useState } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import RoleCard from "../RoleCard";
import { Column, Id, Role } from "../../types";
import { Alert, Button, Card } from "antd";
import { MenuSelectedIcon, PlusIcon } from "../../../../atoms/CustomIcons";

interface PropsType {
  column: Column;
  roles: Role[];
  createRole: () => void;
  deleteRole: (id: Id) => void;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  transformData: () => void;
  updateRole?: (id: string | number, descriptor: string) => void;
  roleToUpdate: any;
  setRoleToUpdate: React.Dispatch<any>;
  columnsLength: number;
}

export default function BasketContainer(props: PropsType) {
  const {
    column,
    createRole,
    roles,
    deleteRole,
    setCurrent,
    transformData,
    updateRole,
    roleToUpdate,
    columnsLength,
    setRoleToUpdate,
  } = props;

  const rolesId = useMemo(() => roles?.map((role: Role) => role?.id), [roles]);
  const [customStyle, setCustomStyle] = useState({ opacity: 0 });
  const { setNodeRef, transform, transition } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  useEffect(() => {
    if (roles?.length === 0) {
      setTimeout(() => {
        setCustomStyle({ opacity: 1 });
      }, 1000);
    }
  }, [roles]);
  return (
    <Card className="basket_card" ref={setNodeRef} style={style}>
      <div className="basket_header">
        <h4>Group role descriptors</h4>
        <p>Create petals and distribute descriptors among them.</p>
      </div>
      <div className="basket_column_wrapper">
        <div className="basket_role_header">
          <h6>Role descriptors</h6>

          <div className="basket_add_role">
            <Button
              onClick={() => createRole()}
              type="text"
              icon={<PlusIcon />}
            >
              Add more
            </Button>
          </div>
        </div>
        <div className="basket_column_content">
          {roles?.length > 0 ? (
            <SortableContext items={rolesId}>
              {roles?.map((role: Role) => (
                <RoleCard
                  key={role.id}
                  role={role}
                  deleteRole={deleteRole}
                  updateRole={updateRole}
                  roleToUpdate={roleToUpdate}
                  setRoleToUpdate={setRoleToUpdate}
                  createRole={createRole}
                />
              ))}
            </SortableContext>
          ) : (
            <div className="sorted_div" style={customStyle}>
              <Alert
                message="Everything is sorted! Proceed to the next step."
                icon={<MenuSelectedIcon />}
                type="success"
                showIcon
              />
            </div>
          )}
        </div>

        <div className="steps_footer">
          <div className="steps_btn_wrapper">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              onClick={() => setCurrent(2)}
            >
              Back
            </Button>
            <Button
              htmlType="button"
              className="icon_dark_btn"
              type="primary"
              size="large"
              onClick={transformData}
              disabled={columnsLength < 2}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
