import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const WritingIconSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="6" fill="#EB9D06" />
    <path
      d="M7.90036 10.0303C8.31598 9.46252 8.82936 8.92973 9.41047 8.47595C10.5102 7.61715 11.7834 7.09408 12.9991 7.09375C14.2172 7.09742 15.4044 7.47765 16.398 8.18234C17.392 8.88727 18.1436 9.88238 18.5497 11.0312C18.7051 11.4706 19.1871 11.7008 19.6265 11.5455C20.0658 11.3902 20.2961 10.9081 20.1407 10.4688C19.6185 8.99164 18.6522 7.71222 17.3742 6.80588C16.0963 5.89954 14.5693 5.41066 13.0026 5.40625H13.0002C11.3138 5.40625 9.68486 6.12063 8.37187 7.14592C7.68167 7.68489 7.05963 8.32435 6.54724 9.02187L6.50626 8.85792C6.39324 8.40585 5.93514 8.13099 5.48306 8.244C5.03098 8.35702 4.75612 8.81512 4.86914 9.2672L5.42945 11.5084C5.43254 11.5213 5.43593 11.5341 5.43962 11.5469C5.48008 11.6872 5.55423 11.8094 5.65097 11.9068C5.71338 11.9698 5.78649 12.0236 5.86887 12.0652C5.99784 12.1307 6.14399 12.1633 6.29412 12.1553C6.35086 12.1523 6.40682 12.1436 6.46119 12.1295L8.70484 11.5686C9.15692 11.4556 9.43178 10.9975 9.31876 10.5454C9.20574 10.0933 8.74764 9.81849 8.29556 9.9315L7.90036 10.0303Z"
      fill="white"
    />
    <path
      d="M19.7485 13.8435C19.8619 13.8432 19.9723 13.8659 20.0738 13.9082C20.2118 13.9653 20.3272 14.0555 20.414 14.1663C20.4915 14.2651 20.5462 14.3802 20.5738 14.5028L21.1313 16.7328C21.2443 17.1849 20.9694 17.643 20.5173 17.756C20.0653 17.869 19.6072 17.5941 19.4941 17.1421L19.4698 17.0449C18.9659 17.7553 18.3524 18.3874 17.6481 18.9156C16.3136 19.9165 14.7127 20.4999 13.0471 20.5924C13.0316 20.5933 13.016 20.5937 13.0005 20.5937C11.4414 20.594 9.91999 20.1144 8.64293 19.22C7.36588 18.3256 6.39507 17.0598 5.86238 15.5945C5.70316 15.1565 5.92912 14.6724 6.36707 14.5132C6.80502 14.354 7.28911 14.58 7.44832 15.0179C7.86264 16.1576 8.61771 17.1421 9.61098 17.8377C10.5979 18.5289 11.7725 18.9016 12.977 18.9062C14.3014 18.828 15.5739 18.3619 16.6356 17.5656C17.2378 17.114 17.757 16.5674 18.175 15.9509L17.7048 16.0685C17.2528 16.1815 16.7947 15.9066 16.6816 15.4546C16.5686 15.0025 16.8435 14.5444 17.2956 14.4314L19.54 13.8703C19.6075 13.8528 19.6775 13.8436 19.7485 13.8435Z"
      fill="white"
    />
  </svg>
);

const WritingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WritingIconSvg} {...props} />
);

export default WritingIcon;
