import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Image,
  Row,
  Tag,
} from "antd";
import DashboardTemplate from "../../../../templates/Dashboard";
import Meta from "antd/es/card/Meta";
import { ArrowIconRight } from "../../../../atoms/CustomIcons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getEmployeeDetails } from "../../../SystemSettings/ducks/actions";
import { getInitials, USER_TYPE } from "../../../../config/constants";
import { Popup } from "../../../../atoms/Popup";
import ConfirmDelete from "../../../../atoms/ConfirmDelete";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import { deleteUserById, updateEmployeeById } from "../../ducks/services";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";

export default function EmployeeDetails() {
  const url = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const employeeId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeActiveModalOpen, setIsDeActiveModalOpen] = useState(false);
  const userDetail = useAppSelector((state) => state?.system?.userDetail);
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeDetails(employeeId));
    }
  }, [employeeId, dispatch]);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/edit-employee/${employeeId}/${companyId}`);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      disabled: employeeId === accountData?.id,
      label: (
        <Button
          className="noBorder"
          disabled={employeeId === accountData?.id}
          onClick={(e) => {
            e.stopPropagation();
            userDetail?.status === "active"
              ? setIsDeActiveModalOpen(true)
              : handleActiveUser();
          }}
        >
          {userDetail?.status === "active" ? "Deactivate" : "Activate"}
        </Button>
      ),
    },
    {
      key: "3",
      disabled: employeeId === accountData?.id,
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          disabled={employeeId === accountData?.id}
          className="noBorder"
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    deleteUserById(employeeId as string)
      .then(() => {
        setDeleteLoading(false);
        dispatch(getEmployeeDetails(employeeId));
        setTimeout(() => {
          setIsDeleteModalOpen(false);
          navigate(`/company-detail/employees/${companyId}`);
        }, 200);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const handleDeActiveUser = async () => {
    setDeleteLoading(true);

    const payload = {
      status: "inactive",
    };
    updateEmployeeById(payload, employeeId as string)
      .then(() => {
        SuccessMessage("User deactivated");
        dispatch(getEmployeeDetails(employeeId));
        setDeleteLoading(false);
        setIsDeActiveModalOpen(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const handleActiveUser = async () => {
    setDeleteLoading(true);

    const payload = {
      status: "active",
    };
    updateEmployeeById(payload, employeeId as string)
      .then(() => {
        setDeleteLoading(false);
        SuccessMessage("User activated");
        dispatch(getEmployeeDetails(employeeId));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading={`Delete ${userDetail?.first_name ?? ""} ${
          userDetail?.last_name ?? ""
        }?`}
        paragraph_1="This user will be deleted. This action can't be undone."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete user"
        btnApi={handleDeleteUser}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  const deActiveConfirmationPopup = {
    visibility: isDeActiveModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deactivation"
        heading={`Deactivate ${userDetail?.first_name ?? ""} ${
          userDetail?.last_name ?? ""
        } user?`}
        paragraph_1="The user will be archived and will lose access to the system."
        onClose={() => setIsDeActiveModalOpen(false)}
        btnText="Deactivate user"
        btnApi={handleDeActiveUser}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeActiveModalOpen(false),
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);
  return (
    <DashboardTemplate loading={false}>
      <Row className="secondary_header">
        {accountData?.type === "SUPER_ADMIN" && (
          <Col span={24}>
            <Breadcrumb separator={<ArrowIconRight />}>
              <Breadcrumb.Item onClick={() => navigate("/companies")}>
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                Companies
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() =>
                  navigate(`/company-detail/employees/${companyId}`)
                }
              >
                {companyData?.name}
              </Breadcrumb.Item>

              <Breadcrumb.Item>Employee details</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        )}

        {accountData?.type === "COMPANY_ADMIN" && (
          <Col span={24}>
            <Breadcrumb separator={<ArrowIconRight />}>
              <Breadcrumb.Item
                onClick={() =>
                  navigate(`/company-detail/employees/${companyId}`)
                }
              >
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                My company
              </Breadcrumb.Item>

              <Breadcrumb.Item>Employee details</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        )}
        <Col span={24}>
          <div className="emp_profile">
            <div className="emp_info">
              <Meta
                avatar={
                  userDetail?.image?.url ? (
                    <div className="participant_avatar">
                      <Image
                        src={userDetail?.image?.url}
                        width={68}
                        height={68}
                        preview={false}
                        style={{
                          borderRadius: "12px",
                          border: "1px solid #ECEDF3",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="participant_avatar">
                      <Avatar shape="square" size={68}>
                        {getInitials(
                          userDetail?.first_name,
                          userDetail?.last_name
                        )}
                      </Avatar>
                    </div>
                  )
                }
                title={`${userDetail?.first_name ?? ""} ${
                  userDetail?.last_name ?? ""
                }`}
                description={userDetail?.email}
                className="headingH2"
              />
              {userDetail?.type && (
                <Tag
                  className="defaultTag white_tag"
                  style={{
                    marginTop: "4px",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {USER_TYPE[userDetail?.type as keyof typeof USER_TYPE]}
                </Tag>
              )}
            </div>
            <div className="right_top_job">
              {userDetail?.status === "inactive" ? (
                <Tag className={`defaultTag grey_tag`}>
                  <Badge className={`default_badge green_badge`} /> Deactivated
                </Tag>
              ) : (
                <Tag className={`defaultTag green_tag`}>
                  <Badge className={`default_badge green_badge`} /> Active
                </Tag>
              )}

              <div className="detail_dropdown">
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="actionButton"
                  overlayClassName="full_width_btn"
                >
                  <Button>
                    <Image
                      src="/images/vertical_dots.svg"
                      width={3}
                      height={15}
                      alt="vertical dots"
                      preview={false}
                    />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={[24, 24]} className="employeeDetail">
        <Col span={24}>
          <div className="jobTitle" style={{ marginTop: "4px" }}>
            <h3> Job: {userDetail?.job?.name} </h3>
            {/* <Button className="icon_grey_btn">
              View full details
              <Image
                src="/images/visit_icon.svg"
                alt="arrow icon"
                width={18}
                height={18}
                preview={false}
                style={{ transform: "rotate(45deg)" }}
              />
            </Button> */}
          </div>
        </Col>
        <Col span={5} className="info_section">
          <ul>
            <li>
              Department:
              <span style={{ fontWeight: "600" }}>
                {userDetail?.job?.department_name ?? "-"}
              </span>
            </li>
            {/* <li className="card_role">
              <p className="role_title">Reports to</p>
              <div className="role_name">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Project Owner
                </div>
              </div>
            </li> */}
            <li>
              Mentored by:
              <span style={{ fontWeight: "600" }}>
                {userDetail?.job?.mentoredBy
                  ? `${userDetail?.job?.mentoredBy?.first_name ?? ""} ${
                      userDetail?.job?.mentoredBy?.last_name ?? ""
                    }`
                  : "-"}
                {/* <Tag
                    className="defaultTag white_tag"
                    style={{ marginLeft: "8px", fontWeight: "400" }}
                  >
                    Project owner
                  </Tag> */}
              </span>
            </li>
          </ul>
        </Col>
        <Col span={19} className="roles_section">
          <h4>
            Roles
            <Badge className="count_badge">
              {userDetail?.job?.roles?.length ?? "0"}
            </Badge>
          </h4>

          {userDetail?.job?.roles?.length > 0 ? (
            <div className="roleAdd" style={{ height: "calc(100vh - 420px)" }}>
              <ul>
                {userDetail?.job?.roles?.map(
                  (role: { name: string }, index: number) => (
                    <li key={index}>
                      <span className={`count count-${index + 1}`}>
                        {index + 1}
                      </span>
                      {role?.name}
                    </li>
                  )
                )}
              </ul>
            </div>
          ) : (
            <div className="emptyListUi">
              <img src="/images/empty/no_roles.svg" alt="" />
              <h5> The employee’s job has no roles yet. </h5>
              <p>
                Create roles by performing a Flower Power session or <br />
                add existing roles via
                <Button
                  type="text"
                  onClick={() =>
                    navigate(`/edit-employee/${employeeId}/${companyId}`)
                  }
                  style={{
                    textDecoration: "underline",
                    padding: "0px",
                    backgroundColor: "unset",
                    paddingLeft: "3px",
                  }}
                >
                  job details
                </Button>
                .
              </p>
            </div>
          )}
        </Col>
      </Row>
      <Popup {...deleteConfirmationPopup} />
      <Popup {...deActiveConfirmationPopup} />
    </DashboardTemplate>
  );
}
