import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  EmployeesIcon,
  JobsIcon,
  RolesIcon,
} from "../../../../atoms/CustomIcons";
import { useAppSelector } from "../../../../config/hooks";

type PropsType = {
  companyId: string;
  urlType: string;
};

export default function CompanyLinks(props: PropsType) {
  const { companyId, urlType } = props;
  const navigate = useNavigate();
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  return (
    <div className="company_actions">
      <Button
        className={urlType === "jobs" ? "icon_dark_btn" : "icon_white_btn"}
        icon={<JobsIcon />}
        onClick={() => navigate(`/company-detail/jobs/${companyId}`)}
      >
        {companyData?.label_position === "Job" ? "Jobs" : "Seats"}
      </Button>
      <Button
        className={urlType === "roles" ? "icon_dark_btn" : "icon_white_btn"}
        icon={<RolesIcon />}
        onClick={() => navigate(`/company-detail/roles/${companyId}`)}
      >
        Roles
      </Button>
      <Button
        className={urlType === "employees" ? "icon_dark_btn" : "icon_white_btn"}
        icon={<EmployeesIcon />}
        onClick={() => navigate(`/company-detail/employees/${companyId}`)}
      >
        Employees
      </Button>
    </div>
  );
}
