import { Button, Image, Form, Spin } from "antd";
import { useForm } from "react-hook-form";
import SelectField from "../../molecules/FormItems/SelectField";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { addExistingRolesToJob } from "../../organisms/CompanyDetails/ducks/services";
import { getJobFilters } from "../../organisms/CompanyDetails/ducks/actions";
import { AxiosError } from "axios";
import SuccessMessage from "../Toasts/SuccessMessage";
import ErrorMessage from "../Toasts/ErrorMessage";
import { getJobDetails } from "../../organisms/MyJob/ducks/actions";
import { Loading3QuartersOutlined } from "@ant-design/icons";

type FormValues = {
  job_id: string;
  roles: string[];
};
const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const {
    onClose,
    title,
    heading,
    btnText,
    existingRoles,
    jobId,
    companyId,
    updateApi,
  } = props;
  const dispatch = useAppDispatch();
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      job_id: jobId,
      roles: val?.roles,
    };

    addExistingRolesToJob(payload)
      .then(() => {
        SuccessMessage("Role added.");
        if (updateApi) {
          updateApi();
        } else {
          dispatch(getJobDetails(jobId));
        }
        setLoading(false);
        onClose();
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  const onCloseHandler = () => {
    onClose();
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (jobsFilterOptions?.role) {
      setValue("roles", existingRoles);
    }
  }, [jobsFilterOptions?.role]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={onCloseHandler}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>

        <div className="modal_content">
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <h4 style={{ marginBottom: "16px" }}>{heading}</h4>
            <div style={{ marginBottom: "24px" }}>
              <SelectField
                fieldName="roles"
                label="Roles"
                control={control}
                selectOption={jobsFilterOptions?.role}
                className="fp_select auto_height"
                mode="multiple"
                iProps={{ placeholder: "Select roles" }}
                isRequired
                rules={{
                  required: "Role name is required.",
                }}
                validate={errors.roles && "error"}
                validMessage={errors.roles && errors.roles.message}
                tooltipTitle="The roles that belong to this job."
              />
            </div>
          </Form>
        </div>

        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn defaultBtnColor"
            type="default"
            size="large"
            onClick={onCloseHandler}
          >
            Cancel
          </Button>

          <Button
            htmlType="button"
            className="actionBtn dangerBtnColor"
            type="primary"
            size="large"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </Spin>
  );
};
